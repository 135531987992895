import { createTheme } from '@mui/material/styles';
import { MAIN_ORANGE } from '../constant/colors';
export const theme = createTheme({
  typography: {
    fontFamily: 'GmarketSansMedium',
  },
  palette: {
    primary: {
      main: MAIN_ORANGE,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          backgroundColor: '#f5f5f5',
          padding: 'unset !important',
        },

        listbox: {
          margin: '0',
          li: {
            fontSize: '14px !important',
            paddingTop: 0,
            paddingBottom: 0,
          },
        },

        popper: {
          margin: '0',
          padding: 'unset !important',
          '& :hover': {
            backgroundColor: '#65c6ff',

            margin: '0',
          },
        },
      },
    },
  },
});
