import React from 'react';
import { calculateFontSize } from '../../pages/DashBoard/UserDashBoard/DashBoard';
import { Typography } from '@mui/material';
import { MAIN_ORANGE } from '../../constant/colors';

export default function BorderBottomText({ text, handleClick }) {
  return (
    <Typography
      sx={{
        borderBottom: 1,
        '&:hover': {
          color: MAIN_ORANGE,
          cursor: 'pointer',
        },
        fontSize: calculateFontSize(),
      }}
      onClick={() => handleClick()}
    >
      {text}
    </Typography>
  );
}
