import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Container, Grid, useMediaQuery } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../api/urls';
import { adminApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import Header from '../../../components/Header';
import TopSubmitButtons from './components/TopSubmitButtons';
import FileDownload from './components/FileDownloadButton';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import DataHeader from '../../../components/DataList/components/DataHeader';
import DataItem from '../../../components/DataList/components/DataItem';
import LoadingIndicator from '../../../components/LoadingIndicator';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import PlaceConfirmSearch from '../PlaceConfirm/components/PlaceConfirmSearch';
import getMaxPage from '../../../hooks/getMaxPage';
import DateSelector from '../../../components/InputForm/components/DateSelector';
import ListCountToggle from '../PlaceConfirm/components/ListCountToggle';
import {
  FlexContainer,
  PlaceConfirmSearchContainer,
  TopTabListContainer,
} from '../PlaceConfirm/PlaceConfirm';

const SettlementConfirm = () => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [settlementId, setSettlementId] = useState([]);
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('provider');
  const setModalProp = useSetRecoilState(modalProps);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxPage, setMaxPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(15);

  useRedirect({ admin: true });

  const headerList = [
    { label: '신청일자', grid: 2.2, key: 'createdAt' },
    { label: '아이디', grid: 2, key: 'username' },
    { label: '장소명', grid: 3.7, key: 'receipt' },
    { label: '실명', grid: 2, key: 'name' },
    { label: '총 금액', grid: 1.2, key: 'totalAmount' },
    { label: null, grid: 0.9, key: 'checkbox' },
  ];

  const getPageData = async (page, category, query, limit) => {
    try {
      const response = await adminApi.get('api/v1/place-information-provider/admin/adjustments', {
        params: {
          page, //
          category: category,
          keyword: query,
          limit: limit,
          startDate: startDate,
          endDate: endDate,
        },
      });

      if (response.status === 200) {
        getMaxPage(response?.data?.totalCount, setMaxPage, limit);
        return response.data;
      }
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const { isLoading, data } = useQuery(
    ['readSettlement', currentPage, query, limit, startDate, endDate],
    () => getPageData(currentPage, category, query, limit),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const adjustments = data?.adjustments;

  const navigateAndDeselectAll = (_, value) => {
    setCurrentPage(value);
    setSettlementId([]);
    setIsAllChecked(false);
  };

  const isAllCheckedState = () => {
    const adjustmentIds = adjustments ? adjustments?.map(adjustment => adjustment.id) : [];
    const sortedCheckedPlace = settlementId ? settlementId.sort() : [];
    if (JSON.stringify(adjustmentIds.sort()) === JSON.stringify(sortedCheckedPlace)) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  };

  const checkBoxHandler = (e, itmeId) => {
    if (e.target.checked) {
      setSettlementId([...settlementId, itmeId]);
      isAllCheckedState();
    } else {
      const newPlaceIds = settlementId.filter(id => id !== itmeId);
      setSettlementId(newPlaceIds);
    }
  };

  const searchMenuItems = [
    { label: '유저', value: 'provider' },
    { label: '장소명', value: 'place' },
  ];

  const navigateHandler = id => {
    navigate(id);
  };

  return (
    <>
      <Container
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="정산 확인"
          subTextFirstLine="정산 요청한 금액을 확인하고,"
          subTextSecondLine="금액을 정산할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />

        <DataContainerWrapper>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <ListCountToggle isMdScreen={true} setLimit={setLimit} />
            <DateSelector
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
            />
          </div>

          <FlexContainer>
            <PlaceConfirmSearchContainer>
              <PlaceConfirmSearch
                isMdScreen={isMdScreen}
                searchMenuItems={searchMenuItems}
                setQuery={setQuery}
                handleSearch={() => {
                  getPageData(currentPage, category, query, limit);
                }}
                setCategory={setCategory}
                setCurrentPage={setCurrentPage}
              />
            </PlaceConfirmSearchContainer>
            <TopTabListContainer>
              <TopSubmitButtons settlementId={settlementId} setSettlementId={setSettlementId} />
            </TopTabListContainer>
          </FlexContainer>

          <DataContainer>
            <DataHeader
              headerList={headerList}
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              setCheckedIds={setSettlementId}
              checkedIds={settlementId}
              data={adjustments}
              showCheckbox={true}
              pageFrom="placeConfirm"
              fontSize={isMdScreen && '0.65rem'}
            />
            {isLoading && <LoadingIndicator />}
            <Grid container>
              {adjustments?.length ? (
                adjustments?.map((adjustment, index) => (
                  <DataItem
                    fontSize={isMdScreen && '0.65rem'}
                    checked={settlementId.some(id => id === adjustment.id)}
                    checkBoxHandler={checkBoxHandler}
                    navigateHandler={() => navigateHandler(adjustment.providerId)}
                    pageFrom="settlementConfirm"
                    headerList={headerList}
                    key={index}
                    resultList={adjustments}
                    setIsAllChecked={setIsAllChecked}
                    isAllChecked={isAllChecked}
                    setCheckedPlace={setSettlementId}
                    showCheckbox={true}
                    result={adjustment}
                    status={adjustment.status}
                    index={index}
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>데이터가 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            navigateAndDeselectAll={navigateAndDeselectAll}
          />
          <div style={{ position: 'fixed', bottom: 20, right: '5vw', width: '15vw' }}>
            <FileDownload
              ids="adjustment_ids"
              width="100%"
              adjustments={settlementId}
              setModalProp={setModalProp}
              url={`${API_URL}/api/v1/place-information-provider/admin/adjustment-sheet`}
              fileName="장소정보제안 정산 목록.xlsx"
            />
          </div>
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default SettlementConfirm;
