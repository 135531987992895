import { Typography, Button, Modal, Box, Grid, TextField, Fade } from '@mui/material';
import React, { useState } from 'react';
import API_URL from '../../../api/urls';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { adminApi } from '../../../api/api';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../../constant/colors';
import { useWatch } from 'react-hook-form';
import useFetchInitData from '../../../hooks/useFetchInitData';

const ProposalReviewModal = ({
  modalState,
  handleModalClose,
  closeProposalReview,
  itemId,
  queryClient,
  control,
  setModalProp,
}) => {
  const [reason, setReason] = useState('');
  const handleChangeConfirm = e => {
    setReason(e.target.value);
  };

  const watch = useWatch({
    control,
  });

  const watchKeywords = useWatch({
    name: 'keywords',
    control,
  });

  const watchCategory = useWatch({
    name: 'placeCategories',
    control,
  });

  const watchAmount = useWatch({
    name: 'amount',
    control,
  });

  const { endDate, endTime, startDate, startTime } = watch;

  const isEndDateInvalid = !endDate || endDate === 'undefined' || endDate === 'Invalid Date';
  const isEndTimeInvalid = !endTime || endTime === null || endTime === 'Invalid Date';
  const isStartDateInvalid =
    !startDate || startDate === 'undefined' || startDate === 'Invalid Date';
  const isStartTimeInvalid = !startTime || startTime === null || startTime === 'Invalid Date';
  const isPlaceCategoryInvalid =
    !watchCategory || watchCategory.length === 0 || watchCategory === 'undefined';

  const handleSubmit = useMutation({
    mutationFn: submitData => {
      const response = adminApi.post(
        `${API_URL}/api/v1/place-information-provider/admin/proposals/${itemId}`,
        submitData,
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchPlaceConfirm']);
    },
    onError: error => {
      console.log(error, 'error');
      alert('오류 발생 ' + JSON.stringify(error));
    },
  });

  const { initData } = useFetchInitData();
  const dayTypes = initData?.businessHourList;

  function findMatchingDayType(day) {
    const matchingDayType = dayTypes?.find(dayType => dayType.name === day);
    return matchingDayType ? matchingDayType.value : day;
  }

  const patchCurrentWatch = callback => {
    const {
      amount,
      isInside,
      startDate,
      startTime,
      endDate,
      endTime,
      jibun,
      address,
      operatingHours,
      ...rest
    } = watch;

    const oprHours = operatingHours.map(item => {
      return {
        day: findMatchingDayType(item.day),
        openingTime: item.openingTime,
        closingTime: item.closingTime,
      };
    });

    let formattedEndDateTime = null;

    if (isEndDateInvalid && isEndTimeInvalid) {
      formattedEndDateTime = null;
    } else if (!isEndDateInvalid && isEndTimeInvalid) {
      formattedEndDateTime = `${endDate} 23:59`;
    } else if (!isEndDateInvalid && !isEndTimeInvalid) {
      formattedEndDateTime = `${endDate} ${endTime}`;
    }
    let formattedStartDateTime = null;

    if (isStartDateInvalid && isStartTimeInvalid) {
      formattedStartDateTime = null;
    } else if (!isStartDateInvalid && isStartTimeInvalid) {
      formattedStartDateTime = `${startDate} 23:59`;
    } else if (!isStartDateInvalid && !isStartTimeInvalid) {
      formattedStartDateTime = `${startDate} ${startTime}`;
    }

    adminApi
      .patch(`${API_URL}/api/v1/place-information-provider/admin/proposals/${itemId}`, {
        ...rest,
        endedAt: formattedEndDateTime,
        startedAt: formattedStartDateTime,
        isInside: isInside,
        address: address,
        jibun: jibun,
        operatingHours: oprHours,
      })
      .then(res => {
        callback();
      })
      .catch(err => {
        alert('서버 오류 발생');
      });
  };

  const handleApproved = () => {
    if (!isEndTimeInvalid && isEndDateInvalid) {
      setModalProp({
        titleText: '종료일이 누락되었습니다',
        text: '종료 날짜를 선택해 주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return;
    }

    if (isPlaceCategoryInvalid) {
      setModalProp({
        titleText: '카테고리가 누락되었습니다',
        text: '카테고리를 선택해 주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return;
    }

    patchCurrentWatch(() =>
      handleSubmit.mutate({
        result: 'approved',
        categories: watchCategory,
        keyword: watchKeywords,
        amount: watchAmount,
        comment: reason,
      }),
    );
    toast.success('승인 처리 되었습니다.');
    closeProposalReview();
    handleModalClose();
  };

  const handleRejected = () => {
    handleSubmit.mutate({
      result: 'rejected',
      comment: reason,
    });
    toast.success('반려 처리 되었습니다.');
    closeProposalReview();
    handleModalClose();
  };

  const handleHold = () => {
    handleSubmit.mutate({
      result: 'hold',
      comment: reason,
    });
    toast.success('보류 처리 되었습니다.');
    closeProposalReview();
    handleModalClose();
  };

  return (
    <>
      <Modal open={modalState.isOpen} onClose={() => handleModalClose()}>
        <Fade in={modalState.isOpen}>
          <Box
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '28rem',
              background: 'white',
              padding: '2.5rem',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              zIndex: 1500,
            }}
            onClick={e => e.stopPropagation()}
          >
            <Typography
              variant="h6"
              sx={{ color: ' #282422', fontWeight: 600 }}
              mb={1.25}
              justifyContent="center"
            >
              {`장소에 관한 정보를 ${modalState.confirmType}하시겠습니까?`}
            </Typography>

            <TextField
              rows={4}
              multiline={true}
              placeholder={`${modalState.confirmType} 사유를 작성해주세요.`}
              onChange={e => handleChangeConfirm(e)}
            />

            <Grid container flexDirection="row" spacing="1rem" mt="0.5rem" mb="1rem">
              <Grid item xs={6} display="flex" justifyContent="center">
                <Button
                  sx={{
                    width: '100%',
                    height: '3rem',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                  onClick={() => {
                    setReason('');
                    closeProposalReview();
                  }}
                >
                  <Typography color={MAIN_ORANGE}>취소</Typography>
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="center">
                <Button
                  sx={{
                    width: '100%',
                    height: '3rem',
                    border: '1px solid',
                    backgroundColor: MAIN_ORANGE,
                    '&:hover': {
                      backgroundColor: FILTER_ORANGE,
                    },
                  }}
                  type="submit"
                  onClick={() => {
                    if (modalState.confirmType === '승인') {
                      handleApproved();
                    } else if (modalState.confirmType === '반려') {
                      handleRejected();
                    } else if (modalState.confirmType === '보류') {
                      handleHold();
                    }

                    setReason('');
                  }}
                >
                  <Typography color="#FFFFFF">확인</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ProposalReviewModal;
