import React from 'react';
import { useTheme } from '@emotion/react';
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { calculateFontSize } from '../../pages/DashBoard/UserDashBoard/DashBoard';

export default function CardComponent({ title, description1, description2, path }) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const handleNavigate = path => {
    navigate(path);
  };
  return (
    <Grid item xs={isMdScreen ? 12 : 6} padding={isMdScreen ? 1 : 1.5}>
      <Paper
        sx={{
          paddingTop: isMdScreen ? '5vw' : '2.5vw',
          paddingBottom: isMdScreen ? '7vw' : '2.5vw',
          paddingLeft: isMdScreen ? '3vw' : '1.4vw',
          paddingRight: isMdScreen ? '3vw' : '1.4vw',
          cursor: 'pointer',
          minHeight: isMdScreen ? '100px' : '190px',
        }}
        elevation={3}
        onClick={() => handleNavigate(path)}
      >
        <Typography
          mb={3}
          sx={{
            fontSize: calculateFontSize(true),
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            fontSize: calculateFontSize(),
          }}
        >
          {description1}
        </Typography>
        <Typography
          sx={{
            fontSize: calculateFontSize(),
          }}
        >
          {description2}
        </Typography>
      </Paper>
    </Grid>
  );
}
