import { FormControl, Grid, Typography, Divider, useMediaQuery } from '@mui/material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Controller, useWatch } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ERROR_RED } from '../../../constant/colors';
import React, { forwardRef, useEffect } from 'react';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { useTheme } from '@emotion/react';

function Date({ control, pageFrom = '', data, dirtyFields }, ref) {
  const theme = useTheme();

  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const watchStartTime = useWatch({
    name: 'startTime',
    control,
  });

  const watchEndTime = useWatch({
    name: 'endTime',
    control,
  });

  useEffect(() => {
    if (watchStartTime === data?.startedAt?.split(' ')[1]) {
      delete dirtyFields?.startTime;
    }

    if (watchEndTime === data?.endedAt?.split(' ')[1]) {
      delete dirtyFields?.endTime;
    }
  }, [watchStartTime, watchEndTime]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        display="flex"
        paddingTop="24px"
        marginBottom={2}
        ref={ref}
      >
        <Grid container flexDirection="row" mb={1}>
          <Typography sx={{ fontSize: '0.8rem' }}>
            공연 일정 또는 운영 기간이 있을 경우 입력해 주세요.
          </Typography>
        </Grid>
      </Grid>
      <SectionContainer>
        <Typography
          sx={{
            color: (dirtyFields?.startDate || dirtyFields?.startTime) && ERROR_RED,
            flexShrink: 0,
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          시작일
        </Typography>
        <ContentContainer>
          <Grid item xs={10} display="flex">
            <Grid item xs={6}>
              <CustomDatePicker
                control={control}
                disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
                name="startDate"
              />
            </Grid>
            <Grid item xs={6} style={{ marginLeft: '10px' }}>
              <CustomTimePicker
                control={control}
                name="startTime"
                label="시작 시간"
                disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
              />
            </Grid>
          </Grid>
        </ContentContainer>
      </SectionContainer>
      <SectionContainer>
        <Typography
          sx={{
            color: (dirtyFields?.endDate || dirtyFields?.endTime) && ERROR_RED,
            flexShrink: 0,
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          종료일
        </Typography>
        <ContentContainer>
          <Grid item xs={10} display="flex">
            <Grid item xs={6}>
              <CustomDatePicker
                control={control}
                disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
                name="endDate"
              />
            </Grid>
            <Grid item xs={6} style={{ marginLeft: '10px' }}>
              <CustomTimePicker
                control={control}
                name="endTime"
                label="종료 시간"
                disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
              />
            </Grid>
          </Grid>
        </ContentContainer>
      </SectionContainer>

      <div style={{ marginTop: '24px' }} />
    </>
  );
}

function CustomDatePicker({ control, disabled, name }) {
  return (
    <Controller
      name={name}
      control={control}
      sx={{ height: '56px' }}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={value ? dayjs(value) : null}
            format="YY-MM-DD"
            onChange={date => onChange(date?.format('YYYY-MM-DD'))}
            disabled={disabled}
            sx={{
              height: '56px',
              'MuiInputBase-formControl ': {
                height: '56px',
              },
              '.MuiInputBase-input': {
                fontSize: { xs: '0.85rem', md: '1rem' },
              },
              '.css-fzb2zt-MuiInputBase-root-MuiOutlinedInput-root': {
                height: '56px',
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}

function CustomTimePicker({ control, name, label, disabled }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        sx={{ height: '55px' }}
        render={({ field }) => (
          <FormControl
            sx={{
              '.MuiInputLabel-root': {
                fontSize: { xs: '0.85rem', md: '1rem' },
              },
            }}
          >
            <TimePicker
              {...field}
              ampm={false}
              openTo="hours"
              views={['hours', 'minutes']}
              format="HH:mm"
              label={label}
              value={field.value ? dayjs(`2023-11-29 ${field.value}`) : null}
              disabled={disabled}
              onChange={value => {
                const formattedTime = dayjs(value).format('HH:mm');
                field.onChange(formattedTime);
              }}
            />
          </FormControl>
        )}
      />
    </LocalizationProvider>
  );
}
export default forwardRef(Date);
