export const API_URL = 'https://api.babygo.kr';
// export const API_URL = 'https://dev.babygo.kr';

export const PERSONAL_INFO_CONSENT_URL =
  'https://babygo.notion.site/a8985b3721e34673b85d0d55dfcff246';
export const TERMS_OF_SERVICE_AGREE_URL =
  'https://babygo.notion.site/44fbcb510a4e4d34a0730335018943c0';

export const NOTION_GUIDE_URL =
  'https://www.notion.so/babygo/25e2a33550ef4f729e1497ebd3f966a4?pvs=4';

export const EMAIL_ADDRESS = 'help@babygo.kr';

export default API_URL;
