import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import SVGRenderer from '../../../utils/SVGrenderer';
import { ERROR_RED, MAIN_ORANGE } from '../../../constant/colors';
import { useTheme } from '@emotion/react';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import preventEnterSubmit from '../../../hooks/preventEnterSubmit';
import { forwardRef } from 'react';
/**
 * 상품 요금 컴포넌트
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param reset
 * @param register
 * @param values
 * @param setValue
 * @returns {JSX.Element}
 * @constructor
 */

function Products({ control, changedProperty, register }, ref) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const watchProduct = useWatch({
    name: 'products',
    control,
  });

  const {
    fields: productPriceFields,
    append: appendProductPrice,
    remove: removeProductPrice,
  } = useFieldArray({
    control,
    name: 'products',
  });

  const handleRemoveProductPrice = index => {
    if (watchProduct?.length === 1) {
      removeProductPrice(index);
      appendProductPrice({
        name: null,
        price: null,
      });
    } else {
      removeProductPrice(index);
    }
  };

  return (
    <SectionContainer style={{ marginBottom: '36px' }} ref={ref}>
      <Typography
        sx={{
          fontSize: isMdScreen && '0.8rem',
          color: changedProperty && ERROR_RED,
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      >
        상품 요금*
      </Typography>
      <ContentContainer>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={5}>
            {productPriceFields.map((item, index) => (
              <TextField
                key={item.id}
                {...register(`products.${index}.name`)}
                placeholder="이름"
                type="text"
                onKeyPress={preventEnterSubmit}
                sx={{
                  paddingBottom: '16px',
                  width: '100%',
                  '.MuiInputBase-input': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                  },
                  '.MuiInputBase-root': {
                    height: '55px',
                  },
                }}
              />
            ))}
          </Grid>
          <Grid item xs={6}>
            {productPriceFields.map((item, index) => (
              <TextField
                key={item.id}
                {...register(`products.${index}.price`, { valueAsNumber: true })}
                placeholder="가격"
                type="text"
                onKeyPress={preventEnterSubmit}
                sx={{
                  paddingBottom: '16px',
                  width: '100%',
                  '.MuiInputBase-input': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                  },
                  '.MuiInputBase-root': {
                    height: '55px',
                  },
                }}
                onChange={e => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  register(`products.${index}.price`).onChange(e);
                }}
              />
            ))}
          </Grid>
          <Grid item xs={1}>
            {productPriceFields?.map((item, index) => (
              <Controller
                key={item.id}
                name=""
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      height: '72px',
                      paddingBottom: '16px',
                      cursor: 'pointer',
                    }}
                    display="flex"
                    alignItems="center"
                    onClick={() => handleRemoveProductPrice(index)}
                  >
                    <SVGRenderer {...field} type="delete" height="100px" />
                  </Box>
                )}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container flexDirection="row-reverse">
          <Button
            component="label"
            sx={{
              background: 'white',
              color: MAIN_ORANGE,
              height: '30px',
              border: 1,
              borderRadius: '20px',
              borderColor: MAIN_ORANGE,
              borderWidth: 1,
              paddingY: 1,
              paddingX: 2,
              marginTop: '8px',
            }}
            onClick={() =>
              appendProductPrice({
                name: null,
                price: null,
              })
            }
          >
            상품리스트 추가 +
          </Button>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
}

export default forwardRef(Products);
