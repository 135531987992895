import { Button, Grid, Typography } from '@mui/material';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../../constant/colors';
import React from 'react';

export default function SettlementRequestButton({ disabled, handleModalOpen, isRequestDay }) {
  return (
    <Grid container justifyContent="center">
      <Button
        sx={{
          width: '35rem',
          height: '3rem',
          backgroundColor: disabled ? 'lightgray' : MAIN_ORANGE,
          '&:hover': {
            backgroundColor: FILTER_ORANGE,
          },
        }}
        disabled={disabled}
        type="submit"
        onClick={handleModalOpen}
      >
        <Typography color="white">
          {isRequestDay ? '정산 요청' : '매월 1일부터 5일까지 정산 가능'}
        </Typography>
      </Button>
    </Grid>
  );
}
