import { useEffect } from 'react';
import API_URL from '../api/urls';
import { userInfo, adminInfo, modalProps } from '../recoilAtom/recoilAtom';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { adminApi, userApi } from '../api/api';

const useRedirect = ({ admin } = {}) => {
  const setUserInfo = useSetRecoilState(admin ? adminInfo : userInfo);
  const setModalProp = useSetRecoilState(modalProps);
  const token = localStorage.getItem(admin ? 'recentAdminAtom' : 'recentUserAtom');
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(admin ? '/admin' : '/login');
    localStorage.removeItem(admin ? 'recentAdminAtom' : 'recentUserAtom');
    setModalProp(null);
  };

  const fetchUserInfo = async token => {
    try {
      const api = admin ? adminApi : userApi;
      const response = await api.get(
        admin
          ? `${API_URL}/api/v1/place-information-provider/admin/me`
          : `${API_URL}/api/v1/place-information-provider/me`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setUserInfo(response.data);
    } catch (error) {
      if (error?.response?.data?.detail === 'ServiceUnderMaintenance') {
        setModalProp({
          titleText: '서버 점검 안내',
          text: '불편을 드려 죄송합니다. 현재 서버 점검으로 이용이 불가능합니다.',
          text2: `빠른 시일 내 서비스를 제공 드릴 수 있도록 하겠습니다. 감사합니다.`,
          buttonText: '로그인 페이지로 이동',
          onPress: () => handleNavigate(),
        });
      } else if (error?.response?.data) {
        setModalProp({
          titleText: '로그인 정보가 만료 되었습니다.',
          text: '다시 로그인 해 주세요.',
          buttonText: '확인',
          onPress: () => handleNavigate(),
        });
      }
    }
  };

  useEffect(() => {
    fetchUserInfo(token);
  }, []);

  return { handleNavigate };
};

export default useRedirect;
