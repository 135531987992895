import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTheme } from '@emotion/react';
import { Paper, Divider, Container, useMediaQuery } from '@mui/material';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { adminApi } from '../../../api/api';
import API_URL from '../../../api/urls';
import ProviderId from '../../SignUp/components/ProviderId';
import Password from '../../SignUp/components/Password';
import ConfirmPassword from '../../SignUp/components/ConfirmPassword';
import NickName from '../../SignUp/components/Nickname';
import Name from '../../SignUp/components/Name';
import AccountNumber from '../../SignUp/components/AccountNumber';
import FormRequestButton from '../../../components/FormRequestButton';
import Header from '../../../components/Header';

const AdminEditUser = () => {
  const setModalProp = useSetRecoilState(modalProps);
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue, control, reset } = useForm({
    defaultValues: {
      providerId: '',
      password: '',
      confirmPassword: '',
      nickname: '',
      name: '',
      bank: '',
      accountNumber: '',
      agreeToAllTerms: false,
      termsOfServiceAgree: false,
      personalInfoConsent: false,
    },
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const values = getValues();
  const [isPasswordMatch, setPasswordMatch] = useState(true);
  const { id } = useParams();

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(['adminGetUserData'], () => adminGetUserData(), {
    cacheTime: 0,
    staleTime: 0,
  });
  const adminGetUserData = async () => {
    try {
      const response = await adminApi.get(
        `${API_URL}/api/v1/place-information-provider/admin/providers/${id}`,
      );
      return response.data?.provider;
    } catch (error) {
      queryClient.invalidateQueries(['adminGetUserData']);
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        providerId: data?.username,
        password: '',
        confirmPassword: '',
        nickname: data?.nickname,
        name: data?.name,
        bank: data?.bank,
        accountNumber: '',
      });
    }
  }, [data]);

  const handleNavigate = () => {
    navigate('/admin/users');
    setModalProp(null);
  };

  const onSubmit = async formData => {
    const updateData = {
      nickname: formData.nickname,
      bank: formData.bank,
    };

    if (formData.password) {
      updateData.password = formData.password;
    }

    if (formData.accountNumber) {
      updateData.accountNumber = formData.accountNumber;
    }
    setModalProp({
      titleText: '유저 정보 수정',
      text: '새로운 정보로 사용자 데이터를 갱신합니다.',
      text2: '진행하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '수정',
      onPress: () => setModalProp(null),
      onPress2: () => submitEditedUserInfo(),
    });

    const submitEditedUserInfo = async () => {
      setModalProp(null);
      try {
        const response = await adminApi.post(
          `api/v1/place-information-provider/admin/providers/${id}`,
          updateData,
        );

        setModalProp({
          titleText: '회원 정보 수정이 완료되었습니다',
          text: '회원 관리 페이지로 이동합니다.',
          buttonText: '이동하기',
          onPress: () => handleNavigate(),
        });
      } catch (error) {
        const response = error.response;

        if (response.status < 500) {
          if (response.data.code === 'AlreadyExistProviderId') {
            setModalProp({
              titleText: '이미 사용 중인 아이디입니다.',
              text: '다른 아이디로 변경해 주세요.',
              buttonText: '확인',
              onPress: () => setModalProp(null),
            });
            return;
          } else if (response.data.code === 'AlreadyExistNickname') {
            setModalProp({
              titleText: '이미 사용 중인 닉네임입니다.',
              text: '다른 닉네임으로 변경해 주세요.',
              buttonText: '확인',
              onPress: () => setModalProp(null),
            });
            return;
          }
        } else if (response.status >= 500) {
          setModalProp({
            titleText: '서버 에러가 발생했습니다.',
            text: '잠시 후 다시 시도해 주세요.',
            text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
            buttonText: '확인',
            onPress: () => setModalProp(null),
          });
          return;
        } else {
          setModalProp({
            titleText: '서버 에러가 발생했습니다.',
            text: '잠시 후 다시 시도해 주세요.',
            text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
            buttonText: '확인',
            onPress: () => setModalProp(null),
          });
          return;
        }
      }
    };
  };

  return (
    <>
      <Container
        style={{
          maxWidth: '900px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="회원 정보 수정"
          subTextFirstLine="회원 정보를 수정하실 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />
        <Paper
          sx={{
            paddingTop: '6vw',
            paddingBottom: '8vw',
            paddingLeft: '8vw',
            paddingRight: '8vw',
          }}
          elevation={3}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProviderId register={register} setValue={setValue} disabled />
            <Password register={register} setValue={setValue} pageFrom="editUser" />
            <ConfirmPassword
              register={register}
              setValue={setValue}
              isPasswordMatch={isPasswordMatch}
              setPasswordMatch={setPasswordMatch}
              control={control}
              pageFrom="editUser"
            />
            <NickName values={values} register={register} setValue={setValue} />
            <Divider variant="middle" />
            <Name values={values} register={register} setValue={setValue} disabled />
            <AccountNumber
              values={values}
              register={register}
              setValue={setValue}
              control={control}
              pageFrom="editUser"
              existingAccountNumber={data?.bankAccount}
            />
            <Divider variant="middle" />
            <FormRequestButton
              control={control}
              buttonText="회원정보 수정"
              pageFrom="adminEditUser"
            />
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default AdminEditUser;
