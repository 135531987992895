import React from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: ${props => (props.pageFrom === 'editPlace' ? '230px' : '150px')};
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 10px 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0.2;
  transition: opacity 0.3s;
`;

const Tooltip = ({ children, text, pageFrom }) => {
  return (
    <TooltipContainer>
      {children}
      <TooltipText className="tooltipText" pageFrom={pageFrom}>
        {text}
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
