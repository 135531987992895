import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../../../recoilAtom/recoilAtom';
import useRedirect from '../../../hooks/useRedirect';
import { useTheme } from '@emotion/react';
import { Container, Grid, useMediaQuery } from '@mui/material';
import Header from '../../../components/Header';
import DashBoardCountContainer from './DashBoardCountContainer';
import CardComponent from '../../../components/Dashboard/CardComponent';
import BorderBottomText from '../../../components/Dashboard/BorderBottomText';
import DashBoardBottomContainer from '../../../components/Dashboard/DashBoardBottomContainer';
import { GuideButton } from '../../../components/StyledButton';
import { EMAIL_ADDRESS } from '../../../api/urls';

export const CURRENT_TEST_ID = 'a2ce32bb-d68c-4f36-b85f-496075c64087';

const DashBoard = () => {
  useRedirect();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [userInformation, setUserInformation] = useRecoilState(userInfo);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('recentUserAtom');
    setUserInformation(null);
    navigate('/');
  };

  const navigateToEditUser = () => {
    navigate('/editUser');
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Container component="main" maxWidth="md">
        <GuideButton>장소 등록 가이드</GuideButton>
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={true}
          titleText={`${userInformation?.nickname}님`}
          subText="환영합니다"
        />
        {CURRENT_TEST_ID === userInformation.id ? (
          <Grid container flexDirection="column" alignItems="left">
            <Grid container flexWrap>
              <CardComponent
                title="장소 등록 및 수정"
                description1="애기야가자에 없는 장소를 등록해주시거나"
                description2="틀린 정보를 수정할 수 있습니다."
                path="/searchplace"
                calculateFontSize={calculateFontSize}
              />
            </Grid>
            <DashBoardBottomContainer>
              <BorderBottomText text="로그아웃" handleClick={handleLogout} />
            </DashBoardBottomContainer>
          </Grid>
        ) : (
          <>
            <DashBoardCountContainer proposalStatus={userInformation?.proposalStatus} />
            <Grid container flexDirection="column" alignItems="left">
              <Grid container flexWrap>
                <CardComponent
                  title="장소 등록 및 수정"
                  description1="애기야가자에 없는 장소를 등록해주시거나"
                  description2="틀린 정보를 수정할 수 있습니다."
                  path="/searchplace"
                  calculateFontSize={calculateFontSize}
                />

                <CardComponent
                  title="제안한 장소 확인"
                  description1="내가 제안한 장소와"
                  description2="승인 반려 여부를 확인할 수 있습니다."
                  path="/propsedplace"
                  calculateFontSize={calculateFontSize}
                />
              </Grid>

              <Grid container flexWrap>
                <CardComponent
                  title="정산 요청"
                  description1="적립된 금액를 확인하거나,"
                  description2="정산 요청을 할 수 있습니다."
                  path="/settlement"
                  calculateFontSize={calculateFontSize}
                />
                <CardComponent
                  title="정산 처리 결과"
                  description1="요청 및 승인된 정산 내역을"
                  description2="확인할 수 있습니다."
                  path="/settlementresult"
                  calculateFontSize={calculateFontSize}
                />
              </Grid>

              <DashBoardBottomContainer>
                <BorderBottomText text="회원정보 수정" handleClick={navigateToEditUser} />
                <div />
                <BorderBottomText text="로그아웃" handleClick={handleLogout} />
              </DashBoardBottomContainer>
            </Grid>
          </>
        )}
      </Container>
      <DashBoardFooterContainer>
        <BaseText>대표전화 : 1877-8739~2</BaseText>
        <EmailText href="mailto:help@babygo.kr">
          정산/오류/개선사항 문의 : {EMAIL_ADDRESS}
        </EmailText>
      </DashBoardFooterContainer>
    </>
  );
};

export default DashBoard;

export const calculateFontSize = size => {
  // 화면의 너비에 따라 fontSize를 계산합니다.
  // 원하는 비율이나 로직에 따라 fontSize를 조정할 수 있습니다.
  if (window.innerWidth <= 310) {
    return size ? '14px' : '10px';
  } else if (window.innerWidth <= 480) {
    return size ? '17px' : '13px';
  } else if (window.innerWidth <= 768) {
    return size ? '20px' : '16px';
  } else {
    return size ? '22px' : '18px';
  }
};

const DashBoardFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 1.5rem;
  background-color: #ebebeb;
  gap: 10px;
`;

const BaseText = styled.p`
  font-size: 0.7rem;
  color: #545750;
`;

const EmailText = styled(BaseText).attrs({
  as: 'a',
})`
  text-decoration: none;
  text-decoration-color: #545750;
  text-underline-offset: 5px;
`;
