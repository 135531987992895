import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../../constant/colors';
import CloseIcon from '@mui/icons-material/Close';

export default function PlaceConfirmSearch({
  isMdScreen,
  searchMenuItems,
  setQuery,
  setCategory,
  handleSearch,
  setCurrentPage,
}) {
  const [keyword, setKeyword] = useState('');
  const handleChangeCategory = e => {
    setCategory(e.target.value);
    setQuery('');
    setKeyword('');
  };

  const handleDeleteQuery = () => {
    setQuery('');
    setKeyword('');
  };

  return (
    <Grid item display="flex" alignItems="center" sx={{ marginBottom: '10px' }}>
      {searchMenuItems && searchMenuItems.length > 0 && (
        <SearchCategorySelector
          isMdScreen={isMdScreen}
          searchMenuItems={searchMenuItems}
          handleChangeCategory={handleChangeCategory}
        />
      )}

      <SearchContainer>
        <StyledInput
          value={keyword}
          placeholder="검색어를 입력하세요."
          isMdScreen={isMdScreen}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setCurrentPage(1);
              setQuery(keyword);
              handleSearch();
            }
          }}
          onChange={e => {
            setKeyword(e.target.value);
          }}
        />
        {keyword && <ClearButton onClick={handleDeleteQuery} />}
      </SearchContainer>
      <SearchButton
        isMdScreen={isMdScreen}
        onClick={() => {
          setCurrentPage(1);
          setQuery(keyword);
          handleSearch();
        }}
      >
        <SearchIcon sx={{ color: 'white' }} />
      </SearchButton>
    </Grid>
  );
}

const StyledInput = styled.input`
  border: 1.5px solid #d3d3d3;
  border-radius: 0.25rem;
  padding: 12.5px 10px;
  height: 40px;
  width: 100%;
  font-size: 0.75rem;

  &:focus {
    border-color: ${MAIN_ORANGE};
    border-width: 1.5px;
    outline: none;
  }

  @media (min-width: 600px) {
    font-size: 0.875rem;
  }

  @media (min-width: 900px) {
    font-size: 1rem;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex: 1;
  width: 100%;
`;

const SearchButton = styled.div`
  width: ${props => (props.isMdScreen ? '50px' : '60px')};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${MAIN_ORANGE};
  cursor: pointer;
  border-radius: 0.25rem;
  &:hover {
    background-color: ${FILTER_ORANGE};
  }
`;

const ClearButton = styled(CloseIcon)`
  cursor: pointer;
  color: #6b6b6b;
`;

function SearchCategorySelector({ isMdScreen, searchMenuItems, handleChangeCategory }) {
  return (
    <FormControl>
      <Select
        sx={{
          display: 'flex',
          height: '40px',
          marginRight: 1,
          minWidth: isMdScreen && '90px',
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
        displayEmpty
        defaultValue={searchMenuItems[0].value}
        onChange={e => handleChangeCategory(e)}
      >
        {searchMenuItems.map(menu => (
          <MenuItem value={menu.value}>{menu.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
