import { Paper } from '@mui/material';

export default function InputFieldWrapper({ children }) {
  return (
    <Paper
      sx={{
        paddingTop: '3vw',
        paddingBottom: '3vw',
        paddingLeft: '6vw',
        paddingRight: '6vw',
      }}
      elevation={3}
    >
      {children}
    </Paper>
  );
}
