import React from 'react';
import API_URL from '../../../api/urls';
import { useTheme } from '@emotion/react';
import useRedirect from '../../../hooks/useRedirect';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { useSetRecoilState } from 'recoil';
import { adminApi } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { Container, useMediaQuery } from '@mui/material';
import Header from '../../../components/Header';
import AdminDashBoardCountContainer from './AdminDashBoardCountContainer';
import AdminDashBoardMain from './AdminDashBoardMain';
import { useQuery } from '@tanstack/react-query';

const AdminDashBoard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const setModalProp = useSetRecoilState(modalProps);
  const { handleNavigate } = useRedirect({ admin: true });
  const navigate = useNavigate();

  const fetchAdminDashBoardCount = async () => {
    try {
      const response = await adminApi.get(
        `${API_URL}/api/v1/place-information-provider/admin/dashboard`,
      );

      return response?.data?.proposals;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '서버 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      }
    }
  };

  const { isLoading, data } = useQuery(['fetchAdminDashBoardCount'], () =>
    fetchAdminDashBoardCount(),
  );

  return (
    <Container component="main" maxWidth="md" sx={{ marginBottom: '8vw', paddingBottom: '6vw' }}>
      <Header
        isSmallScreen={isSmallScreen}
        isGreeting={true}
        titleText="관리자님"
        subText="환영합니다"
        navigateToDashBoard={() => navigate('/admin/dashboard')}
      />
      <AdminDashBoardCountContainer proposalStatus={data} />
      <AdminDashBoardMain />
    </Container>
  );
};

export default AdminDashBoard;
