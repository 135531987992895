import { Grid, TextField } from '@mui/material';
import FormContainer from './FormContainer';
import useDebounce from '../../../hooks/useDebounce';

export default function Name({ register, setValue, disabled }) {
  const validateNameCheck = async e => {
    if (e.target.value.length === 0) {
      return '필수 입력란입니다.';
    }
  };

  const [nameCheckError, debounceName] = useDebounce(validateNameCheck, 2000);

  return (
    <Grid sx={{ paddingTop: '37px' }}>
      <FormContainer label="실명" error={nameCheckError}>
        <TextField
          {...register('name', { required: '필수 입력란입니다.' })}
          placeholder="실명을 입력해주세요."
          type="text"
          onChange={e => {
            debounceName(e);
            setValue('name', e.target.value);
          }}
          disabled={disabled}
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        />
      </FormContainer>
    </Grid>
  );
}
