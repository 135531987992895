import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTheme } from '@emotion/react';
import { Paper, Divider, Container, useMediaQuery } from '@mui/material';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { userApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import SignUpTerms from '../../SignUp/components/SignUpTerms';
import ProviderId from '../../SignUp/components/ProviderId';
import Password from '../../SignUp/components/Password';
import ConfirmPassword from '../../SignUp/components/ConfirmPassword';
import NickName from '../../SignUp/components/Nickname';
import Name from '../../SignUp/components/Name';
import AccountNumber from '../../SignUp/components/AccountNumber';
import FormRequestButton from '../../../components/FormRequestButton';
import Header from '../../../components/Header';

const EditUser = () => {
  const setModalProp = useSetRecoilState(modalProps);
  const navigate = useNavigate();
  const { handleNavigate } = useRedirect();
  const queryClient = useQueryClient();

  const getUserData = async () => {
    try {
      const response = await userApi.get(`/api/v1/place-information-provider/me`);
      return response.data;
    } catch (error) {
      queryClient.invalidateQueries(['getUserData']);
    }
  };

  const { isLoading, data } = useQuery(['getUserData'], () => getUserData(), {
    cacheTime: 0,
    staleTime: 0,
  });

  const { register, handleSubmit, getValues, reset, setValue, control } = useForm({
    defaultValues: {
      providerId: '',
      password: '',
      confirmPassword: '',
      nickname: '',
      name: '',
      bank: '',
      accountNumber: '',
      agreeToAllTerms: false,
      termsOfServiceAgree: false,
      personalInfoConsent: false,
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        providerId: data?.username,
        password: '',
        confirmPassword: '',
        nickname: data?.nickname,
        name: data?.name,
        bank: data?.bankAccount?.bank,
        accountNumber: '',
        agreeToAllTerms: false,
        termsOfServiceAgree: false,
        personalInfoConsent: false,
      });
    }
  }, [data]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const values = getValues();
  const [isPasswordMatch, setPasswordMatch] = useState(true);

  const navigateToDashBoard = () => {
    navigate('/dashboard');
    setModalProp(null);
  };

  const onSubmit = async formData => {
    const updateData = {
      nickname: formData.nickname,
      bank: formData.bank,
    };

    if (formData.password) {
      updateData.password = formData.password;
    }

    if (formData.accountNumber) {
      updateData.accountNumber = formData.accountNumber;
    }
    setModalProp({
      titleText: '회원 정보 수정',
      text: '입력하신 정보로 회원 정보 수정을',
      text2: '진행하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '수정',
      onPress: () => setModalProp(null),
      onPress2: async () => {
        try {
          await userApi.post(`/api/v1/place-information-provider/me`, updateData);
          setModalProp({
            titleText: '회원정보 수정이 완료되었습니다',
            text: '메인 페이지로 이동합니다.',
            buttonText: '이동하기',
            onPress: () => navigateToDashBoard(),
          });
        } catch (error) {
          const response = error.response;

          if (response.status < 500) {
            if (response.data.code === 'NotPermitted') {
              setModalProp({
                titleText: '해당 권한이 없습니다.',
                text: '로그인 화면으로 이동합니다.',
                buttonText: '확인',
                onPress: () => handleNavigate(),
              });
              return;
            }
            if (response.data.code === 'Unauthorized') {
              setModalProp({
                titleText: '해당 권한이 없습니다.',
                text: '로그인 화면으로 이동합니다.',
                buttonText: '확인',
                onPress: () => handleNavigate(),
              });
              return;
            }
            if (response.data.code === 'AlreadyExistNickname') {
              setModalProp({
                titleText: '이미 사용 중인 닉네임입니다.',
                text: '다른 닉네임으로 변경해 주세요.',
                buttonText: '확인',
                onPress: () => setModalProp(null),
              });
              return;
            }
          } else if (response.status >= 500) {
            setModalProp({
              titleText: '서버 에러가 발생했습니다.',
              text: '잠시 후 다시 시도해 주세요.',
              text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
              buttonText: '확인',
              onPress: () => setModalProp(null),
            });
            return;
          } else {
            setModalProp({
              titleText: '서버 에러가 발생했습니다.',
              text: '잠시 후 다시 시도해 주세요.',
              text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
              buttonText: '확인',
              onPress: () => setModalProp(null),
            });
            return;
          }
        }
      },
    });
  };

  return (
    <>
      <Container
        style={{
          maxWidth: '900px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="회원 정보 수정"
          subTextFirstLine="비밀번호, 닉네임, 계좌번호를 수정하실 수 있습니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <Paper
          sx={{
            paddingTop: '6vw',
            paddingBottom: '8vw',
            paddingLeft: '8vw',
            paddingRight: '8vw',
          }}
          elevation={3}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProviderId register={register} setValue={setValue} disabled />
            <Password register={register} setValue={setValue} pageFrom="editUser" />
            <ConfirmPassword
              register={register}
              setValue={setValue}
              isPasswordMatch={isPasswordMatch}
              setPasswordMatch={setPasswordMatch}
              control={control}
              pageFrom="editUser"
            />
            <NickName values={values} register={register} setValue={setValue} />
            <Divider variant="middle" />
            <Name values={values} register={register} setValue={setValue} disabled />
            <AccountNumber
              values={values}
              register={register}
              setValue={setValue}
              control={control}
              existingAccountNumber={data?.bankAccount?.accountNumber}
              pageFrom="editUser"
            />
            <Divider variant="middle" />
            <SignUpTerms register={register} setValue={setValue} control={control} />
            <FormRequestButton control={control} buttonText="회원정보 수정" pageFrom="editUser" />
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default EditUser;
