import { Grid, Typography } from '@mui/material';
import { calculateFontSize } from '../../DashBoard/UserDashBoard/DashBoard';

export default function FormContainer({ children, label, error }) {
  return (
    <Grid container direction="column" alignItems="left" minHeight={129}>
      <Typography mb={1}>{label}</Typography>
      {children}
      <Typography mt={0.5} sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
        {error && error}
      </Typography>
    </Grid>
  );
}
