import React, { useState } from 'react';
import useRedirect from '../../../../hooks/useRedirect';
import { useQuery } from '@tanstack/react-query';
import API_URL from '../../../../api/urls';
import DataContainer from '../../../../components/DataList/components/DataContainer';
import DataHeader from '../../../../components/DataList/components/DataHeader';
import DataItem from '../../../../components/DataList/components/DataItem';
import DataContainerWrapper from '../../../../components/DataList/components/DataContainerWrapper';
import { Container, Grid, useMediaQuery, Typography } from '@mui/material';
import Header from '../../../../components/Header';
import { useTheme } from '@emotion/react';
import { adminApi } from '../../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import PaginationButton from '../../../../components/DataList/components/PaginationButton';
import TopTabList from '../../PlaceConfirm/components/TopTabList';
import FileDownload from './FileDownloadButton';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../../recoilAtom/recoilAtom';
import getMaxPage from '../../../../hooks/getMaxPage';
import DateSelector from '../../../../components/InputForm/components/DateSelector';
import PlaceConfirmSearch from '../../PlaceConfirm/components/PlaceConfirmSearch';
import ListCountToggle from '../../PlaceConfirm/components/ListCountToggle';
import {
  FlexContainer,
  PlaceConfirmSearchContainer,
  TopTabListContainer,
} from '../../PlaceConfirm/PlaceConfirm';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const SettlementConfirmDetail = () => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const setModalProp = useSetRecoilState(modalProps);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [maxPage, setMaxPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(15);

  const [adminTabList, setAdminTabList] = useState({ value: 'all', label: '전체' });
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedProposal, setCheckedProposal] = useState([]);

  useRedirect({ admin: true });

  const { id } = useParams();

  const checkBoxHandler = (e, itmeId) => {
    if (e.target.checked) {
      setCheckedProposal([...checkedProposal, itmeId]);
    } else {
      const newPlaceIds = checkedProposal.filter(id => id !== itmeId);
      setCheckedProposal(newPlaceIds);
    }
  };

  const navigateAndDeselectAll = (_, value) => {
    setCurrentPage(value);
    setCheckedProposal([]);
    setIsAllChecked(false);
  };
  const fetchSettlementDetail = async () => {
    try {
      const response = await adminApi.get(
        `api/v1/place-information-provider/admin/adjustments-provider/${id}`,
        {
          params: {
            page: currentPage,
            status: adminTabList.value,
            keyword: query,
            limit: limit,
            startDate: startDate,
            endDate: endDate,
          },
        },
      );
      getMaxPage(response.data?.adjustmentCount, setMaxPage, limit);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '정산 목록을 불러오는 데 실패했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const { isLoading, data } = useQuery(
    ['fetchSettlementDetail', currentPage, adminTabList, startDate, endDate, query, limit],

    () => fetchSettlementDetail(currentPage, adminTabList, query, limit),
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );
  const settlementResult = data && data?.adjustments;

  const headerList = [
    { label: '신청일자', grid: 2, key: 'createdAt' },
    { label: '처리일자', grid: 2, key: 'proceededAt' },
    { label: '신청내역', grid: 3.7, key: 'receipt' },
    { label: '금액', grid: 2, key: 'totalAmount' },
    { label: '상태', grid: 1.3, key: 'status' },
    { label: null, grid: 1, key: 'checkbox' },
  ];

  const tabList = [
    {
      value: 'all',
      label: '전체',
    },
    {
      value: 'requested',
      label: '요청',
    },
    {
      value: 'rejected',
      label: '거절',
    },
    {
      value: 'approved',
      label: '승인',
    },
  ];

  return (
    <>
      <Container
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="정산 상세 조회"
          subTextFirstLine="해당 유저의 모든 정산 요청과"
          subTextSecondLine="정산 내역을 확인할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />
        <DataContainerWrapper>
          <DataContainer>
            <Grid item xs={6} flexDirection="row">
              <Typography
                sx={{
                  color: '#5E5E5E',
                  fontSize: isMdScreen && '0.8rem',
                  marginBottom: 0.5,
                }}
              >
                {settlementResult && `회원정보 : ${data?.name} / ${data?.username}`}
              </Typography>
              <Typography
                sx={{
                  color: '#5E5E5E',
                  fontSize: isMdScreen && '0.8rem',
                }}
              >
                {`승인된 정산금 : ${(data?.approvedAmount ?? '0').toLocaleString()}원`}
              </Typography>
            </Grid>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <ListCountToggle isMdScreen={true} setLimit={setLimit} />
              <DateSelector
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
              />
            </div>

            <FlexContainer>
              <PlaceConfirmSearchContainer>
                <PlaceConfirmSearch
                  isMdScreen={isMdScreen}
                  searchMenuItems={[]}
                  setQuery={setQuery}
                  setCurrentPage={setCurrentPage}
                  handleSearch={() => {
                    fetchSettlementDetail(
                      currentPage,
                      adminTabList.value,
                      query,
                      limit,
                      startDate,
                      endDate,
                    );
                  }}
                />
              </PlaceConfirmSearchContainer>
              <TopTabListContainer>
                <TopTabList
                  fontSize={isMdScreen && '0.65rem'}
                  setCurrentPage={setCurrentPage}
                  setAdminTabList={setAdminTabList}
                  adminTabList={adminTabList}
                  setIsAllChecked={setIsAllChecked}
                  setCheckedPlace={setCheckedProposal}
                  tabList={tabList}
                />
              </TopTabListContainer>
            </FlexContainer>

            <DataHeader
              headerList={headerList}
              showCheckbox
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              checkedIds={checkedProposal}
              setCheckedIds={setCheckedProposal}
              data={settlementResult}
              fontSize={isMdScreen && '0.65rem'}
            />
            {isLoading && <LoadingIndicator />}
            <Grid container>
              {settlementResult?.length ? (
                settlementResult?.map((result, index) => (
                  <DataItem
                    fontSize={isMdScreen && '0.65rem'}
                    headerList={headerList}
                    resultList={settlementResult}
                    status={result.status}
                    result={result}
                    key={index}
                    index={index}
                    pageFrom="settlementConfirm"
                    showCheckbox
                    checked={checkedProposal?.some(id => id === result.id)}
                    checkedIds={checkedProposal}
                    setCheckedIds={setCheckedProposal}
                    checkBoxHandler={checkBoxHandler}
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>데이터가 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            navigateAndDeselectAll={navigateAndDeselectAll}
          />
          <FileDownload
            ids="adjustment_ids"
            width="80%"
            adjustments={checkedProposal}
            setModalProp={setModalProp}
            url={`${API_URL}/api/v1/place-information-provider/admin/adjustment-result-sheet`}
            fileName={`${data?.name}님의 정산 상세 조회 목록.xlsx`}
          />
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default SettlementConfirmDetail;
