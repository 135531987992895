import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import queryString from 'query-string';
import API_URL from '../../../api/urls';
import { adminApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { Grid, Container, useMediaQuery } from '@mui/material';
import Header from '../../../components/Header';
import TopTabList from './components/TopTabList';
import styled from 'styled-components';
import PlaceConfirmDetail from './PlaceConfirmDetail';
import ListCountToggle from './components/ListCountToggle';
import PlaceConfirmSearch from './components/PlaceConfirmSearch';
import LoadingIndicator from '../../../components/LoadingIndicator';
import DataItem from '../../../components/DataList/components/DataItem';
import DataHeader from '../../../components/DataList/components/DataHeader';
import FileDownload from '../SettlementConfirm/components/FileDownloadButton';
import DataContainer from '../../../components/DataList/components/DataContainer';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import getMaxPage from '../../../hooks/getMaxPage';
import DateSelector from '../../../components/InputForm/components/DateSelector';
import dayjs from 'dayjs';

const PlaceConfirm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [adminTabList, setAdminTabList] = useState({ value: 'all', label: '전체' });
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const setModalProp = useSetRecoilState(modalProps);
  const { handleNavigate } = useRedirect({ admin: true });
  const [openPlaceDetailModal, setPlaceDetailModal] = useState(false);
  const [currentPlaceId, setCurrentPlaceId] = useState(null);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedPlace, setCheckedPlace] = useState([]);
  const [category, setCategory] = useState('provider');
  const [limit, setLimit] = useState(15);
  const [sortKey, setSortKey] = useState('created_new');
  const [maxPage, setMaxPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const navigateAndDeselectAll = (_, value) => {
    setCurrentPage(value);
    setCheckedPlace([]);
    setIsAllChecked(false);
  };

  const headerList = [
    { label: '작성분류', grid: 1.7, key: 'categoryDisplay' },
    { label: '작성일자', grid: 2.7, key: 'createdAt' },
    { label: '수정일자', grid: 0, key: 'modifiedAt' },
    { label: '장소명', grid: 3, key: 'name' },
    { label: '상태', grid: 1, key: 'statusDisplay' },
    { label: '아이디', grid: 1.6, key: 'providerId' },
    { label: '관리자', grid: 1.2, key: 'processor' },
    { label: null, grid: 0.8, key: 'checkbox' },
  ];

  const searchMenuItems = [
    { label: '아이디', value: 'provider' },
    { label: '장소명', value: 'place' },
  ];

  const tabList = [
    {
      value: 'all',
      label: '전체',
    },
    {
      value: 'pending',
      label: '승인대기',
    },
    {
      value: 'completed',
      label: '처리결과',
    },
  ];

  const handleOpenPlaceDetail = placeId => {
    setCurrentPlaceId(placeId);
    setPlaceDetailModal(true);
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.label && parsed.value) {
      setAdminTabList({ ...parsed });
    } else {
      setAdminTabList({ value: 'all', label: '전체' });
    }
  }, [window.location.search]);

  const fetchPlaceConfirmList = async (currentPage, adminTabList, query, sortKey, limit) => {
    try {
      const response = await adminApi.get('/api/v1/place-information-provider/admin/proposals', {
        params: {
          page: currentPage,
          proposalStatus: adminTabList.value,
          search: category,
          keyword: query,
          sort_key: sortKey,
          limit: limit,
          startDate: startDate,
          endDate: endDate,
        },
      });
      getMaxPage(response.data?.totalCount, setMaxPage, limit);
      return response?.data;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '서버 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const { isLoading, data } = useQuery(
    ['fetchPlaceConfirm', query, adminTabList, currentPage, sortKey, limit, startDate, endDate],
    () => fetchPlaceConfirmList(currentPage, adminTabList, query, sortKey, limit),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const places = data && data.proposals;

  const checkBoxHandler = (e, itmeId) => {
    if (e.target.checked) {
      setCheckedPlace([...checkedPlace, itmeId]);
    } else {
      const newPlaceIds = checkedPlace.filter(id => id !== itmeId);
      setCheckedPlace(newPlaceIds);
    }
  };
  return (
    <>
      <Container
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="장소 확인"
          subTextFirstLine="신규 요청 장소나, 장소 정보 수정 요청을"
          subTextSecondLine="확인하고, 관리할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />

        <DataContainerWrapper
          pageFrom="placeConfirm"
          isMdScreen={isMdScreen}
          isLargeScreen={isLargeScreen}
        >
          <DataContainer>
            <ListAndDateControls>
              <ListCountToggle isMdScreen={isMdScreen} setLimit={setLimit} />
              <DateSelector
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
              />
            </ListAndDateControls>

            <FlexContainer>
              <PlaceConfirmSearchContainer>
                <PlaceConfirmSearch
                  setQuery={setQuery}
                  isMdScreen={isMdScreen}
                  searchMenuItems={searchMenuItems}
                  handleSearch={() =>
                    fetchPlaceConfirmList(currentPage, adminTabList, query, sortKey, limit)
                  }
                  setCategory={setCategory}
                  setCurrentPage={setCurrentPage}
                />
              </PlaceConfirmSearchContainer>

              <TopTabListContainer>
                <TopTabList
                  fontSize={isMdScreen && '0.7rem'}
                  setCurrentPage={setCurrentPage}
                  setAdminTabList={setAdminTabList}
                  adminTabList={adminTabList}
                  isMdScreen={isMdScreen}
                  setIsAllChecked={setIsAllChecked}
                  setCheckedPlace={setCheckedPlace}
                  tabList={tabList}
                />
              </TopTabListContainer>
            </FlexContainer>

            <DataHeader
              fontSize={isMdScreen && '0.7rem'}
              headerList={headerList}
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              data={places}
              showCheckbox={adminTabList.value === 'completed' && true}
              pageFrom="placeConfirm"
              checkedIds={checkedPlace}
              setCheckedIds={setCheckedPlace}
              sortKey={sortKey}
              setSortKey={setSortKey}
              handleSearch={() =>
                fetchPlaceConfirmList(currentPage, adminTabList, query, sortKey, limit)
              }
            />

            {isLoading && <LoadingIndicator />}
            <Grid container>
              {places?.length ? (
                places?.map((place, index) => (
                  <DataItem
                    fontSize={isMdScreen && '0.7rem'}
                    checked={checkedPlace.some(id => id === place.id)}
                    checkBoxHandler={checkBoxHandler}
                    pageFrom="placeConfirm"
                    headerList={headerList}
                    resultList={places}
                    result={place}
                    status={place.status}
                    category={place.category}
                    key={index}
                    index={index}
                    handleOpenPlaceDetail={() => handleOpenPlaceDetail(place.id)}
                    showCheckbox={adminTabList.value === 'completed' && true}
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>데이터가 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            navigateAndDeselectAll={navigateAndDeselectAll}
          />
          {adminTabList.value === 'completed' && (
            <div style={{ position: 'fixed', bottom: 20, right: '5vw', width: '15vw' }}>
              <FileDownload
                ids="confirm_ids"
                adjustments={checkedPlace}
                width="100%"
                setModalProp={setModalProp}
                url={`${API_URL}/api/v1/place-information-provider/admin/placeconfirm-sheet`}
                fileName="장소확인 처리결과 목록.xlsx"
              />
            </div>
          )}
        </DataContainerWrapper>

        {openPlaceDetailModal && (
          <PlaceConfirmDetail
            isMdScreen={isMdScreen}
            isLargeScreen={isLargeScreen}
            open={openPlaceDetailModal}
            handleModalClose={() => setPlaceDetailModal(false)}
            itemId={currentPlaceId}
          />
        )}
      </Container>
    </>
  );
};

export default PlaceConfirm;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PlaceConfirmSearchContainer = styled.div`
  @media (max-width: 600px) {
    margin-right: auto;
  }
`;

export const TopTabListContainer = styled.div`
  margin-left: auto;
`;

export const ListAndDateControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;
