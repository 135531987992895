import { adminApi, userApi } from './api';
import API_URL from './urls';

export const signUpApi = async signUpData => {
  try {
    const response = await userApi.post(
      `${API_URL}/api/v1/place-information-provider/sign-up`,
      {
        providerId: signUpData.providerId,
        password: signUpData.password,
        confirmPassword: signUpData.confirmPassword,
        nickname: signUpData.nickname,
        name: signUpData.name,
        bank: signUpData.bank,
        accountNumber: signUpData.accountNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return {
      ok: response.ok,
      data: response?.data,
    };
  } catch (error) {
    return {
      ok: error.ok,
      response: error.response,
    };
  }
};

export const signInApi = async loginData => {
  try {
    const response = await userApi.post(
      `${API_URL}/api/v1/place-information-provider/sign-in`,
      {
        providerId: loginData.id,
        password: loginData.password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return {
      ok: response.ok,
      data: response?.data,
    };
  } catch (error) {
    return {
      ok: error.ok,
      response: error.response,
    };
  }
};

export const adminSignInApi = async loginData => {
  try {
    const response = await adminApi.post(
      `${API_URL}/api/v1/place-information-provider/admin/signin`,
      {
        id: loginData.id,
        password: loginData.password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return {
      ok: response.ok,
      data: response?.data,
    };
  } catch (error) {
    return {
      ok: error.ok,
      response: error.response,
    };
  }
};
