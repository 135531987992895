import React from 'react';

const SVGRenderer = ({ type, width, marginRight, marginTop, marginLeft, marginBottom }) => {
  let svgContent = null;

  switch (type) {
    case 'logo':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width ? width : '80'}
          height="58"
          viewBox="0 0 80 58"
          fill="none"
        >
          <g clipPath="url(#clip0_2554_5330)">
            <path
              d="M40.5324 0C51.7356 0.0750922 63.3681 5.19354 67.4744 18.6675C68.7399 22.8179 69.3109 27.0859 70.0965 31.3155C70.5703 33.8625 68.9525 35.5774 66.0996 35.9732C63.6739 36.3081 62.2646 35.2142 62.1208 32.316C61.9163 28.1961 60.9829 24.269 59.766 20.3581C58.215 15.3695 55.0907 11.9194 50.294 9.75386C44.7602 7.25553 39.192 7.15405 33.5185 8.97047C30.7445 9.85737 28.6468 11.6616 27.0209 14.0706C23.2142 19.7086 20.0414 25.6511 17.8505 32.1049C16.7612 35.3156 14.9672 36.3811 11.7599 36.0057C9.88494 35.7865 9.09932 34.9605 8.97176 33.0649C8.95556 32.8335 8.94949 32.5941 8.98593 32.3667C10.6058 22.4647 14.0581 13.474 21.9811 6.73191C27.7032 1.86107 34.5511 0.247601 40.5324 0Z"
              fill="#F4AC3B"
            />
            <path
              d="M42.9015 12.6073C47.192 12.5707 50.62 14.2471 53.1288 17.7237C55.7549 21.3626 56.5608 25.6023 56.9394 29.913C57.1358 32.1576 54.8336 33.7163 51.6466 33.8299C49.3889 33.9111 48.2571 32.9451 48.0283 30.5137C47.8541 28.6628 47.9655 26.7855 47.7752 24.9366C47.4492 21.7827 46.2323 20.7172 43.0128 20.5163C37.8334 20.1956 34.29 22.4423 32.3846 27.3111C31.7914 28.8292 31.2912 30.3818 30.7607 31.9242C30.048 33.9923 28.493 35.1227 26.4661 35.6809C24.5203 36.2146 23.2993 35.3947 22.9875 33.3855C22.9167 32.9309 22.943 32.456 22.9572 31.9912C23.2669 21.7868 32.6094 12.7108 42.9015 12.6073Z"
              fill="#E76548"
            />
            <path
              d="M24.2105 57.6464C17.7777 57.8129 11.6263 57.8129 5.64102 56.4064C4.46056 56.1284 3.26187 55.8909 2.24542 55.345C-0.846453 53.6828 -0.72294 50.6081 2.48637 49.1123C3.62634 48.5806 4.90197 48.2924 6.16949 47.9981C13.1632 46.3786 20.3492 45.4145 27.5757 44.6798C35.9321 43.8315 44.3431 43.3424 52.7825 43.4013C57.9903 43.4378 63.1839 43.8031 68.3107 44.5621C70.3639 44.8666 72.3502 45.3354 74.2981 45.9016C75.7215 46.3156 77.0477 46.8778 78.1735 47.6612C80.741 49.4512 80.5891 51.7405 77.801 53.3155C76.1751 54.2328 74.2738 54.6894 72.336 55.0547C67.357 55.9985 62.2687 56.4287 57.1682 56.7047C52.4464 56.9605 47.7083 57.0985 42.9764 57.1715C36.6306 57.269 30.3052 57.8027 24.2125 57.6444L24.2105 57.6464Z"
              fill="#F4AC3B"
            />
            <path
              d="M37.4488 25.637C38.7649 24.8576 39.9859 24.3198 41.3749 24.334C42.8226 24.3503 43.8411 25.0545 44.4749 26.3311C45.0196 27.4311 45.0216 28.6061 44.8717 29.7894C44.6591 31.4536 43.991 32.9108 42.7842 34.1061C42.3266 34.5587 41.7981 34.92 41.2068 35.1798C40.6844 35.4071 40.1378 35.5106 39.5708 35.4416C38.202 35.2751 37.0499 34.6927 36.1671 33.6292C35.3511 32.6429 35.0555 31.4718 35.0433 30.2135C35.0211 28.1901 35.9788 26.6943 37.4488 25.639V25.637Z"
              fill="#3E4F9A"
            />
          </g>
          <defs>
            <clipPath id="clip0_2554_5330">
              <rect width="80" height="57.7215" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'logoWithBabyGo':
      svgContent = (
        <svg
          width={width}
          height="100"
          viewBox="0 0 516 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2616_253)">
            <path
              d="M29.5131 100C25.4744 100 21.6843 99.2266 18.1427 97.6952C14.6167 96.1484 11.5411 94.0757 8.91599 91.4771C6.29088 88.8631 4.20942 85.7849 2.67163 82.2428C1.11831 78.6852 0.357178 74.8955 0.357178 70.8739C0.357178 66.8522 1.13384 63.0781 2.67163 59.5514C4.22496 56.0402 6.30641 52.9775 8.91599 50.3634C11.5411 47.7494 14.6012 45.6767 18.1427 44.1453C21.6688 42.5986 25.4589 41.8406 29.5131 41.8406C33.5672 41.8406 37.3418 42.614 40.8834 44.1453C44.4095 45.6921 47.5006 47.7648 50.1568 50.3634C52.8129 52.9775 54.9099 56.0247 56.4632 59.5514C58.0166 63.0626 58.7777 66.8368 58.7777 70.8739C58.7777 74.911 58.001 78.7006 56.4632 82.2428C54.9099 85.8004 52.8129 88.8785 50.1568 91.4771C47.5006 94.0912 44.4095 96.1639 40.8834 97.6952C37.3574 99.242 33.5672 100 29.5131 100ZM29.5131 62.2119C27.1365 62.2119 25.0861 63.0626 23.393 64.7641C21.6843 66.4655 20.83 68.4918 20.83 70.8584C20.83 73.225 21.6843 75.2668 23.393 76.9528C25.1016 78.6542 27.1365 79.505 29.5131 79.505C31.8897 79.505 33.94 78.6542 35.6332 76.9528C37.3418 75.2513 38.1962 73.225 38.1962 70.8584C38.1962 68.4918 37.3418 66.4501 35.6332 64.7641C33.9245 63.0626 31.8897 62.2119 29.5131 62.2119ZM104.585 41.7169V99.6597H88.1666V80.1082H81.1456V99.6597H64.7269V41.7014H81.1456V61.9644H88.1666V41.7014H104.585V41.7169Z"
              fill="#555852"
            />
            <path
              d="M113.859 41.4695H154.789V90.4099C154.789 92.9312 153.919 95.0658 152.164 96.8136C150.424 98.546 148.28 99.4277 145.733 99.4277H136.568V64.1145C136.568 61.1137 135.061 59.6133 132.048 59.6133H113.843V41.485L113.859 41.4695ZM187.269 99.4122H169.064V41.4695H187.269V99.4122Z"
              fill="#555852"
            />
            <path
              d="M224.284 100C220.246 100 216.456 99.2267 212.914 97.6954C209.388 96.1486 206.312 94.0759 203.687 91.4773C201.062 88.8632 198.981 85.7851 197.443 82.243C195.89 78.6854 195.128 74.8957 195.128 70.8741C195.128 66.8524 195.905 63.0783 197.443 59.5516C198.996 56.0404 201.078 52.9777 203.687 50.3636C206.312 47.7496 209.372 45.6769 212.914 44.1455C216.44 42.5988 220.23 41.8408 224.284 41.8408C228.338 41.8408 232.113 42.6142 235.655 44.1455C239.181 45.6923 242.272 47.765 244.928 50.3636C247.584 52.9777 249.681 56.0249 251.234 59.5516C252.788 63.0628 253.549 66.837 253.549 70.8741C253.549 74.9112 252.772 78.7008 251.234 82.243C249.681 85.8006 247.584 88.8787 244.928 91.4773C242.272 94.0914 239.181 96.1641 235.655 97.6954C232.129 99.2422 228.338 100 224.284 100ZM224.284 62.2121C221.908 62.2121 219.857 63.0628 218.164 64.7643C216.456 66.4657 215.601 68.492 215.601 70.8586C215.601 73.2252 216.456 75.267 218.164 76.953C219.873 78.6544 221.908 79.5052 224.284 79.5052C226.661 79.5052 228.711 78.6544 230.404 76.953C232.113 75.2515 232.967 73.2252 232.967 70.8586C232.967 68.492 232.113 66.4503 230.404 64.7643C228.696 63.0628 226.661 62.2121 224.284 62.2121ZM282.472 67.4247V75.3598H295.799V93.4881H282.472V100H264.267V42.0574H282.472V49.2809H295.799V67.4093H282.472V67.4247Z"
              fill="#555852"
            />
            <path
              d="M305.477 41.1602H346.407V90.1006C346.407 92.6219 345.537 94.7564 343.797 96.5043C342.057 98.2367 339.914 99.1184 337.366 99.1184H328.202V63.8052C328.202 60.8044 326.695 59.304 323.682 59.304H305.477V41.1756V41.1602ZM392.214 79.5515H378.887V99.1029H360.682V41.1602H378.887V61.4231H392.214V79.5515Z"
              fill="#F8AF1A"
            />
            <path
              d="M470.937 88.2599L453.804 99.5205L445.944 86.4811L433.455 65.9861L420.966 86.4811L412.998 99.5205L404.19 94.8956C402.917 94.1841 401.798 93.519 400.804 92.8848C399.81 92.2506 399.111 91.338 398.723 90.1624C397.853 87.8732 398.21 85.4293 399.795 82.8152L402.171 78.9018L412.159 62.4285L413.821 59.8144V59.6907H399.422V41.5623H469.741V59.6907H452.965L453.089 59.8144L454.642 62.4285L464.755 78.9018L470.937 88.2599ZM515.315 79.8454H501.988V99.3968H483.783V41.4695H501.988V61.7324H515.315V79.8608V79.8454Z"
              fill="#F8AF1A"
            />
            <path
              d="M29.3891 0C39.8119 0.0773395 50.6385 4.79505 54.4752 17.2622C55.6558 21.0982 56.1839 25.0425 56.914 28.9559C57.3489 31.307 55.8422 32.9002 53.186 33.256C50.9337 33.5654 49.6133 32.5599 49.4891 29.8685C49.3027 26.0634 48.4328 22.4285 47.2989 18.809C45.8543 14.1995 42.9496 11.0131 38.476 9.00232C33.319 6.6976 28.1464 6.60479 22.8651 8.27533C20.2866 9.09513 18.3294 10.7657 16.8227 12.993C13.2811 18.2057 10.3298 23.6968 8.27938 29.6674C7.26972 32.6373 5.59213 33.6272 2.60974 33.2715C0.870021 33.0704 0.139959 32.297 0.0156927 30.5491C0.0156927 30.3326 0.000159484 30.116 0.0156927 29.8995C1.52242 20.7425 4.7378 12.4207 12.1161 6.18716C17.444 1.71694 23.8282 0.232019 29.3891 0Z"
              fill="#F3AB3A"
            />
            <path
              d="M31.5948 11.6628C35.5868 11.6319 38.7867 13.1787 41.1166 16.396C43.5554 19.768 44.3165 23.6814 44.6582 27.6721C44.8446 29.7448 42.701 31.1833 39.7342 31.2916C37.6372 31.3689 36.5809 30.4718 36.3635 28.2289C36.2081 26.512 36.3013 24.7796 36.1305 23.0781C35.8198 20.1547 34.7014 19.1802 31.7035 18.9946C26.8882 18.7007 23.5796 20.7734 21.8088 25.2746C21.2496 26.6821 20.7992 28.1207 20.3021 29.5437C19.6342 31.4617 18.1896 32.4981 16.3101 33.024C14.4927 33.519 13.3587 32.761 13.0791 30.9049C13.017 30.4872 13.0325 30.0387 13.0481 29.621C13.3432 20.1856 22.0263 11.7866 31.6103 11.6937L31.5948 11.6628Z"
              fill="#E66547"
            />
            <path
              d="M26.5154 23.7122C27.7425 22.9852 28.8765 22.4903 30.1657 22.5057C31.5171 22.5057 32.4646 23.1708 33.0549 24.3464C33.5675 25.3673 33.5675 26.45 33.4277 27.5483C33.2258 29.0796 32.6044 30.4408 31.486 31.539C31.0666 31.9566 30.5696 32.2969 30.0259 32.5289C29.5444 32.7455 29.0318 32.8383 28.5037 32.7764C27.2299 32.6217 26.1581 32.0804 25.3349 31.1059C24.5737 30.1933 24.2941 29.1105 24.2941 27.9504C24.2786 26.0788 25.164 24.7022 26.5309 23.7122H26.5154Z"
              fill="#3D4E99"
            />
          </g>
          <defs>
            <clipPath id="clip0_2616_253">
              <rect width="515.315" height="100" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'delete':
      svgContent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none">
          <circle cx="12" cy="12" r="12" fill="#E6E6E6" />
          <path
            d="M15.845 8.90335C16.0517 8.6967 16.0517 8.36165 15.845 8.15499C15.6384 7.94834 15.3033 7.94834 15.0967 8.15499L12 11.2517L8.90335 8.15499C8.69669 7.94834 8.36164 7.94834 8.15499 8.15499C7.94834 8.36165 7.94834 8.6967 8.15499 8.90335L11.2516 12L8.15506 15.0966C7.94841 15.3033 7.94841 15.6384 8.15506 15.845C8.36172 16.0517 8.69677 16.0517 8.90342 15.845L12 12.7484L15.0966 15.845C15.3032 16.0517 15.6383 16.0517 15.8449 15.845C16.0516 15.6384 16.0516 15.3033 15.8449 15.0966L12.7484 12L15.845 8.90335Z"
            fill="#6B6B6B"
          />
        </svg>
      );
      break;

    // placePreview에서 사용하는 이모티콘들
    case '전화걸기':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M10.1638 11.24L11.2838 10.12C11.4347 9.97104 11.6256 9.86906 11.8332 9.82646C12.0409 9.78385 12.2565 9.80246 12.4538 9.88002L13.8188 10.425C14.0182 10.506 14.1892 10.6441 14.3102 10.8221C14.4313 11 14.4969 11.2098 14.4988 11.425V13.925C14.4977 14.0714 14.4669 14.2161 14.4084 14.3502C14.3498 14.4844 14.2648 14.6054 14.1583 14.7058C14.0517 14.8062 13.926 14.8841 13.7886 14.9346C13.6512 14.9852 13.5051 15.0074 13.3588 15C3.79385 14.405 1.86385 6.30502 1.49885 3.20502C1.4819 3.05279 1.49738 2.89869 1.54427 2.75288C1.59116 2.60706 1.66839 2.47282 1.77088 2.35899C1.87337 2.24516 1.9988 2.15432 2.13893 2.09245C2.27905 2.03058 2.43068 1.99908 2.58385 2.00002H4.99885C5.21435 2.00066 5.42474 2.06575 5.60294 2.18694C5.78115 2.30812 5.91903 2.47984 5.99885 2.68002L6.54385 4.04502C6.62397 4.24155 6.64442 4.45734 6.60262 4.66543C6.56083 4.87351 6.45865 5.06467 6.30885 5.21502L5.18885 6.33502C5.18885 6.33502 5.83385 10.7 10.1638 11.24Z"
            fill="#F7F7F7"
          />
        </svg>
      );
      break;
    case '찜수하트':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
        >
          <path
            d="M4.4 1.05435e-06C1.97 1.05435e-06 0 1.89052 0 4.22249C0 8.44498 5.2 12.2836 8 13.1765C10.8 12.2836 16 8.44498 16 4.22249C16 1.89052 14.03 1.05435e-06 11.6 1.05435e-06C10.112 1.05435e-06 8.796 0.708995 8 1.79418C7.59427 1.23957 7.05526 0.786954 6.42861 0.474642C5.80196 0.16233 5.10612 -0.000478049 4.4 1.05435e-06Z"
            fill="#FBAF17"
          />
        </svg>
      );
      break;
    case '별점':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
        >
          <path
            d="M9.00561 13.486L4.06109 16.0588L5.00561 10.6095L1 6.75063L6.52774 5.95776L9 1L11.4723 5.95776L17 6.75063L12.9944 10.6095L13.9389 16.0588L9.00561 13.486Z"
            fill="#FBAF17"
            stroke="#FBAF17"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case '나침반':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
            stroke="#545750"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 15L9 11L5 10L14 6L10 15Z"
            stroke="#545750"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case '주소복사아이콘':
      svgContent = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="20"
          viewBox="0 0 16 20"
          fill="none"
        >
          <path
            d="M4.73333 2.8667H2.86667C2.3716 2.8667 1.8968 3.06337 1.54673 3.41343C1.19667 3.7635 1 4.23829 1 4.73337V15.9334C1 16.4284 1.19667 16.9032 1.54673 17.2533C1.8968 17.6034 2.3716 17.8 2.86667 17.8H5.66667M14.0667 9.40003V4.73337C14.0667 4.23829 13.87 3.7635 13.5199 3.41343C13.1699 3.06337 12.6951 2.8667 12.2 2.8667H10.3333M8.46667 14.0667V13.1334C8.46667 12.8858 8.565 12.6484 8.74003 12.4734C8.91507 12.2984 9.15246 12.2 9.4 12.2H10.3333M13.1333 12.2H14.0667C14.3142 12.2 14.5516 12.2984 14.7266 12.4734C14.9017 12.6484 15 12.8858 15 13.1334V14.0667M15 16.8667V17.8C15 18.0476 14.9017 18.285 14.7266 18.46C14.5516 18.635 14.3142 18.7334 14.0667 18.7334H13.1333M10.3333 18.7334H9.4C9.15246 18.7334 8.91507 18.635 8.74003 18.46C8.565 18.285 8.46667 18.0476 8.46667 17.8V16.8667"
            stroke="#545750"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.46771 1H6.60104C5.57011 1 4.73438 1.83574 4.73438 2.86667C4.73438 3.8976 5.57011 4.73333 6.60104 4.73333H8.46771C9.49864 4.73333 10.3344 3.8976 10.3344 2.86667C10.3344 1.83574 9.49864 1 8.46771 1Z"
            stroke="#545750"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case '전구아이콘':
      svgContent = (
        <svg
          width="15"
          height="20"
          viewBox="0 0 15 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 20C6.95 20 6.47933 19.8043 6.088 19.413C5.696 19.021 5.5 18.55 5.5 18H9.5C9.5 18.55 9.30433 19.021 8.913 19.413C8.521 19.8043 8.05 20 7.5 20ZM4.5 17C4.21667 17 3.979 16.904 3.787 16.712C3.59567 16.5207 3.5 16.2833 3.5 16C3.5 15.7167 3.59567 15.4793 3.787 15.288C3.979 15.096 4.21667 15 4.5 15H10.5C10.7833 15 11.021 15.096 11.213 15.288C11.4043 15.4793 11.5 15.7167 11.5 16C11.5 16.2833 11.4043 16.5207 11.213 16.712C11.021 16.904 10.7833 17 10.5 17H4.5ZM3.75 14C2.6 13.3167 1.68767 12.4 1.013 11.25C0.337667 10.1 0 8.85 0 7.5C0 5.41667 0.729333 3.646 2.188 2.188C3.646 0.729334 5.41667 0 7.5 0C9.58333 0 11.354 0.729334 12.812 2.188C14.2707 3.646 15 5.41667 15 7.5C15 8.85 14.6627 10.1 13.988 11.25C13.3127 12.4 12.4 13.3167 11.25 14H3.75ZM4.35 12H10.65C11.4 11.4667 11.979 10.8083 12.387 10.025C12.7957 9.24167 13 8.4 13 7.5C13 5.96667 12.4667 4.66667 11.4 3.6C10.3333 2.53333 9.03333 2 7.5 2C5.96667 2 4.66667 2.53333 3.6 3.6C2.53333 4.66667 2 5.96667 2 7.5C2 8.4 2.20433 9.24167 2.613 10.025C3.021 10.8083 3.6 11.4667 4.35 12Z"
            fill="black"
          />
        </svg>
      );
      break;
    case '운영시간아이콘':
      svgContent = (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.991 0C4.023 0 0 4.032 0 9C0 13.968 4.023 18 8.991 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 8.991 0ZM9 16.2C5.022 16.2 1.8 12.978 1.8 9C1.8 5.022 5.022 1.8 9 1.8C12.978 1.8 16.2 5.022 16.2 9C16.2 12.978 12.978 16.2 9 16.2ZM8.802 4.5H8.748C8.388 4.5 8.1 4.788 8.1 5.148V9.396C8.1 9.711 8.262 10.008 8.541 10.17L12.276 12.411C12.582 12.591 12.978 12.501 13.158 12.195C13.2032 12.1221 13.2333 12.0409 13.2464 11.9562C13.2595 11.8714 13.2555 11.7849 13.2345 11.7018C13.2135 11.6186 13.176 11.5406 13.1242 11.4722C13.0724 11.4039 13.0074 11.3467 12.933 11.304L9.45 9.234V5.148C9.45 4.788 9.162 4.5 8.802 4.5Z"
            fill="black"
          />
        </svg>
      );
      break;
    case '가격아이콘':
      svgContent = (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1V3V1ZM13 7V9V7ZM13 13V15V13ZM3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V6C1.53043 6 2.03914 6.21071 2.41421 6.58579C2.78929 6.96086 3 7.46957 3 8C3 8.53043 2.78929 9.03914 2.41421 9.41421C2.03914 9.78929 1.53043 10 1 10V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V10C18.4696 10 17.9609 9.78929 17.5858 9.41421C17.2107 9.03914 17 8.53043 17 8C17 7.46957 17.2107 6.96086 17.5858 6.58579C17.9609 6.21071 18.4696 6 19 6V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;
    case '편의시설아이콘':
      svgContent = (
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 7V5C6 3.667 6.8 1 10 1C13.2 1 14 3.667 14 5V7M1 7H2H1ZM19 7H18H19ZM18 7L17 17H3L2 7H18ZM18 7H14H18ZM2 7H6H2ZM10 11V13V11ZM13 11V13V11ZM7 11V13V11ZM6 7H14H6Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      break;

    default:
      svgContent = null;
  }

  return (
    <div style={{ height: 'auto', marginRight, marginTop, marginLeft, marginBottom }}>
      {svgContent}
    </div>
  );
};

export default SVGRenderer;
