import { Button, Grid, Typography, Link as MuiLink } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../../constant/colors';

export default function SignInBottomButtons({
  showLoginError,
  errorMessage,
  adminLogin,
  isMdScreen,
  isSmallScreen,
}) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        width: isSmallScreen ? '70vw' : isMdScreen ? '50vw' : '32vw',
        transition: 'width 0.5s ease',
      }}
    >
      <Grid container flexDirection="column">
        <ErrorMessage>{showLoginError && errorMessage}</ErrorMessage>
      </Grid>

      <Button
        fullWidth
        sx={{
          width: '100%',
          height: '3rem',
          border: '1px solid',
          backgroundColor: MAIN_ORANGE,
          '&:hover': {
            backgroundColor: FILTER_ORANGE,
          },
        }}
        type="submit"
      >
        <Typography color="white">로그인</Typography>
      </Button>
      {!adminLogin && (
        <Grid container display="flex" flexDirection="row-reverse" mt={3}>
          <Link onClick={() => navigate('/signup')}>
            <Typography>회원가입</Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
}

const Link = styled(MuiLink)({
  color: MAIN_ORANGE,
  textDecorationColor: MAIN_ORANGE,
  textDecorationThickness: '1px',
  textUnderlineOffset: '5px',
  cursor: 'pointer',
  '&:hover': {
    color: FILTER_ORANGE,
    textDecorationColor: FILTER_ORANGE,
  },
});
