import { useState, useEffect } from 'react';
export function useCapsLock() {
  const [capsLockOn, setCapsLockOn] = useState(false);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.getModifierState) {
        setCapsLockOn(event.getModifierState('CapsLock'));
      }
    };

    window.addEventListener('keyup', handleKeyDown);

    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  return capsLockOn;
}

export default useCapsLock;
