import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashBoardBottomContainer from '../../../components/Dashboard/DashBoardBottomContainer';
import BorderBottomText from '../../../components/Dashboard/BorderBottomText';
import { useSetRecoilState } from 'recoil';
import { adminInfo } from '../../../recoilAtom/recoilAtom';
import CardComponent from '../../../components/Dashboard/CardComponent';

const AdminDashBoardMain = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('recentAdminAtom');
    setAdminInfo(null);
    navigate('/admin');
  };

  const setAdminInfo = useSetRecoilState(adminInfo);
  return (
    <>
      <Grid container flexDirection="column">
        <Grid container flexWrap>
          <CardComponent
            title="회원가입 관리"
            description1="유저의 회원 가입 요청을 확인하고,"
            description2="승인 및 거부 할 수 있습니다."
            path="/admin/signuprequest"
          />
          <CardComponent
            title="회원 관리"
            description1="회원 상세 정보를 확인하고"
            description2="설정을 관리할 수 있습니다."
            path="/admin/users"
          />
        </Grid>
        <Grid container flexWrap>
          <CardComponent
            title="장소 확인"
            description1="신규 요청 장소나, 장소 정보 수정 요청을"
            description2="확인하고, 관리할 수 있습니다."
            path="/admin/placeconfirm"
          />
          <CardComponent
            title="정산 확인"
            description1="정산 요청한 금액을 확인하고,"
            description2="금액을 정산할 수 있습니다."
            path="/admin/settlementconfirm"
          />
        </Grid>
        <Grid container flexWrap>
          <CardComponent
            title="정산 처리 결과"
            description1="유저의 정산 요청 및 승인된 정산 내역을"
            description2="확인할 수 있습니다."
            path="/admin/settlementresult"
          />
        </Grid>
      </Grid>
      <DashBoardBottomContainer hasMultipleTexts>
        <BorderBottomText text="로그아웃" handleClick={handleLogout} />
      </DashBoardBottomContainer>
    </>
  );
};

export default AdminDashBoardMain;
