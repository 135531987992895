import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

export default function ListCountToggle({ isMdScreen, setLimit }) {
  return (
    <FormControl sx={{ display: 'flex', marginRight: 1 }}>
      <Select
        defaultValue="15"
        sx={{
          minWidth: isMdScreen && '80px',
          height: '40px',
          marginRight: 1,
          width: '100%',
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
        displayEmpty
        onChange={e => setLimit(e.target.value)}
      >
        <MenuItem value="15">{isMdScreen ? `15줄` : `15줄 보기`}</MenuItem>
        <MenuItem value="30">{isMdScreen ? `30줄` : `30줄 보기`}</MenuItem>
        <MenuItem value="100">{isMdScreen ? `100줄` : `100줄 보기`}</MenuItem>
        <MenuItem value="99999">{isMdScreen ? `전체` : `전체 보기`}</MenuItem>
      </Select>
    </FormControl>
  );
}
