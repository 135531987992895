import { Paper } from '@mui/material';

export default function DataContainerWrapper({ pageFrom, isMdScreen, isLargeScreen, children }) {
  const widthLargePage = ({ isMdScreen, isLargeScreen }) => {
    return {
      paddingTop: isMdScreen ? '2vw' : isLargeScreen ? '4vw' : '6vw',
      paddingBottom: isMdScreen ? '1vw' : isLargeScreen ? '3vw' : '6vw',
      paddingLeft: isMdScreen ? '2vw' : isLargeScreen ? '4vw' : '6vw',
      paddingRight: isMdScreen ? '2vw' : isLargeScreen ? '4vw' : '6vw',
      transition: 'padding 0.5s ease',
      minHeight: '100vh',
    };
  };

  return (
    <Paper
      alignItems="center"
      flexDirection="column"
      sx={
        pageFrom === 'placeConfirm'
          ? widthLargePage({ isMdScreen, isLargeScreen })
          : {
              paddingTop: '6vw',
              paddingBottom: '6vw',
              paddingLeft: '6vw',
              paddingRight: '6vw',
              minHeight: '100vh',
            }
      }
      elevation={3}
    >
      {children}
    </Paper>
  );
}
