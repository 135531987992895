import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Button, Modal, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { API_URL } from '../../../api/urls';
import { adminApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import styled from 'styled-components';
import AdminComment from '../../../components/AdminComment';
import ProposalReviewModal from './ProposalReviewModal';
import ComparePatchComp from '../../../components/ComparePatchComp/ComparePatchComp';
import { DescText } from '../../../components/InputForm/components/Amount';

const PlaceConfirmDetail = ({ open, handleModalClose, itemId, isMdScreen, isLargeScreen }) => {
  const queryClient = useQueryClient();
  const setModalProp = useSetRecoilState(modalProps);
  const [modalState, setModalState] = useState({
    isOpen: false,
    confirmType: '',
  });
  const { handleNavigate } = useRedirect({ admin: true });

  const closeProposalReview = () => {
    setModalState(prev => ({ ...prev, isOpen: false }));
  };

  const calculateWidth = (isMd, isLg) => {
    if (isMd) return null;
    if (isLg) return '70%';
    return '55%';
  };

  const calculateLeft = (isMd, isLg) => {
    if (isMd) return null;
    if (isLg) return '15%';
    return '25%';
  };

  const style = {
    position: 'absolute',
    transform: 'translate(-0%, -0%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 10,
    borderRadius: '5px',
    width: calculateWidth(isMdScreen, isLargeScreen),
    left: calculateLeft(isMdScreen, isLargeScreen),
    transition: 'width 0.5s ease',
  };

  const { register, control, watch, setValue, reset } = useForm();

  const fetchProposalsDetail = async fetchId => {
    try {
      const response = await adminApi.get(
        `${API_URL}/api/v1/place-information-provider/admin/proposals/${fetchId}`,
      );
      return response.data.proposal;
    } catch (error) {
      setModalProp({
        titleText: '로그인 정보가 만료 되었습니다.',
        text: '다시 로그인 해 주세요.',
        buttonText: '확인',
        onPress: () => handleNavigate(),
      });
    }
  };

  const { data } = useQuery(
    ['readPlaceConfirmDetail', itemId],
    () => fetchProposalsDetail(itemId),
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );
  useEffect(() => {
    if (data) {
      const isInside = data?.isInside;

      let placeCategory = [];
      if (data?.placeCategories?.length > 0) {
        placeCategory = data?.placeCategories;
      } else if (data.originalPlace) {
        if (data.originalPlace?.placeCategories) {
          if (data.originalPlace.placeCategories[0]?.length > 0) {
            placeCategory = data.originalPlace.placeCategories[0];
          }
        }
      }

      let keywords = [];
      if (data?.keywords) {
        keywords = data.keywords;
      } else if (data.originalPlace) {
        if (data.originalPlace.keywords) {
          keywords = data.originalPlace.keywords;
        }
      }

      reset({
        name: data?.name,
        address: data?.address,
        jibun: data.jibun,
        detailAddress: data?.detailAddress,
        phoneNumber: data?.phoneNumber,
        homepage: data?.homepage,
        isInside: isInside,
        startDate: data?.startedAt && data?.startedAt?.split(' ')[0],
        startTime: data?.startedAt && data?.startedAt?.split(' ')[1],
        endDate: data?.endedAt && data?.endedAt.split(' ')[0],
        endTime: data?.endedAt && data?.endedAt.split(' ')[1],
        operatingHours: data?.operatingHours.map(item => {
          if (!item.startAt && !item.endAt) return item;
          return {
            day: item.name,
            openingTime: item.startAt,
            closingTime: item.endAt,
          };
        }),
        products: data?.products,
        description: data?.description,
        // placeCategory: placeCategory,
        placeCategories: placeCategory,
        amenities: data?.amenities,
        keywords: keywords,
        region: data?.region,
        images: data?.images,
        isOpen: data?.isOpen,
      });
    }
  }, [data]);

  const values = watch();

  const recordTimestamps = [
    { key: 'createdAt', label: '생성일자' },
    { key: 'modifiedAt', label: '수정일자' },
    { key: 'checkedAt', label: '검토완료일' },
    { key: 'device', label: '기기 유형' },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleModalClose()}
        sx={{
          boxShadow: 'none',
          border: 'none',
          borderRadius: '20',
          overflow: 'auto',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ ...style }}>
          <Grid container spacing={2}>
            <Grid container flexDirection="row-reverse" mb={1}>
              <CloseIcon
                sx={{
                  color: '#D2D2D2FF',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => handleModalClose()}
              />
            </Grid>

            <TimestampsContainer>
              {recordTimestamps.map(({ key, label }) => (
                <TimeStampText key={key}>
                  {label} : {data?.[key]}
                </TimeStampText>
              ))}
            </TimestampsContainer>

            {data && <AdminComment comment={data?.comment} />}
            {!data?.isOpen && <DescText>*폐업 요청 제안서입니다.</DescText>}
            {data && (
              <ComparePatchComp
                register={register}
                control={control}
                formData={data}
                values={values}
                setValue={setValue}
                handleModalClose={handleModalClose}
              />
            )}
            <Grid item xs={4}>
              <Button
                sx={{
                  width: '100%',
                  height: '5vh',
                  marginTop: '1rem',
                  backgroundColor: '#0CAA09',
                  '&:hover': {
                    backgroundColor: '#089306',
                  },
                }}
                onClick={() => {
                  setModalState(prev => ({ ...prev, isOpen: true, confirmType: '승인' }));
                }}
              >
                <Typography color="white">승인</Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{
                  width: '100%',
                  height: '5vh',
                  marginTop: '1rem',
                  backgroundColor: '#FFA800',
                  '&:hover': {
                    backgroundColor: '#E59400',
                  },
                }}
                onClick={() => {
                  setModalState(prev => ({ ...prev, isOpen: true, confirmType: '보류' }));
                }}
              >
                <Typography color="white">보류</Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{
                  width: '100%',
                  height: '5vh',
                  marginTop: '1rem',
                  backgroundColor: '#E84444',
                  '&:hover': {
                    backgroundColor: '#D62D2D',
                  },
                }}
                onClick={() => {
                  setModalState(prev => ({ ...prev, isOpen: true, confirmType: '반려' }));
                }}
              >
                <Typography color="white">반려</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {modalState.isOpen && (
        <>
          <ProposalReviewModal
            itemId={itemId}
            modalState={modalState}
            closeProposalReview={closeProposalReview}
            handleModalClose={handleModalClose}
            queryClient={queryClient}
            formData={data}
            control={control}
            setModalProp={setModalProp}
          />
        </>
      )}
    </>
  );
};

export default PlaceConfirmDetail;

const TimestampsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const TimeStampText = styled.div`
  font-size: 0.895rem;
  margin-bottom: 10px;
  color: #6b6b6b;
`;
