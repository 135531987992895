import { Grid, Button } from '@mui/material';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { adminToken } from '../../../../recoilAtom/recoilAtom';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../../../constant/colors';

export default function FileDownload({ adjustments, setModalProp, width, url, fileName, ids }) {
  const adminTokenAtom = useRecoilValue(adminToken);

  const handleClickFileDownload = async () => {
    try {
      const allSettlementId = adjustments?.map(item => item);
      const response = await axios
        .post(
          url,
          { [ids]: allSettlementId },
          {
            authorization: adminTokenAtom,
            responseType: 'blob',
          },
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      console.log(error, 'error');
      setModalProp({
        text: '다운로드에 실패했습니다. 잠시 후 다시 시도해 주세요.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
    }
  };

  return (
    <Grid container justifyContent="center" mb={2}>
      <Button
        sx={{
          width: width,
          height: '7vh',
          background: MAIN_ORANGE,
          color: 'white',
          '&:hover': {
            backgroundColor: FILTER_ORANGE,
          },
        }}
        onClick={() => {
          handleClickFileDownload();
        }}
      >
        파일 다운로드
      </Button>
    </Grid>
  );
}
