import { forwardRef, useEffect, useState } from 'react';

import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Box, Checkbox, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { ERROR_RED } from '../../../constant/colors';
import { useTheme } from '@emotion/react';
import { calculateFontSize } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

/**
 * 시설정보 컴포넌트
 * @param errors
 * @param control
 * @param initData { 게시글 진입 시 기본 amenities 데이터 }
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param ref
 * @param amenities { 장소 수정/보류에서 체크한 항목들에 대한 amenities 데이터 }
 * @param setValue
 * @returns {JSX.Element}
 * @constructor
 */

function Amenities(
  {
    errors,
    control,
    initData,
    comparisonOriginalData,
    changedProperty,
    pageFrom,
    setValue,
    amenities,
  },
  ref,
) {
  const [dirtyAmenities, setDirtyAmenities] = useState(false);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const watchAmenities = useWatch({
    name: 'amenities',
    control,
  });
  const { replace: replaceAmenities } = useFieldArray({
    control,
    name: 'amenities',
  });

  useEffect(() => {
    const amenitiesSet = new Set(watchAmenities?.map(service => service.id));
    const initialValuesSet = new Set(amenities?.map(service => service.id));

    if (
      JSON.stringify(Array.from(amenitiesSet).sort()) !==
      JSON.stringify(Array.from(initialValuesSet).sort())
    ) {
      setDirtyAmenities(true);
    } else {
      setDirtyAmenities(false);
    }
  }, [watchAmenities]);

  return (
    <Grid container flexDirection="column" minHeight={240} alignItems="start" mt={4} ref={ref}>
      <Grid container flexDirection="column" flex>
        <Grid item xs={2}>
          <Typography
            mb={1}
            sx={{
              color:
                (pageFrom === 'editPlace' || pageFrom === 'comparison') && dirtyAmenities
                  ? ERROR_RED
                  : 'initial',
              flexShrink: 0,
              width: '80px',
              marginBottom: '10px',
              marginRight: '6%',
              fontSize: isMdScreen && '0.8rem',
            }}
          >
            시설정보*
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>
            해당하는 모든 사항을 선택해주세요.
          </Typography>
        </Grid>
        <Grid container>
          <Box
            display="flex"
            flexWrap="wrap"
            alignContent="center"
            paddingX="1vw"
            paddingTop="1.5vw"
            paddingBottom="40px"
          >
            <Controller
              name="amenities"
              control={control}
              render={({ field }) => (
                <>
                  {initData?.amenities?.map(service => (
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      key={service.id}
                      style={{
                        flexBasis: '33.33%',
                        padding: '8px',
                        alignItems: 'center',
                      }}
                      flexDirection="row"
                    >
                      {field && (
                        <Checkbox
                          {...field}
                          checked={field?.value?.some(item => item?.id === service?.id) || false}
                          onChange={e => {
                            let newValue;
                            if (e.target.checked) {
                              newValue = [...(field?.value || []), service];
                              replaceAmenities(newValue);
                            } else {
                              newValue = field?.value.filter(item => item.id !== service.id);
                              replaceAmenities(newValue);
                            }
                          }}
                          value={service.name}
                          style={{ padding: 0, marginRight: isMdScreen ? '5px' : '8px' }}
                        />
                      )}

                      <Typography mt={0.5} sx={{ fontSize: isMdScreen && '0.8rem' }}>
                        {service.name}
                      </Typography>
                    </Grid>
                  ))}
                </>
              )}
            />
          </Box>
        </Grid>
        <Typography mt={0.5} mb={0.5} sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
          {errors?.amenities && errors.amenities.message}
        </Typography>
        <Divider variant="middle" />
        <Grid item xs={2} />
      </Grid>
    </Grid>
  );
}

export default forwardRef(Amenities);
