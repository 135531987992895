import { Grid, TextField, Typography } from '@mui/material';

export default function InputField({
  label,
  register,
  type,
  placeholder,
  isMdScreen,
  isSmallScreen,
}) {
  return (
    <Grid container direction="column" alignItems="left" minHeight={129}>
      <Typography mb={1}>{label}</Typography>
      <TextField
        {...register}
        placeholder={placeholder}
        type={type}
        sx={{
          width: isSmallScreen ? '70vw' : isMdScreen ? '50vw' : '32vw',
          transition: 'width 0.5s ease',
        }}
      />
    </Grid>
  );
}
