import { styled } from 'styled-components';
import { FILTER_ORANGE, MAIN_ORANGE } from '../constant/colors';
import isMobileDevice from '../hooks/isMobileDevice';
import { NOTION_GUIDE_URL } from '../api/urls';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  background-color: ${props => (props.isRejectButton ? '#FFFFFF' : MAIN_ORANGE)};
  border: 1px solid;
  color: ${props => (props.isRejectButton ? MAIN_ORANGE : 'white')};
  &:hover {
    background-color: ${props => (props.isRejectButton ? '#FFFFFF' : FILTER_ORANGE)};
  }
`;

export default StyledButton;

const openNotionGuide = () => {
  window.open(NOTION_GUIDE_URL, '_blank');
};

export const GuideButton = ({ children }) => {
  if (!isMobileDevice()) {
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <StyledButton
            onClick={openNotionGuide}
            style={{
              position: 'absolute',
              top: '6px',
              backgroundColor: 'white',
              border: 'none',
              color: '#F09D06',
              height: '2rem',
              fontSize: '0.7rem',
              width: '6rem',
            }}
          >
            {children}
          </StyledButton>
        </div>
      </div>
    );
  }
  return null;
};

export const MobileGuideButton = ({ children }) => {
  return (
    <StyledButton
      onClick={openNotionGuide}
      style={{
        backgroundColor: 'white',
        border: 'none',
        color: '#F09D06',
        height: '1.8rem',
        fontSize: '0.7rem',
        width: '6rem',
        marginRight: '12px',
      }}
    >
      {children}
    </StyledButton>
  );
};
