import { Button, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../../constant/colors';
import { useEffect, useRef, useState } from 'react';

export default function SubmitButton({ control, data, pageFrom, submitText = '제출하기' }) {
  const submitCheckRef = useRef(null);
  const [disableBtn, setDisableBtn] = useState(true);

  const watch = useWatch({
    control,
  });

  useEffect(() => {
    const isWatchValid = data && watch.name;
    if (pageFrom === 'editPage') {
      if (isWatchValid) {
        if (!submitCheckRef.current) {
          let { images, ...rest } = watch;
          images = images
            .map(image => {
              const { id, ...rest } = image;
              return rest;
            })
            .sort((a, b) => {
              a.url?.localeCompare(b.url);
            })
            .map(JSON.stringify);
          submitCheckRef.current = JSON.stringify({ images, ...rest }).replace(/\\n/g, '');
        }
        let { images, ...rest } = watch;
        images = images
          .map(image => {
            const { id, ...rest } = image;
            return rest;
          })
          .sort((a, b) => {
            a.url?.localeCompare(b.url);
          })
          .map(JSON.stringify);
        const hasChanged =
          submitCheckRef.current !== JSON.stringify({ images, ...rest }).replace(/\\n/g, '');
        setDisableBtn(!hasChanged);
      } else {
        setDisableBtn(true);
      }
    } else {
      setDisableBtn(false);
    }
  }, [watch, data]);

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          height: '3rem',
          backgroundColor: MAIN_ORANGE,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: FILTER_ORANGE,
            boxShadow: 'none',
          },
          color: 'white',
        }}
        disabled={disableBtn}
        type="submit"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            return;
          }
        }}
      >
        <Typography color="white">{submitText}</Typography>
      </Button>
    </>
  );
}
