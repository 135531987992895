import { Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { ERROR_RED } from '../../../constant/colors';
import { useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { useTheme } from '@emotion/react';
import preventEnterSubmit from '../../../hooks/preventEnterSubmit';

/**
 * 전화번호 컴포넌트
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param register
 * @param values
 * @param dirtyFields
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */

export default function PhoneNumber({
  control,
  comparisonOriginalData,
  changedProperty,
  pageFrom,
  register,
  values,
  dirtyFields,
  data,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const watchPhoneNumber = useWatch({
    name: 'phoneNumber',
    control,
  });

  useEffect(() => {
    if (data?.phoneNumber === watchPhoneNumber) {
      delete dirtyFields?.phoneNumber;
    }
  }, [watchPhoneNumber]);

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isSafeNumber = phoneNumber => {
    const pattern = /^(0508)/;
    return pattern.test(phoneNumber);
  };

  useEffect(() => {
    if (data?.phoneNumber && isSafeNumber(data?.phoneNumber)) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [data]);

  return (
    <SectionContainer style={{ marginBottom: '24px' }}>
      <Typography
        sx={{
          color: changedProperty && ERROR_RED,
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        전화번호*
      </Typography>
      <ContentContainer style={{ marginTop: '-16px' }}>
        <Grid container flexDirection="row-reverse">
          <Typography sx={{ fontSize: '0.8rem' }}>안심번호는 등록하실 수 없습니다.</Typography>
        </Grid>
        <TextField
          {...register('phoneNumber')}
          placeholder="업체에 연락 가능한 번호를 입력해주세요."
          defaultValue=""
          type="text"
          fullWidth
          disabled={isDisabled}
          onChange={e => {
            e.target.value = e.target.value.replace(/[^0-9-]/g, '');
            register('phoneNumber').onChange(e);
          }}
          onKeyPress={preventEnterSubmit}
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        />
      </ContentContainer>
    </SectionContainer>
  );
}
