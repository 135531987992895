import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ERROR_RED } from '../../../constant/colors';
import { forwardRef } from 'react';
import { useTheme } from '@emotion/react';
import { calculateFontSize } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

/**
 * 실내외 여부 컴포넌트
 * @param errors
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */

function IsInside({ control, errors, comparisonOriginalData, changedProperty, pageFrom }, ref) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid container direction="row" alignItems="center" minHeight={84} ref={ref} mt={4}>
        <Typography
          mr={3}
          sx={{
            color:
              (pageFrom === 'editPlace' || pageFrom === 'comparison') && changedProperty
                ? ERROR_RED
                : null,
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          실내외 여부*
        </Typography>
        <RadioGroup
          row
          sx={{
            '.MuiInputLabel-root': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        >
          <Controller
            name="isInside"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  {...field}
                  value="실내"
                  checked={field.value === '실내'}
                  onChange={() => field.onChange('실내')}
                  label="실내"
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: isMdScreen && '0.8rem',
                    },
                  }}
                  control={
                    <Radio disabled={pageFrom === 'comparison' || pageFrom === 'readonly'} />
                  }
                />
                <FormControlLabel
                  {...field}
                  value="실외"
                  checked={field.value === '실외'}
                  onChange={() => field.onChange('실외')}
                  label="실외"
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: isMdScreen && '0.8rem',
                    },
                  }}
                  control={
                    <Radio disabled={pageFrom === 'comparison' || pageFrom === 'readonly'} />
                  }
                />
                <FormControlLabel
                  {...field}
                  value="실내외"
                  checked={field.value === '실내외'}
                  onChange={() => field.onChange('실내외')}
                  label="실내외"
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: isMdScreen && '0.8rem',
                    },
                  }}
                  control={
                    <Radio disabled={pageFrom === 'comparison' || pageFrom === 'readonly'} />
                  }
                />
              </>
            )}
          />
        </RadioGroup>
        <Grid item xs={2} />
        <Grid
          item
          xs={10}
          container
          flexDirection="column"
          minHeight={(pageFrom === 'comparison' && 50) || 20}
        >
          <Typography mt={0.5} sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
            {errors?.isInside && errors.isInside.message}
          </Typography>
          {pageFrom === 'comparison' && comparisonOriginalData?.isInside && (
            <Typography>{comparisonOriginalData?.isInside}</Typography>
          )}
        </Grid>
      </Grid>
      <Divider variant="middle" />
    </>
  );
}

export default forwardRef(IsInside);
