import { styled } from 'styled-components';

const AdminComment = ({ comment }) => {
  return (
    <AdminCommentContainer multiline={true}>
      <CommentText>{comment}</CommentText>
    </AdminCommentContainer>
  );
};
export default AdminComment;

const AdminCommentContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  display: block;
  border-radius: 3px;
  padding: 20px;
  line-height: 1.4;
  margin-bottom: 1rem;
`;

const CommentText = styled.p`
  white-space: pre-line;
`;
