import { Grid, Typography, Button, TextField, useMediaQuery } from '@mui/material';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useState } from 'react';
import { ERROR_RED, FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';
import { useTheme } from '@emotion/react';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '../../Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../recoilAtom/recoilAtom';

/**
 * 해시태그 키워드 컴포넌트
 * @param errors
 * @param useFieldArray
 * @param control
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param keywordsInput
 * @param values
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */

function KeywordButton({
  keyword,
  index,
  pageFrom,
  control,
  handleDeleteHashtag,
  keywordsFields,
  isMdScreen,
}) {
  return (
    <Controller
      key={keywordsFields.id}
      name="keywords"
      control={control}
      render={({ field }) => (
        <>
          <Button
            {...field}
            sx={{
              borderColor: MAIN_ORANGE,
              border: '1px solid',
              borderRadius: 50,
              marginRight: 1,
              marginBottom: 1,
              height: isMdScreen && '7vw',
              fontSize: isMdScreen && '0.5rem',
            }}
            onClick={() => {
              if (pageFrom === 'editPlace' || pageFrom === 'addPlace') {
                handleDeleteHashtag(index);
              }
            }}
            key={keyword.id}
          >
            <Typography
              sx={{
                fontSize: { xs: '0.7rem', md: '1rem' },
              }}
            >
              {keyword}
            </Typography>
            <CloseIcon
              sx={{
                marginLeft: 1,
                fontSize: { xs: '0.7rem', md: '1rem' },
              }}
            />
          </Button>
        </>
      )}
    />
  );
}

function Keywords({ errors, control, changedProperty, pageFrom, keywordsInput, values }, ref) {
  const [keywordInputValue, setKeywordInputValue] = useState(null);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const setModalProp = useSetRecoilState(modalProps);
  const {
    fields: keywordsFields,
    append: appendKeywords,
    remove: removeKeywords,
  } = useFieldArray({
    control,
    name: 'keywords',
  });

  const watchKeywords = useWatch({
    name: 'keywords',
    control,
  });

  const handleKeywordInput = e => {
    const { value } = e.target;

    if (value.length <= 100) {
      setKeywordInputValue(value);
    } else {
      setModalProp({
        text: '해시태그는 100자까지 입력 가능합니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
    }
  };

  const handleEnterKeyword = e => {
    if (e.key === 'Enter' || e.keyCode === 32 || e.key === ',' || e.key === '.') {
      e.preventDefault();
      if (e.nativeEvent.isComposing) return;
      handleAddKeyword(e.target.value);
    }
  };

  const handleAddKeyword = keyword => {
    if (watchKeywords?.length >= 10) {
      setModalProp({
        text: '해시태그는 최대 10개까지 등록 가능합니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    const trimmedKeyword = keyword?.trim();

    if (!trimmedKeyword) {
      setModalProp({
        text: '해시태그를 입력해 주세요.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    let formattedKeyword = keyword.replace(/[ ,.]/g, '');
    if (!formattedKeyword) return;
    if (watchKeywords && watchKeywords.includes(formattedKeyword)) {
      setModalProp({
        text: '이미 등록된 해시태그 입니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    appendKeywords(formattedKeyword);
    setKeywordInputValue('');
  };

  const handleDeleteHashtag = indexToRemove => {
    if (pageFrom === 'editPlace' || pageFrom === 'addPlace') {
      removeKeywords(indexToRemove);
    }
  };

  return (
    <SectionContainer>
      <div
        style={{
          display: 'flex',
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      >
        <Typography
          sx={{
            color:
              ((pageFrom === 'editPlace' && changedProperty) ||
                (pageFrom === 'comparison' && changedProperty)) &&
              ERROR_RED,
            flexShrink: 0,
            marginRight: '10px',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          해시태그*
        </Typography>
        <Tooltip text="엔터, 쉼표(,) 또는 마침표(.)로 해시태그를 등록하세요." pageFrom="editPlace">
          <InfoIcon sx={{ fontSize: isMdScreen ? '1.1rem' : '1.2rem' }} />
        </Tooltip>
      </div>
      <ContentContainer>
        {(pageFrom === 'editPlace' || pageFrom === 'addPlace') && keywordsInput && (
          <>
            <Grid container>
              <Grid item display="flex" xs={10}>
                <TextField
                  onChange={e => handleKeywordInput(e)}
                  onKeyPress={handleEnterKeyword}
                  placeholder="원활한 검색을 위한 해시태그를 입력해주세요. 최소 3개"
                  type="text"
                  fullWidth
                  value={keywordInputValue}
                  defaultValue=""
                  sx={{
                    '.MuiInputBase-input': {
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem',
                      },
                    },
                    marginBottom: 1,
                    marginRight: 1,
                  }}
                />
              </Grid>
              <Grid display="flex" fullWidth xs={2} item sx={{ marginBottom: 1 }}>
                <Button
                  fullWidth
                  onClick={() => {
                    handleAddKeyword(keywordInputValue);
                  }}
                  sx={{
                    background: MAIN_ORANGE,
                    '&:hover': {
                      backgroundColor: FILTER_ORANGE,
                    },
                  }}
                >
                  <AddIcon sx={{ color: 'white' }} />
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <Grid container flexDirection="row">
          {watchKeywords &&
            watchKeywords?.map((keyword, index) => (
              <KeywordButton
                keywordsFields={keywordsFields}
                key={index}
                keyword={keyword}
                index={index}
                handleDeleteHashtag={() => handleDeleteHashtag(index)}
                control={control}
                pageFrom={pageFrom}
                isMdScree={isMdScreen}
              />
            ))}
        </Grid>
        <Typography mt={0.5} sx={{ color: ERROR_RED }}>
          {errors?.keywords && errors?.keywords?.message}
        </Typography>
      </ContentContainer>
    </SectionContainer>
  );
}

export default forwardRef(Keywords);
