import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import API_URL from './urls';

const createApi = () => {
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  api.interceptors.request.use(
    request => {
      if (request.params instanceof Object) {
        request.params = snakecaseKeys(request.params, { deep: true });
      }
      if (request.data instanceof Object && request.data.constructor.name === 'Object') {
        request.data = snakecaseKeys(request.data, { deep: true });
      }

      if (request.data && request.data._parts) {
        request.headers['Content-Type'] = 'multipart/form-data';
      }

      return request;
    },

    error => {
      return { ok: false, errors: error };
    },
  );

  api.interceptors.response.use(
    response => {
      response.ok = response.status >= 200 && response.status < 300;
      if (response.data instanceof Object) {
        response.data = camelcaseKeys(response.data, { deep: true });
      }

      if (response.data && response.data.message instanceof Object) {
        response.data.message = camelcaseKeys(response.data.message, { deep: true });
        response.errors = response.data.message;
      }

      return response;
    },
    error => {
      const { message, code, config, request, response } = error;
      return Promise.reject({ ok: false, errors: error, message, code, config, request, response });
    },
  );

  return api;
};

export const adminApi = createApi();
export const userApi = createApi();
