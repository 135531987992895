import styled from 'styled-components';

const DashBoardBottomContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.hasMultipleTexts ? 'row-reverse' : 'row')};
  justify-content: space-between;
  padding: 0px 12px 0px 12px;
`;

export default DashBoardBottomContainer;
