import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { styled } from 'styled-components';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';
import { userApi } from '../../../api/api';
import API_URL from '../../../api/urls';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userInfo } from '../../../recoilAtom/recoilAtom';
import { CURRENT_TEST_ID } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

const getAddressList = async (searchText, setter) => {
  try {
    const response = await userApi.get(
      `${API_URL}/api/v1/place-information-provider/address/search?keyword=${
        searchText.split('(')[0]
      }`,
    );
    if (response.data) {
      const options = response.data.map(item => ({
        label: item.name,
        value: item.id,
        babyPass: item.hasBabypassCategory,
        pending: item.hasPendingProposal,
        ownerPlace: item.isOwnerPlace,
      }));
      setter(options);
    }
  } catch (error) {
    console.log(error);
  }
};

const CheckAddressModal = ({ control }) => {
  const previouseAddress = useRef(null);
  const watchAddress = useWatch({ control, name: 'address' });
  const [open, setOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const [userInformation, setUserInformation] = useRecoilState(userInfo);

  const clickToBackDrop = e => {
    if (e.target === e.currentTarget) {
      setAddressList([]);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (userInformation.id !== CURRENT_TEST_ID) {
      if (!!watchAddress) {
        if (previouseAddress.current === null) {
          previouseAddress.current = watchAddress;
        }
        if (previouseAddress.current !== watchAddress) {
          getAddressList(watchAddress, setAddressList);
        }
      }
    }
  }, [watchAddress]);

  useEffect(() => {
    if (addressList.length > 0) {
      setOpen(true);
    }
  }, [addressList]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      previouseAddress.current = watchAddress;
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) {
    return null;
  }
  return (
    <div
      onClick={clickToBackDrop}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.3)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          padding: '20px',
          minWidth: '350px',
          width: '100%',
          maxWidth: '60vw',
          maxHeight: '80vh',
        }}
      >
        <Typography>아래의 장소를 찾으셨나요?</Typography>
        <Typography style={{ fontSize: '0.7rem', color: '#bbb' }}>
          장소 이름을 클릭하면 수정페이지로 이동합니다.
        </Typography>
        <AddressList arr={addressList} closeModal={() => setOpen(false)} />
        <Button
          sx={{
            width: '100%',
            height: '3.5rem',
            border: '1px solid',
            color: 'white',
            backgroundColor: MAIN_ORANGE,
            '&:hover': {
              backgroundColor: FILTER_ORANGE,
            },
          }}
          onClick={() => setOpen(false)}
        >
          아니요, 신규로 등록합니다.
        </Button>
      </div>
    </div>
  );
};

export default CheckAddressModal;

const AddressList = ({ arr, closeModal }) => {
  const navigate = useNavigate();

  async function handleClick(item) {
    if (item.babyPass) {
      alert('애가패스 전용 장소입니다. 해당 장소는 수정하실 수 없습니다.');
    } else if (item.ownerPlace || item.label === '애기야가자') {
      alert('사업주가 관리하는 장소입니다. 해당 장소는 수정하실 수 없습니다.');
    } else if (item.pending) {
      alert('검토 중인 장소입니다. 해당 장소는 관리자의 검토가 진행 중인 장소입니다.');
    } else {
      navigate(-1);
      setTimeout(() => {
        navigate('/searchplace/' + item.value);
      }, 100);
    }
  }

  return (
    <SearchDataContainer>
      {arr.map((item, index) => (
        <SearchDataItem
          key={index}
          onClick={() => {
            handleClick(item);
          }}
        >
          <Typography style={{ fontSize: '0.8rem' }}>{item.label}</Typography>
        </SearchDataItem>
      ))}
    </SearchDataContainer>
  );
};

const SearchDataContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 50vh;
  margin-bottom: 30px;
  flex-basis: 0;
  flex-shrink: 0;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${MAIN_ORANGE};
    border-radius: 20px;
  }
`;

const SearchDataItem = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  padding: 15px;
  border-color: transparent;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;
