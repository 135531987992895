import { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../recoilAtom/recoilAtom';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAIN_ORANGE } from '../constant/colors';
import isMobileDevice from '../hooks/isMobileDevice';
import { Grid } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { MobileGuideButton } from './StyledButton';

function MobileBackButton() {
  const setModalProp = useSetRecoilState(modalProps);
  const isMobile = isMobileDevice();
  const navigate = useNavigate();
  const location = useLocation();
  const splitPathName = location.pathname.split('/searchplace/');
  const pathId = splitPathName[splitPathName.length - 1];
  const [showButton, setShowButton] = useState(false);
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 120);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickBack = () => {
    if (
      (location.pathname === '/addplace' || location.pathname === `/searchplace/${pathId}`) &&
      location.pathname !== `/propsedplace/${pathId}`
    ) {
      setModalProp({
        titleText: '제안서 작성 취소',
        text: '현재 작성 중인 제안서가 삭제됩니다.',
        text2: '작성을 취소하시겠습니까?',
        isSelect: true,
        buttonText: '작성 취소',
        buttonText2: '닫기',
        onPress: () => {
          navigate(-1);
          setModalProp(null);
        },
        onPress2: () => setModalProp(null),
      });
    } else {
      if (location.pathname === '/PlacePreview') {
        return window.close();
      }
      goBack();
    }
  };

  const shouldDisplayBackButton =
    isMobile &&
    ![
      '/',
      '/login',
      '/login/',
      '/admin',
      '/admin/',
      '/admin/dashboard',
      '/admin/dashboard/',
    ].includes(location.pathname);
  const showBackOutsideDashboard =
    isMobile && !['/', '/dashboard', '/dashboard/'].includes(location.pathname);

  const showGuideButtonPaths = ['/dashboard', '/searchplace', '/addplace'];
  const showGuideButton =
    isMobile && showGuideButtonPaths.some(path => location.pathname.startsWith(path));

  return (
    <Grid
      container
      sx={{
        minHeight: '40px',
        top: 0,
        backgroundColor: MAIN_ORANGE,
        borderTop: '1.6px solid #FBAF17',
        zIndex: 99,
        justifyContent: showBackOutsideDashboard ? 'space-between' : 'flex-end',
      }}
      alignContent="center"
      paddingY={2}
    >
      {shouldDisplayBackButton && (
        <>
          {showBackOutsideDashboard && (
            <NavigateBeforeIcon
              sx={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => handleClickBack()}
            />
          )}
          {showGuideButton && <MobileGuideButton>장소 등록 가이드</MobileGuideButton>}
          {showButton && (
            <Grid
              container
              sx={{
                minHeight: '40px',
                position: 'fixed',
                top: 0,
                backgroundColor: MAIN_ORANGE + 40,
                borderTop: '1.6px solid #FBAF17',
                zIndex: 99,
                justifyContent: showBackOutsideDashboard ? 'space-between' : 'flex-end',
              }}
              alignContent="center"
              paddingY={2}
            >
              {showBackOutsideDashboard && (
                <NavigateBeforeIcon
                  sx={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => handleClickBack()}
                />
              )}
              {showGuideButton && <MobileGuideButton>장소 등록 가이드</MobileGuideButton>}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default MobileBackButton;
