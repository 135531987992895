import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Paper, Container, useMediaQuery, Typography, Divider } from '@mui/material';
import Header from '../../components/Header';
import { userApi } from '../../api/api';
import useRedirect from '../../hooks/useRedirect';
import { useTheme } from '@emotion/react';
import API_URL from '../../api/urls';
import { modalProps, userInfo } from '../../recoilAtom/recoilAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Name from '../../components/InputForm/components/Name';
import Address from '../../components/InputForm/components/Address';
import useFetchInitData from '../../hooks/useFetchInitData';
import Amenities from '../../components/InputForm/components/Amenities';
import IsInside from '../../components/InputForm/components/IsInside';
import Images from '../../components/InputForm/components/Images';
import OperatingHours from '../../components/InputForm/components/OperatingHours';
import Products from '../../components/InputForm/components/Products';
import PhoneNumber from '../../components/InputForm/components/PhoneNumber';
import HomePage from '../../components/InputForm/components/HomePage';
import Description from '../../components/InputForm/components/Description';
import SubmitButton from '../../components/InputForm/components/SubmitButton';
import { styled } from 'styled-components';
import CheckAddressModal from '../../components/InputForm/components/CheckAddressModal';
import isMobileDevice from '../../hooks/isMobileDevice';
import { GuideButton } from '../../components/StyledButton';
import { CURRENT_TEST_ID } from '../DashBoard/UserDashBoard/DashBoard';
import PlacePreviewPage from '../PlacePreview/PlacePreviewPage';
import Preview from '../../components/InputForm/components/Preview';
import usePreventBack from '../../hooks/usePreventBack';
import { FormController } from '../../utils/formController';

const AddPlace = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const setModalProp = useSetRecoilState(modalProps);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { initData } = useFetchInitData();
  useRedirect();

  const [userInformation, setUserInformation] = useRecoilState(userInfo);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const { preventGoBack } = usePreventBack({
    openPreviewModal,
    setOpenPreviewModal,
  });

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);

  const nameRef = useRef();
  const addressRef = useRef();
  const amenitiesRef = useRef();
  const isInsideRef = useRef();
  const imagesRef = useRef();
  const endDateRef = useRef();
  const operatingRef = useRef();
  const productsRef = useRef();

  const isAllNull = obj => Object.values(obj).every(key => key === null);

  const tempData =
    localStorage.getItem('placeTemp') && JSON.parse(localStorage.getItem('placeTemp'));

  const handleUseTempData = () => {
    reset({
      name: tempData?.name,
      address: tempData?.address,
      detailAddress: tempData?.detailAddress,
      phoneNumber: tempData?.phoneNumber,
      homepage: tempData?.homepage,
      isInside: tempData?.isInside,
      startDate: tempData?.startDate,
      startTime: tempData?.startTime,
      endDate: tempData?.endDate,
      endTime: tempData?.endTime,
      operatingHours: tempData?.operatingHours,
      products: tempData?.products,
      description: tempData?.description,
      amenities: tempData?.amenities,
      images: tempData?.images && tempData?.images,
    });
    setModalProp(null);
  };

  const handleDeleteTempData = () => {
    localStorage.removeItem('placeTemp');
    setModalProp(null);
  };

  const handleNavigateList = () => {
    navigate('/propsedplace');
    setModalProp(null);
  };

  const handleNavigateSearch = () => {
    navigate('/searchplace');
    setModalProp(null);
  };

  useEffect(() => {
    if (localStorage.getItem('placeTemp')) {
      setModalProp({
        titleText: '임시 저장된 데이터가 있습니다.',
        text: '저장된 데이터를 불러 오시겠습니까?',
        isSelect: true,
        buttonText: '예',
        buttonText2: '아니오',

        onPress: () => handleUseTempData(),
        onPress2: () => handleDeleteTempData(),
      });
    }
  }, []);

  const { register, handleSubmit, control, setValue, reset, setError, formState, getValues } =
    useForm({
      defaultValues: {
        name: '',
        address: '',
        detailAddress: '',
        phoneNumber: '',
        homepage: '',
        isInside: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',

        operatingHours: [
          {
            day: '',
            openingTime: '',
            closingTime: '',
          },
        ],
        products: [
          {
            name: '',
            price: '',
          },
        ],
        description: {},
        amenities: [],
        images: [],
      },
    });
  const { errors } = formState;
  const isMobile = isMobileDevice();
  const onSubmit = async formData => {
    const isValidateForm = FormController.validateForm(formData, setModalProp);

    if (isValidateForm) {
      const isEndDateInvalid =
        !formData.endDate ||
        formData.endDate === 'undefined' ||
        formData.endDate === 'Invalid Date';
      const isEndTimeInvalid =
        !formData.endTime || formData.endTime === null || formData.endTime === 'Invalid Date';

      const isStartDateInvalid =
        !formData.startDate ||
        formData.startDate === 'undefined' ||
        formData.startDate === 'Invalid Date';
      const isStartTimeInvalid =
        !formData.startTime || formData.startTime === null || formData.startTime === 'Invalid Date';

      let formattedStartDateTime = null;
      let formattedEndDateTime = null;
      if (isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = null;
      } else if (!isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} 23:59`;
      } else if (!isEndDateInvalid && !isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} ${formData.endTime}`;
      }
      if (isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = null;
      } else if (!isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} 23:59`;
      } else if (!isStartDateInvalid && !isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} ${formData.startTime}`;
      }

      const updateData = {
        name: formData.name,
        address: formData.address,
        jibun: formData.jibun,
        detailAddress: formData.detailAddress,
        phoneNumber: formData.phoneNumber,
        homepage: formData.homepage,
        isInside: formData.isInside,
        products: formData.products,
        description: formData.description,
        amenities: formData.amenities,
        images: formData.images,
        startedAt: formattedStartDateTime,
        endedAt: formattedEndDateTime,
        operatingHours: formData.operatingHours,
        region: formData.region,
        device: isMobile ? 'mobile' : 'pc',
      };

      if (updateData.products.length === 1 && isAllNull(updateData.products[0])) {
        updateData.products = null;
      }
      localStorage.setItem('placeTemp', JSON.stringify(formData));

      await handlePlaceSubmit(updateData);
    }
  };

  const handlePlaceSubmit = async updateData => {
    setModalProp(null);
    try {
      const response = await userApi.post(
        `${API_URL}/api/v1/place-information-provider/places/new`,
        updateData,
      );
      localStorage.removeItem('placeTemp');
      if (userInformation.id === CURRENT_TEST_ID) {
        setModalProp({
          titleText: '테스트 진행해주셔서 감사합니다',
          text: '내부 검토 후, 따로 연락드릴 예정입니다.',
          text2: '해당 안내창이 나온 이후는 페이지를 닫으셔도 정상적으로 처리됩니다.',
          isSelect: false,
          buttonText: '확인',
          // buttonText2: '작성하기',
          // proposalId: response.data.proposalId,
          onPress: () => handleNavigateSearch(),
        });
      } else {
        setModalProp({
          titleText: '제출이 완료되었습니다',
          text: '내부 검토 후, 장소 등록이 진행될 예정입니다.',
          text2: '완료까지 약 며칠의 시간이 소요 될 수 있습니다.',
          isSelect: true,
          buttonText: '목록보기',
          buttonText2: '작성하기',
          proposalId: response.data.proposalId,
          onPress: () => handleNavigateList(),
          onPress2: () => handleNavigateSearch(),
        });
      }
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          text: '장소 등록에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => {
            setModalProp(null);
          },
        });
      }
    }
  };

  const allValues = getValues();

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <GuideButton>장소 등록 가이드</GuideButton>

        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="신규 장소 등록"
          subTextFirstLine="장소 등록을 신청해주시면, 애기야가자에서 검토 후,"
          subTextSecondLine="최종 승인 후, 앱에 등록됩니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper
            sx={{
              paddingTop: '6vw',
              paddingBottom: '8vw',
              paddingLeft: '6vw',
              paddingRight: '6vw',
            }}
            elevation={3}
          >
            <Grid container flexDirection="row-reverse" mb={1}>
              <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>
                *는 필수 작성 항목입니다.
              </Typography>
            </Grid>

            <Name
              errors={errors}
              control={control}
              register={register}
              ref={nameRef}
              pageFrom="addPlace"
            />

            <Address
              errors={errors}
              control={control}
              register={register}
              ref={addressRef}
              setValue={setValue}
              pageFrom="addPlace"
              initData={initData}
            />

            <Amenities
              initData={initData}
              control={control}
              errors={errors}
              ref={amenitiesRef}
              setValue={setValue}
              pageFrom="addPlace"
            />

            <IsInside
              control={control}
              errors={errors}
              ref={isInsideRef}
              setValue={setValue}
              pageFrom="addPlace"
            />
            <Images
              control={control}
              errors={errors}
              ref={imagesRef}
              setValue={setValue}
              pageFrom="addPlace"
              setModalProp={setModalProp}
            />
            <OperatingHours
              control={control}
              reset={reset}
              values={allValues}
              pageFrom="addPlace"
              setValue={setValue}
              ref={operatingRef}
            />
            <Products
              control={control}
              reset={reset}
              values={allValues}
              register={register}
              pageFrom="addPlace"
              setValue={setValue}
              ref={productsRef}
            />

            <PhoneNumber
              control={control}
              values={allValues}
              register={register}
              pageFrom="addPlace"
            />

            <HomePage //
              register={register}
              pageFrom="addPlace"
            />

            <Description
              register={register}
              pageFrom="addPlace"
              setValue={setValue}
              control={control}
              endDateRef={endDateRef}
            />
            {/* <ExpectedPriceComp pageFrom="addPlace" control={control} /> */}

            <Preview setOpenPreviewModal={setOpenPreviewModal} />
            <SubmitButton control={control} />
          </Paper>
        </form>
        <CheckAddressModal control={control} />
      </Container>
      {openPreviewModal && (
        <PlacePreviewPage
          values={allValues}
          setOpenPreviewModal={setOpenPreviewModal}
          openPreviewModal={openPreviewModal}
        />
      )}
    </>
  );
};

export default AddPlace;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 280px;
`;
