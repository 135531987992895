import { useEffect, useState } from 'react';
import useFetchInitData from './useFetchInitData';

// 축제&공연 c168fad2-519a-4383-a3c4-452566566268
// 카페(국내) 503d471d-76fa-4303-b3a3-68382f447382
// 식당(국내) e84b34b7-c38a-48a0-a6bf-a78f00a8d557
// 크리스마스 78863021-870f-4aab-bcd2-b3d135ce6ae1
// 제주도 3f4d6d0f-1951-4123-9a22-c7278c03500e
// 할로윈 e97d0ecf-dcb0-4c67-9866-fa2cbe7a016a
// 어린이스포츠 04a550cc-3516-4a13-9124-3160d8df29cb
// 물놀이카페 ef027727-102e-4eb3-8f11-708ffc6d1041
// 공간대여 ef5405b4-0c11-4ad7-a569-19871abfa063
// 빛축제 523b133a-5a1f-440b-b49f-fc36030a8ac7
// 캠핑글램핑 b7fc1e0d-3bcf-4ba7-8922-fd22b3560468
// 가족탕 93f07a4a-cda8-4582-b879-690af099bcfb
// 물놀이 7727be4c-0ba3-4fa5-979b-16904759782d
// 동물친구 6f53fc8a-267a-4d74-8704-564b911ab8e1
// 장난감가게 2812e3fc-fd2b-4370-9173-736027944a71
// 봄소풍 583e718b-9862-434c-956c-d32ccb17cf6f
// 역사 8e2b1a9a-1334-4bb2-a680-1e0c5e1683ac
// 키즈숙박 0d29dcc1-6214-461a-9f65-8676922fa2cf
// YES키즈존 4e4c5f0c-0e80-42a6-b613-c148b36aa8df
// 자연과함께 54653ef6-3522-4234-b6c9-c55d7cd088bf
// 전시.체험.관람 78e65d74-07ae-4365-828b-b0a58acbc2c0
// 아이가아플때 01aef5b3-5ddd-4f6a-bc03-1b2c2a953870
// 키카.테마파크 2dfbe955-12f9-4c6b-a314-33ae1d602d25

export const useCustomCategory = () => {
  const [userCategory, setUserCategory] = useState([]);
  const { initData } = useFetchInitData();
  const allCategories = initData?.placeCategories;

  const requiredIds = [
    'c168fad2-519a-4383-a3c4-452566566268', // 축제&공연
    '503d471d-76fa-4303-b3a3-68382f447382', // 카페(국내)
    'e84b34b7-c38a-48a0-a6bf-a78f00a8d557', // 식당(국내)
    '78863021-870f-4aab-bcd2-b3d135ce6ae1', // 크리스마스
    '3f4d6d0f-1951-4123-9a22-c7278c03500e', // 제주도
    'e97d0ecf-dcb0-4c67-9866-fa2cbe7a016a', // 할로윈
    '04a550cc-3516-4a13-9124-3160d8df29cb', // 어린이스포츠
    'ef027727-102e-4eb3-8f11-708ffc6d1041', // 물놀이카페
    'ef5405b4-0c11-4ad7-a569-19871abfa063', // 공간대여
    '523b133a-5a1f-440b-b49f-fc36030a8ac7', // 빛축제
    'b7fc1e0d-3bcf-4ba7-8922-fd22b3560468', // 캠핑글램핑
    '93f07a4a-cda8-4582-b879-690af099bcfb', // 가족탕
    '7727be4c-0ba3-4fa5-979b-16904759782d', // 물놀이
    '6f53fc8a-267a-4d74-8704-564b911ab8e1', // 동물친구
    '2812e3fc-fd2b-4370-9173-736027944a71', // 장난감가게
    '583e718b-9862-434c-956c-d32ccb17cf6f', // 봄소풍
    '8e2b1a9a-1334-4bb2-a680-1e0c5e1683ac', // 역사
    '0d29dcc1-6214-461a-9f65-8676922fa2cf', // 키즈숙박
    '4e4c5f0c-0e80-42a6-b613-c148b36aa8df', // YES키즈존
    '54653ef6-3522-4234-b6c9-c55d7cd088bf', // 자연과함께
    '78e65d74-07ae-4365-828b-b0a58acbc2c0', // 전시.체험.관람
    '01aef5b3-5ddd-4f6a-bc03-1b2c2a953870', // 아이가아플때
    '2dfbe955-12f9-4c6b-a314-33ae1d602d25', // 키카.테마파크
    '0818db88-49c6-4be1-9cf9-da5035772ab7', // 여름물놀이
    'a8009bff-e656-46c7-b2e6-1cdd3c8f1cb7', // 가을나들이
    'beff19c3-9bea-4a41-9473-592d099b4694', // 겨울이야기
    '18a81cba-c37b-4051-bea1-a85afe116903', // 해당없음
  ];
  const categoryNameHandler = name => {
    if (name === '동물친구') {
      return '동물체험카페';
    }
    if (name === '자연과함께') {
      return '공원•놀이터•자연명소';
    }
    if (name === '아이가아플때') {
      return '병원•응급실•약국';
    }
    if (name === '식당') {
      return 'YES키즈존 식당';
    }
    if (name === '카페') {
      return 'YES키즈존 카페';
    } else return name;
  };

  useEffect(() => {
    if (initData) {
      const filterCategory = allCategories?.filter(category => requiredIds.includes(category.id));
      setUserCategory([...filterCategory]);
    }
  }, [initData]);

  // 해당없음 카테고리를 제외한 전체 어드민 카테고리
  const adminCategory = allCategories?.filter(category => requiredIds.includes(category.id));

  return { userCategory, categoryNameHandler, adminCategory, allCategories };
};
