import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { StyledCheckbox } from '../../Checkbox';
import DateSortArrows from '../../DateSortArrows';

export default function DataHeader({
  headerList,
  isAllChecked,
  setIsAllChecked,
  setCheckedIds,
  checkedIds,
  data,
  showCheckbox,
  pageFrom,
  fontSize,
  sortKey,
  setSortKey,
  handleSearch,
}) {
  useEffect(() => {
    const checkIfAllSelected = () => {
      if (data && checkedIds) {
        const dataIds = data.map(adjustment => adjustment.id);
        const sortedCheckedIds = checkedIds.sort();
        if (JSON.stringify(dataIds.sort()) === JSON.stringify(sortedCheckedIds)) {
          setIsAllChecked(true);
        } else {
          setIsAllChecked(false);
        }
      }
    };
    checkIfAllSelected();
  }, [checkedIds]);

  return (
    <Grid
      container
      sx={{
        minHeight: '40px',
        borderBottom: '1.2px solid #BBB',
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        borderTop: '1.6px solid #FBAF17',
        zIndex: 1,
        alignItems: 'center',
      }}
      alignContent="center"
      paddingY={2}
    >
      {headerList.map(
        (header, index) =>
          header.key !== 'modifiedAt' && (
            <Grid
              item
              xs={header.grid}
              display="flex"
              justifyContent="center"
              key={index}
              sx={{ alignItems: 'center' }}
            >
              {(() => {
                switch (header.key) {
                  case 'checkbox':
                    if (showCheckbox) {
                      return (
                        <StyledCheckbox
                          type="checkbox"
                          checked={isAllChecked}
                          onChange={e => {
                            if (e.target.checked) {
                              setIsAllChecked(true);
                              setCheckedIds(data?.map(item => item.id));
                            } else {
                              setIsAllChecked(false);
                              setCheckedIds([]);
                            }
                          }}
                        />
                      );
                    }
                    break;

                  default:
                    return (
                      <>
                        <Typography sx={{ fontSize: fontSize && fontSize }}>
                          {header.label}
                        </Typography>
                        {header.label === '작성일자' && pageFrom === 'placeConfirm' && (
                          <DateSortArrows
                            sortKey={sortKey}
                            setSortKey={setSortKey}
                            handleSearch={handleSearch}
                          />
                        )}
                      </>
                    );
                }
              })()}
            </Grid>
          ),
      )}
    </Grid>
  );
}
