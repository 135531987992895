import { TextField } from '@mui/material';
import { userApi } from '../../../api/api';
import API_URL from '../../../api/urls';
import { idRegex } from '../../../utils/regexRules';
import useDebounce from '../../../hooks/useDebounce';
import FormContainer from './FormContainer';

const validateIdValue = async e => {
  if (e.target.value.length === 0) {
    return '필수 입력란입니다.';
  }
  if (!idRegex.test(e.target.value)) {
    return '영문, 숫자를 조합하여 4~25자로 만들어주세요.';
  }

  try {
    await userApi.get(
      `${API_URL}/api/v1/place-information-provider/sign-up/check/username?provider_id=${e.target.value}`,
    );
  } catch (error) {
    const errorMessageJson = error.response.data;
    if (
      errorMessageJson.code === 'DuplicateUsername' ||
      errorMessageJson.code === 'InvalidParameter'
    ) {
      return errorMessageJson.message;
    }
  }

  return;
};

export default function ProviderId({ register, setValue, disabled }) {
  const [idError, debounceId] = useDebounce(validateIdValue, 2000);

  return (
    <FormContainer label="아이디" error={idError}>
      <TextField
        {...register('providerId', {
          required: '필수 입력란입니다.',
          pattern: {
            value: /^[A-Za-z0-9]{4,25}$/,
            message: '영문, 숫자를 조합하여 4~25자로 만들어주세요.',
          },
        })}
        placeholder="사용하실 아이디를 입력해주세요."
        type="text"
        onChange={e => {
          debounceId(e);
          setValue('providerId', e.target.value);
        }}
        disabled={disabled}
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </FormContainer>
  );
}
