import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`   
  ${reset}
  @font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-style: normal;
  }
  @font-face {
    font-family: 'GmarketSansBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
    font-style: normal;
  }

  *, html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, dl, dt, dd, ol, ul, li, form, label, table {
    box-sizing: border-box;
    font-family: 'GmarketSansMedium', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-size: 1rem;
  }

  body {

    background: linear-gradient(#FBAF17 320px, #F9F9F9 22.5vh );
  }


  
  a {
    text-decoration: none;
    color: inherit;
    &:hover, &:focus {
      text-decoration: underline;
      color: #545750;
    }
  }

  ol, ul {
    list-style: none;
  }

  button {
    border: 0;
    background: transparent;
    cursor: pointer;
  }
`;

export default GlobalStyle;
