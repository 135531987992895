import { Button, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../../recoilAtom/recoilAtom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adminApi } from '../../../../api/api';
import API_URL from '../../../../api/urls';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../../../constant/colors';
import CustomButton from '../../../../components/CustomButton';
export default function TopSubmitButtons({ settlementId, setSettlementId }) {
  const setModalProp = useSetRecoilState(modalProps);
  const queryClient = useQueryClient();

  const handleClickReject = () => {
    if (settlementId.length === 0) {
      toast.error('항목이 선택되지 않았습니다.');
      return;
    }

    setModalProp({
      titleText: '선택된 정산 항목들에 대해 거절 처리하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '확인',
      onPress: () => setModalProp(null),
      onPress2: () => {
        toast.success('정산 거절 처리 되었습니다.');
        handleSubmit.mutate({
          adjustmentIds: settlementId,
          result: 'rejected',
        });
        setModalProp(null);
        setSettlementId([]);
      },
    });
  };

  const handleClickApproved = () => {
    if (settlementId.length === 0) {
      toast.error('항목이 선택되지 않았습니다.');
      return;
    }

    setModalProp({
      titleText: '선택된 정산 항목들에 대해 완료 처리하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '확인',
      onPress: () => setModalProp(null),
      onPress2: () => {
        toast.success('정산 완료 처리 되었습니다.');
        handleSubmit.mutate({
          adjustmentIds: settlementId,
          result: 'approved',
        });
        setModalProp(null);
        setSettlementId([]);
      },
    });
  };

  const handleSubmit = useMutation({
    mutationFn: submitData => {
      const response = adminApi.post(
        `${API_URL}/api/v1/place-information-provider/admin/adjustments`,
        submitData,
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['readSettlement']);
    },
  });

  return (
    <>
      <Grid item display="flex" sx={{ marginBottom: '10px' }}>
        <CustomButton
          text="거절"
          buttonHandler={() => handleClickReject()}
          isPrimaryButtonOrange
          marginRight={1}
        />

        <CustomButton text="완료" buttonHandler={() => handleClickApproved()} />
      </Grid>
    </>
  );
}
