import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { passwordRegex, idRegex } from '../utils/regexRules';
import { MAIN_ORANGE, FILTER_ORANGE } from '../constant/colors';
import { useWatch } from 'react-hook-form';

const SignUpRequestButton = ({ control, buttonText, pageFrom }) => {
  const [disabled, setDisabled] = useState(true);

  const watchValues = useWatch({
    control,
  });

  const {
    providerId,
    confirmPassword,
    password,
    name,
    nickname,
    bank,
    accountNumber,
    agreeToAllTerms,
  } = watchValues;
  const isButtonDisabled = () => {
    if (pageFrom === 'adminEditUser') {
      return (
        !providerId ||
        !nickname ||
        !name ||
        password !== confirmPassword ||
        (password !== '' && !passwordRegex.test(password))
      );
    } else if (pageFrom === 'editUser') {
      return (
        !agreeToAllTerms ||
        !providerId ||
        !nickname ||
        !name ||
        password !== confirmPassword ||
        (password !== '' && !passwordRegex.test(password))
      );
    } else {
      return (
        !agreeToAllTerms ||
        !providerId ||
        !nickname ||
        !name ||
        password !== confirmPassword ||
        !passwordRegex.test(password) ||
        !idRegex.test(providerId) ||
        bank === '' ||
        accountNumber === ''
      );
    }
  };

  useEffect(() => {
    setDisabled(isButtonDisabled());
  }, [watchValues, pageFrom]);

  return (
    <Button
      variant="contained"
      sx={{
        width: '100%',
        height: '3.2rem',
        backgroundColor: MAIN_ORANGE,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: FILTER_ORANGE,
          boxShadow: 'none',
        },
        color: 'white',
      }}
      type="submit"
      disabled={disabled}
    >
      <Typography color="white">{buttonText}</Typography>
    </Button>
  );
};

export default SignUpRequestButton;
