import React from 'react';
import { Button, Divider, Grid } from '@mui/material';

export default function TopTabList({
  setCurrentPage,
  setAdminTabList,
  adminTabList,
  setIsAllChecked,
  setCheckedPlace,
  tabList,
  fontSize,
}) {
  const handleChangeTabList = tab => {
    setAdminTabList(tab);
    setCurrentPage(1);
    setIsAllChecked(false);
    setCheckedPlace([]);
  };

  return (
    <>
      <Grid item display="flex" alignItems="center">
        {tabList.map((tab, index) => (
          <>
            <Grid item display="flex" alignItems="center">
              <React.Fragment key={index}>
                <Button
                  onClick={() => handleChangeTabList(tab)}
                  value={tab.value}
                  sx={{
                    fontSize: fontSize && fontSize,
                    color: tab.value === adminTabList.value ? 'null' : '#6B6B6B',
                    fontWeight: tab.value === adminTabList.value ? 600 : null,
                  }}
                >
                  {tab.label}
                </Button>

                {index !== tabList.length - 1 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      height: '0.8rem',
                      margin: 'auto 0',
                    }}
                  />
                )}
              </React.Fragment>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}
