import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { adminToken, adminInfo } from '../../recoilAtom/recoilAtom';
import API_URL from '../../api/urls';
import { adminSignInApi } from '../../api/sign';
import InputFieldWrapper from '../../components/SignIn/components/InputFieldWrapper';
import SignInBottomButtons from '../../components/SignIn/components/SignInBottomButtons';
import SingInLogo from '../../components/SignIn/components/SignInLogo';
import InputField from '../../components/SignIn/components/InputField';
import WrapperContainer from '../../components/SignIn/components/WrapperContainer';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import CapsLockIndicator from '../../components/InputForm/components/CapsLockIndicator';
import useCapsLock from '../../hooks/useCapsLock';

const AdminSignIn = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [showLoginError, setShowLoginError] = useState(false);
  const setAdminTokenAtom = useSetRecoilState(adminToken);
  const setAdminInfo = useSetRecoilState(adminInfo);
  const [errorMessage, setErrorMessage] = useState('');
  const capsLockOn = useCapsLock();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      id: '',
      password: '',
    },
    mode: 'onSubmit',
  });

  const fetchAdminInfo = async (token, signal) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/place-information-provider/admin/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal: signal,
      });
      setAdminInfo(response.data);
      navigate('/admin/dashboard');
    } catch (error) {
      if (error.code !== 'ERR_CANCELED' && error?.response?.data) {
        localStorage.removeItem('recentAdminAtom');
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchAdminData = () => {
      if (localStorage.getItem('recentAdminAtom')) {
        fetchAdminInfo(localStorage.getItem('recentAdminAtom'), signal);
      }
    };
    fetchAdminData();

    return () => abortController.abort();
  }, []);

  const onSubmit = async loginData => {
    setErrorMessage('');
    setShowLoginError(false);

    const { ok, data, response } = await adminSignInApi(loginData);

    if (ok) {
      setAdminTokenAtom(data.accessToken);
      fetchAdminInfo(data.accessToken);
      setShowLoginError(false);
      return;
    } else {
      const errorMessageJson = response.data;
      if (errorMessageJson?.code === 'NotAuthenticated') {
        setErrorMessage(errorMessageJson.message);
        setShowLoginError(true);
      } else {
        setErrorMessage('서버 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.');
        setShowLoginError(true);
      }
      return;
    }
  };

  return (
    <>
      <WrapperContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputField
              label="아이디"
              register={{
                ...register('id'),
              }}
              type="text"
              placeholder="아이디를 입력해주세요."
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
            />
            <InputField
              label="비밀번호"
              register={{
                ...register('password'),
              }}
              type="password"
              placeholder="비밀번호를 입력해주세요."
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
            />
            {capsLockOn && <CapsLockIndicator />}
            <SignInBottomButtons
              showLoginError={showLoginError}
              errorMessage={errorMessage}
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
              adminLogin
            />
            <SingInLogo isMdScreen={isMdScreen} isSmallScreen={isSmallScreen} />
          </InputFieldWrapper>
        </form>
      </WrapperContainer>
    </>
  );
};

export default AdminSignIn;
