import React, { useEffect } from 'react';
import { PERSONAL_INFO_CONSENT_URL, TERMS_OF_SERVICE_AGREE_URL } from '../../../api/urls';
import { Typography, Grid, Checkbox } from '@mui/material';
import { styled } from 'styled-components';
import { useWatch } from 'react-hook-form';

const LinkWrapper = styled.a`
  color: #545750;
  text-decoration-line: underline;
  text-decoration-color: #545750;
  text-underline-offset: 5px;
  cursor: pointer;
`;

const handleOpenLink = linkTo => {
  let url;
  if (linkTo === 'personalInfoConsentLink') {
    url = PERSONAL_INFO_CONSENT_URL;
  } else if (linkTo === 'termsOfServiceAgreeLink') {
    url = TERMS_OF_SERVICE_AGREE_URL;
  }
  return url;
};

const SignUpTerms = ({ register, setValue, control }) => {
  const watchValues = useWatch({
    control,
  });

  const { agreeToAllTerms, termsOfServiceAgree, personalInfoConsent } = watchValues;

  useEffect(() => {
    if (termsOfServiceAgree && personalInfoConsent) {
      setValue('agreeToAllTerms', true);
    }
    if (termsOfServiceAgree === false || personalInfoConsent === false) {
      setValue('agreeToAllTerms', false);
    }
  }, [termsOfServiceAgree, personalInfoConsent, agreeToAllTerms, setValue]);

  return (
    <Grid container direction="column" mb={5} mt={5}>
      <Grid container direction="row">
        <Grid item mr={3} xs={1}>
          <Checkbox
            {...register('agreeToAllTerms')}
            checked={agreeToAllTerms}
            onChange={() => {
              if (agreeToAllTerms === false) {
                setValue('termsOfServiceAgree', true);
                setValue('personalInfoConsent', true);
                setValue('agreeToAllTerms', true);
              }
              if (agreeToAllTerms === true) {
                setValue('termsOfServiceAgree', false);
                setValue('personalInfoConsent', false);
                setValue('agreeToAllTerms', false);
              }
            }}
            style={{ padding: 0 }}
          />
        </Grid>
        <Grid item>
          <Typography>약관 전체 동의</Typography>
          <Typography mb={4} fontSize={12}>
            가입을 위해 이용약관에 동의해주세요.
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item mr={3} xs={1}>
          <Checkbox
            {...register('termsOfServiceAgree')}
            checked={termsOfServiceAgree}
            onChange={() => {
              setValue('termsOfServiceAgree', !termsOfServiceAgree);
            }}
            style={{ padding: 0 }}
          />
        </Grid>

        <LinkWrapper
          href={handleOpenLink('termsOfServiceAgreeLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography mb={3}> (필수)이용약관 동의</Typography>
        </LinkWrapper>
      </Grid>

      <Grid container direction="row">
        <Grid item mr={3} xs={1}>
          <Checkbox
            {...register('personalInfoConsent')}
            checked={personalInfoConsent}
            style={{ padding: 0 }}
          />
        </Grid>

        <LinkWrapper
          href={handleOpenLink('personalInfoConsentLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          (필수)개인정보 수집 이용동의
        </LinkWrapper>
      </Grid>
    </Grid>
  );
};

export default SignUpTerms;
