import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { adminToken, modalProps, userToken } from '../recoilAtom/recoilAtom';
import AddPlace from '../pages/AddPlace/AddPlace';
import SignIn from '../pages/SignIn/SignIn';
import SignUp from '../pages/SignUp/SignUp';
import DashBoard from '../pages/DashBoard/UserDashBoard/DashBoard';
import SearchPlace from '../pages/SearchPlace/SearchPlace';
import SettlementRequest from '../pages/DashBoard/SettlementRequest/SettlementRequest';
import ProposedPlace from '../pages/DashBoard/ProposedPlace/ProposedPlace';
import RevisePage from '../pages/SearchPlace/RevisePage/RevisePage';
import AdminDashBoard from '../pages/AdminDashBoard/AdminDashBoard/AdminDashBoard';
import PlaceConfirm from '../pages/AdminDashBoard/PlaceConfirm/PlaceConfirm';
import SignUpRequest from '../pages/AdminDashBoard/SignUpRequest/SignUpRequest';
import SettlementConfirm from '../pages/AdminDashBoard/SettlementConfirm/SettlementConfirm';
import AdminSignIn from '../pages/AdminSignIn/AdminSignIn';
import SettlementResult from '../pages/DashBoard/SettlementResult/SettlementResult';
import AdminSettlementResult from '../pages/AdminDashBoard/AdminSettlementResult/AdminSettlementResult';
import EditUser from '../pages/DashBoard/UserDashBoard/EditUser';
import Users from '../pages/AdminDashBoard/Users/Users';
import AdminEditUser from '../pages/AdminDashBoard/Users/AdminEditUser';
import SettlementConfirmDetail from '../pages/AdminDashBoard/SettlementConfirm/components/SettlementConfirmDetail';
import PlaceStatusPage from '../pages/DashBoard/ProposedPlace/ProposalsDetail/PlaceStatusPage/PlaceStatusPage';

export default function Router() {
  const navigate = useNavigate();
  const setModalProp = useSetRecoilState(modalProps);
  const adminTokenState = useRecoilValue(adminToken);
  const userTokenState = useRecoilValue(userToken);
  const resetAdminToken = useResetRecoilState(adminToken);
  const resetUserToken = useResetRecoilState(userToken);

  const resetTokens = () => {
    if (adminTokenState.length > 0) {
      resetAdminToken();
    } else if (userTokenState.length > 0) {
      resetUserToken();
    }
  };

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === 'recentUserAtom' || event.key === 'recentAdminAtom') {
        let recentUserAtom;
        let recentAdminAtom;

        try {
          recentUserAtom = JSON.parse(window.localStorage.getItem('recentUserAtom'));
        } catch (error) {
          console.error('recentUserAtom 에러:', error);
        }

        try {
          recentAdminAtom = JSON.parse(window.localStorage.getItem('recentAdminAtom'));
        } catch (error) {
          console.error('recentAdminAtom 에러:', error);
        }

        if (recentUserAtom === null) {
          setModalProp({
            titleText: '유저 로그인 정보가 만료 되었습니다.',
            text: '다시 로그인 해 주세요.',
            buttonText: '확인',
            onPress: () => navigate('/login'),
          });
          resetTokens();
        } else if (recentAdminAtom === null) {
          setModalProp({
            titleText: '관리자 로그인 정보가 만료 되었습니다.',
            text: '다시 로그인 해 주세요.',
            buttonText: '확인',
            onPress: () => navigate('/admin'),
          });
          resetTokens();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    // return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="login" element={<SignIn />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="dashboard" element={<DashBoard />} />
      <Route path="addplace" element={<AddPlace />} />
      <Route path="searchplace" element={<SearchPlace />} />
      <Route path="propsedplace" element={<ProposedPlace />} />
      <Route path="propsedplace/:id" element={<PlaceStatusPage />} />
      <Route path="settlement" element={<SettlementRequest />} />
      <Route path="settlementresult" element={<SettlementResult />} />
      <Route path="searchplace/:id" element={<RevisePage />} />
      <Route path="admin" element={<AdminSignIn />} />
      <Route path="admin/dashboard" element={<AdminDashBoard />} />
      <Route path="admin/placeconfirm" element={<PlaceConfirm />} />
      <Route path="admin/users" element={<Users />} />
      <Route path="admin/signuprequest" element={<SignUpRequest />} />
      <Route path="admin/settlementconfirm" element={<SettlementConfirm />} />
      <Route path="admin/settlementresult" element={<AdminSettlementResult />} />
      <Route path="admin/editUser/:id" element={<AdminEditUser />} />
      <Route path="editUser" element={<EditUser />} />
      <Route path="admin/settlementconfirm/:id" element={<SettlementConfirmDetail />} />
    </Routes>
  );
}
