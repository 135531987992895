import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { userToken, userInfo, modalProps } from '../../recoilAtom/recoilAtom';
import { idRegex, passwordRegex } from '../../utils/regexRules';
import { signInApi } from '../../api/sign';
import { userApi } from '../../api/api';
import API_URL from '../../api/urls';
import { useNavigate } from 'react-router-dom';
import InputFieldWrapper from '../../components/SignIn/components/InputFieldWrapper';
import SignInBottomButtons from '../../components/SignIn/components/SignInBottomButtons';
import SingInLogo from '../../components/SignIn/components/SignInLogo';
import InputField from '../../components/SignIn/components/InputField';
import WrapperContainer from '../../components/SignIn/components/WrapperContainer';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import CapsLockIndicator from '../../components/InputForm/components/CapsLockIndicator';
import useCapsLock from '../../hooks/useCapsLock';

const SignIn = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showLoginError, setShowLoginError] = useState(false);
  const setUserInfo = useSetRecoilState(userInfo);
  const setUserTokenAtom = useSetRecoilState(userToken);
  const [errorMessage, setErrorMessage] = useState('');
  const capsLockOn = useCapsLock();
  const navigate = useNavigate();
  const setModalProp = useSetRecoilState(modalProps);

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      id: '',
      password: '',
    },
    mode: 'onSubmit',
  });

  const fetchUserInfo = async (token, signal) => {
    try {
      const response = await userApi.get(`${API_URL}/api/v1/place-information-provider/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        signal: signal,
      });
      setUserInfo(response.data);
      navigate('/dashboard');
    } catch (error) {
      if (error.code !== 'ERR_CANCELED' && error?.response?.data) {
        localStorage.removeItem('recentUserAtom');
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = () => {
      // TODO: 자동 로그인 임시 주석처리
      // if (localStorage.getItem('recentUserAtom')) {
      //   fetchUserInfo(localStorage.getItem('recentUserAtom'), signal);
      // }
    };
    fetchData();

    return () => abortController.abort();
  }, []);

  const onSubmit = async loginData => {
    const values = getValues();
    setErrorMessage('');
    setShowLoginError(false);

    if (!values.id || !values.password) {
      setErrorMessage('필수 입력란입니다.');
      setShowLoginError(true);

      return;
    }

    if (!idRegex.test(values.id) || !passwordRegex.test(values.password)) {
      setShowLoginError(true);
      setErrorMessage('아이디 혹은 비밀번호를 다시 확인해 주세요.');
      return;
    }

    const { ok, data, response } = await signInApi(loginData);

    if (ok) {
      setUserTokenAtom(data.token);
      fetchUserInfo(data.token);
      setShowLoginError(false);
      return;
    } else {
      setShowLoginError(true);
      if (response.status < 500) {
        if (response.data.code === 'LoginError') {
          setErrorMessage(response.data.message);
          setShowLoginError(true);
        } else if (response.data.code === 'InactiveUser') {
          setErrorMessage(response.data.message);
          setShowLoginError(true);
        } else if (response.data.detail === 'ServiceUnderMaintenance') {
          setModalProp({
            titleText: '서버 점검 안내',
            text: '불편을 드려 죄송합니다.  현재 서버 점검으로 이용이 불가능합니다.',
            text2: `빠른 시일 내 서비스를 제공 드릴 수 있도록 하겠습니다. 감사합니다.`,
            buttonText: '확인',
            onPress: () => setModalProp(null),
          });
        }
        return;
      } else if (response.status >= 500) {
        setShowLoginError(true);
        setErrorMessage(
          '예상치 못한 에러가 발생했습니다. 지속적으로 발생 시 고객센터에 문의 바랍니다.',
        );
        return;
      } else {
        setShowLoginError(true);
        setErrorMessage(
          '예상치 못한 에러가 발생했습니다. 지속적으로 발생 시 고객센터에 문의 바랍니다.',
        );
        return;
      }
    }
  };

  return (
    <>
      <WrapperContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper>
            <InputField
              label="아이디"
              register={{
                ...register('id'),
              }}
              type="text"
              placeholder="아이디를 입력해주세요."
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
            />
            <InputField
              label="비밀번호"
              register={{
                ...register('password'),
              }}
              type="password"
              placeholder="비밀번호를 입력해주세요."
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
            />
            {capsLockOn && <CapsLockIndicator />}
            <SignInBottomButtons
              showLoginError={showLoginError}
              errorMessage={errorMessage}
              isMdScreen={isMdScreen}
              isSmallScreen={isSmallScreen}
            />
            <SingInLogo isMdScreen={isMdScreen} isSmallScreen={isSmallScreen} />
          </InputFieldWrapper>
        </form>
      </WrapperContainer>
    </>
  );
};

export default SignIn;
