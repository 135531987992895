import React, { useMemo, useState } from 'react';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../../../api/api';
import API_URL, { EMAIL_ADDRESS } from '../../../api/urls';
import styled from 'styled-components';
import useRedirect from '../../../hooks/useRedirect';
import Header from '../../../components/Header';
import CampaignIcon from '@mui/icons-material/Campaign';
import RequestCompletedModal from './RequestCompletedModal';
import SettlementModal from '../SettlementRequest/SettlementModal';
import DataHeader from '../../../components/DataList/components/DataHeader';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataItem from '../../../components/DataList/components/DataItem';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import SettlementAmountDisplay from '../SettlementResult/components/SettlementAmountDisplay';
import SettlementRequestButton from '../SettlementResult/components/SettlementRequestButton';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const headerList = [
  { label: '작성일자', grid: 3, key: 'createdAt' },
  { label: '장소명', grid: 5, key: 'name' },
  { label: '상태', grid: 2, key: 'categoryDisplay' },
  { label: '금액', grid: 2, key: 'payAmount' },
];

const SettlementRequest = () => {
  useRedirect();

  const [requestModal, setRequestModal] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(['fetchApprovedProposal'], () => fetchApprovedProposals());
  const approvedProposals = data && data.approvedProposals;

  function requestDayChecker() {
    const day = dayjs().date();
    return day >= 1 && day <= 5;
  }

  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isRequestDay = requestDayChecker();

  const handleModalOpen = () => {
    if (isRequestDay) {
      setRequestModal(true);
      return;
    }
  };

  const handleModalClose = () => {
    setRequestModal(false);
  };

  const handleRequestModalOpen = () => {
    setRequestCompleted(true);
  };

  const handleRequestModalClose = () => {
    setRequestCompleted(false);
  };

  const fetchApprovedProposals = async () => {
    try {
      const response = await userApi.get(
        `${API_URL}/api/v1/place-information-provider/me/adjustments`,
      );
      return response.data;
    } catch (error) {}
  };

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="정산 요청"
          subTextFirstLine="적립된 금액을 확인하거나,"
          subTextSecondLine="정산을 요청할 수 있습니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <DataContainerWrapper>
          <DataContainer pageFrom="settlementRequest">
            <NoticeContainer>
              <CampaignIcon sx={{ color: '#6b6b6b' }} />
              <div style={{ marginLeft: '8px' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NoticeText>
                  정산요청 이후 애기야가자 이메일({EMAIL_ADDRESS})로 신분증 사본과 통장 사본을
                  보내주세요!
                </NoticeText>
                <div style={{ height: '5px' }} />
                <PriceNoticeText>
                  (33,340원 이상일 경우 3.3% 사업 소득세 발생으로 해당 금액을 제외한 정산 금액이
                  입급됩니다.)
                </PriceNoticeText>
              </div>
            </NoticeContainer>

            <DataHeader headerList={headerList} fontSize={isMdScreen && '0.62rem'} />
            {!isLoading && (
              <>
                {approvedProposals?.length ? (
                  <Grid container>
                    {approvedProposals?.map((proposal, index) => (
                      <DataItem
                        headerList={headerList}
                        resultList={approvedProposals}
                        status={proposal.category}
                        result={proposal}
                        key={index}
                        index={index}
                        fontSize={isMdScreen && '0.62rem'}
                        pageFrom="settlementRequest"
                      />
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '30vh',
                    }}
                  >
                    <Grid item>정산 요청 가능한 내역이 없습니다.</Grid>
                  </Grid>
                )}
              </>
            )}
          </DataContainer>
          <SettlementAmountDisplay approvedProposals={approvedProposals} />
          {!isLoading && (
            <SettlementRequestButton
              handleModalOpen={handleModalOpen}
              disabled={
                approvedProposals?.length === 0 ||
                approvedProposals?.reduce((acc, item) => acc + Number(item.payAmount), 0) < 10000 ||
                !isRequestDay
              }
              isRequestDay={isRequestDay}
            />
          )}
        </DataContainerWrapper>

        {requestModal && (
          <SettlementModal
            open={requestModal}
            handleModalClose={handleModalClose}
            approvedProposals={approvedProposals}
            handleRequestModalOpen={handleRequestModalOpen}
            queryClient={queryClient}
          />
        )}

        {requestCompleted && (
          <RequestCompletedModal
            open={requestCompleted}
            handleModalClose={handleRequestModalClose}
          />
        )}
      </Container>
    </>
  );
};

export default SettlementRequest;

const NoticeContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
  align-items: center;
`;

const NoticeText = styled.p`
  @media (min-width: 600px) {
    font-size: 0.8rem;
  }
  font-size: 0.62rem;
  color: #6b6b6b;
`;

const PriceNoticeText = styled.p`
  @media (min-width: 600px) {
    font-size: 0.75rem;
  }
  font-size: 0.5rem;
  color: #6b6b6b;
`;
