import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../recoilAtom/recoilAtom';
import { adminApi, userApi } from '../api/api';
import { useNavigate } from 'react-router-dom';

const useFetchInitData = callback => {
  const setModalProp = useSetRecoilState(modalProps);
  const [initData, setInitData] = useState(null);
  const navigate = useNavigate();

  const navigatePrevPage = () => {
    navigate(-1);
  };

  const fetchInitPlace = signal => {
    userApi
      .get('/api/v1/place-information-provider/places/init', { signal: signal })
      .then(response => {
        setInitData(response.data);
      })
      .catch(error => {
        console.log(error, 'error');
        // setModalProp({
        //   text: '초기 데이터를 불러오는 데 실패하였습니다. 이전 페이지로 이동합니다.',
        //   buttonText: '확인',
        //   onPress: callback ? () => callback() : () => navigatePrevPage(),
        // });
      });
  };
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchInitPlace(signal);
  }, []);
  return { initData };
};

export default useFetchInitData;
