import React, { useState } from 'react';
import DaumPostcode from 'react-daum-postcode';

import { Typography, Button, Modal, Box } from '@mui/material';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';

const PlaceInfoModal = ({ setValue, open, setOpen, initData }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    borderRadius: '5px',
  };

  const [isSearch, setIsSearch] = useState(false);
  const [openJibunEditInput, setOpenJibunEditInput] = useState(false);
  const [jibun, setJibun] = useState('');

  const handlePostCode = async data => {
    const {
      address,
      addressType,
      bname,
      buildingName,
      sido,
      sigungu,
      autoJibunAddress,
      jibunAddress,
    } = data;
    const fullAddress = createFullAddress(address, addressType, bname, buildingName);
    setValue('address', fullAddress, { shouldDirty: true });
    setOpen(false);

    const selectJibunAddress = autoJibunAddress ? autoJibunAddress : jibunAddress;
    setValue('jibun', selectJibunAddress);

    const [processedSido, sigunguFirstPart] = processRegionData(sido, sigungu);
    const matchedRegion = matchRegion(initData?.regions, processedSido, sigunguFirstPart);

    try {
      if (matchedRegion) {
        console.log('일치!');
        setValue('region', `${processedSido} / ${sigunguFirstPart}`);
      } else {
        console.log('일치하는 지역을 찾을 수 없습니다.');
        setValue('region', `${processedSido} / ${sigunguFirstPart}`);
      }
    } catch (error) {
      console.log('지역 처리 중 오류가 발생했습니다:');
      setValue('region', `${processedSido} / ${sigunguFirstPart}`);
    }
  };

  const handleEditJibun = jibun => {
    setValue('address', jibun);
    setValue('jibun', jibun);

    const [processedSido, sigunguFirstPart] = processJibun(jibun);

    const matchedRegion = matchRegion(initData?.regions, processedSido, sigunguFirstPart);

    try {
      if (matchedRegion) {
        setValue('region', `${processedSido} / ${sigunguFirstPart}`);
        setOpen(false);
      } else {
        console.log('일치하는 지역을 찾을 수 없습니다.');
        setValue('region', `${processedSido} / ${sigunguFirstPart}`);
        alert('시/군구를 정확하게 입력해주세요.');
      }
    } catch (error) {
      console.log('지역 처리 중 오류가 발생했습니다:');
      setValue('region', `${processedSido} / ${sigunguFirstPart}`);
      alert('시/군구를 정확하게 입력해주세요.');
    }
  };

  const createFullAddress = (address, addressType, bname, buildingName) => {
    let extraAddress = '';
    if (addressType === 'R') {
      extraAddress += bname ? bname : '';
      extraAddress += buildingName ? `${extraAddress ? `, ${buildingName}` : buildingName}` : '';
    }
    return `${address}${extraAddress ? ` (${extraAddress})` : ''}`;
  };

  const processRegionData = (sido, sigungu) => {
    let processSido = sido;
    processSido = processSido.slice(0, 2);

    let processSigungu = sigungu;
    if (!processSigungu && processSido === '세종') {
      processSigungu = '세종';
    }
    return [processSido, processSigungu.split(' ')[0]];
  };

  const processJibun = jibun => {
    const jibunArray = jibun.split(' ');

    let si = jibunArray[0];
    let gu = jibunArray[1];

    if (
      si === '서울특별시' ||
      si === '부산광역시' ||
      si === '대구광역시' ||
      si === '인천광역시' ||
      si === '광주광역시' ||
      si === '대전광역시' ||
      si === '울산광역시' ||
      si === '세종특별자치시' ||
      si === '경기도' ||
      si === '강원도' ||
      si === '제주특별자치도'
    ) {
      si = si.slice(0, 2);
    } else if (
      si === '충청북도' ||
      si === '충청남도' ||
      si === '전라북도' ||
      si === '전라남도' ||
      si === '경상북도' ||
      si === '경상남도'
    ) {
      si = si[0] + si[2];
    }

    if (si === '세종') {
      gu = '세종';
    }

    return [si, gu];
  };

  const matchRegion = (regions, processedSido, sigunguFirstPart) => {
    return regions?.find(region => region.includes(`${processedSido} ${sigunguFirstPart}`));
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ boxShadow: 'none', border: 'none', borderRadius: '20' }}
    >
      <Box sx={style}>
        {openJibunEditInput ? (
          <div>
            <Typography>지번 주소를 직접 입력해주세요.</Typography>
            <Typography sx={{ color: 'gray', fontSize: '0.8rem' }}>
              예) 서울특별시 강남구 역삼동 123-4
            </Typography>
            <Typography sx={{ color: 'gray', fontSize: '0.8rem' }}>
              예) 제주특별자치도 제주시 구좌읍 세화리 123-4
            </Typography>
            <Typography sx={{ color: 'gray', fontSize: '0.8rem' }}>
              예) 충청북도 청주시 상당구 용암동 123-4
            </Typography>
            <input
              type="text"
              placeholder="지번 주소를 정확하게 입력해주세요."
              style={{
                width: '100%',
                height: '3rem',
                border: '1px solid lightgray',
                borderRadius: '5px',
                padding: '0.5rem',
                marginTop: '1rem',
                marginBottom: '1rem',
                fontSize: '0.9rem',
              }}
              onChange={e => setJibun(e.target.value)}
            />
          </div>
        ) : (
          <DaumPostcode
            onComplete={handlePostCode}
            onSearch={() => {
              setIsSearch(true);
            }}
          />
        )}

        <Button
          sx={{
            width: '100%',
            height: '3rem',
            backgroundColor: MAIN_ORANGE,
            '&:hover': {
              backgroundColor: FILTER_ORANGE,
            },
          }}
          onClick={openJibunEditInput ? () => handleEditJibun(jibun) : () => setOpen(false)}
        >
          <Typography color="white">{openJibunEditInput ? '위 주소로 적용' : '닫기'}</Typography>
        </Button>
        {isSearch && !openJibunEditInput && (
          <>
            <div style={{ marginTop: '8px' }} />
            <span
              style={{
                color: MAIN_ORANGE,
                borderBottom: '1px solid ' + MAIN_ORANGE,
                fontSize: '14px',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.preventDefault();
                setOpenJibunEditInput(true);
              }}
            >
              원하는 결과를 못찾으셨나요?
            </span>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PlaceInfoModal;
