import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

function DateSelector({ startDate, setStartDate, endDate, setEndDate }) {
  const handleStartDateChange = newValue => {
    if (newValue && newValue.isValid()) {
      setStartDate(newValue.format('YYYY-MM-DD'));
    } else if (newValue === null) {
      return;
    }
  };

  const handleEndDateChange = newValue => {
    if (newValue && newValue.isValid()) {
      setEndDate(newValue.format('YYYY-MM-DD'));
    } else if (newValue === null) {
      return;
    }
  };

  const handleDeleteDate = () => {
    setEndDate('');
    setStartDate('');
  };
  const isStartDateValid = dayjs(startDate, 'YY-MM-DD').isValid();

  return (
    <DateSelectorContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ marginRight: '8px' }}>
          <DatePicker
            value={startDate ? dayjs(startDate, 'YYYY-MM-DD').toDate() : null}
            onChange={handleStartDateChange}
            format="YY-MM-DD"
            sx={{
              display: 'flex',
              maxWidth: '170px',
              '.MuiInputBase-input': {
                height: '10px',
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
              },
            }}
            renderInput={params => (
              <TextField
                {...params}
                value={
                  params?.inputProps?.value ? dayjs(params.inputProps.value).format('YY-MM-DD') : ''
                }
              />
            )}
          />
        </div>
        <div>
          <DatePicker
            value={endDate ? dayjs(endDate, 'YYYY-MM-DD').toDate() : null}
            onChange={handleEndDateChange}
            format="YY-MM-DD"
            sx={{
              display: 'flex',
              maxWidth: '170px',
              '.MuiInputBase-input': {
                height: '10px',
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
              },
              marginRight: '10px',
            }}
            shouldDisableDate={date => {
              const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);
              return isStartDateValid
                ? dayjsDate.isBefore(dayjs(startDate, 'YYYY-MM-DD'), 'day')
                : false;
            }}
            renderInput={params => (
              <TextField
                {...params}
                value={
                  params?.inputProps?.value ? dayjs(params.inputProps.value).format('YY-MM-DD') : ''
                }
              />
            )}
          />
        </div>
        <DeleteIcon
          onClick={() => handleDeleteDate()}
          sx={{ color: '#6B6B6B', cursor: 'pointer' }}
        />
      </LocalizationProvider>
    </DateSelectorContainer>
  );
}

export default DateSelector;

const DateSelectorContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;
