import React from 'react';
import { Typography, Grid } from '@mui/material';

const FormHeader = ({ isSmallScreen, titleText, subtitleText }) => {
  return (
    <Grid container>
      <Grid container direction="column" alignItems="center">
        <Typography
          mb={3}
          sx={{
            textAlign: 'center',
            fontSize: isSmallScreen ? '1.5rem' : '2rem',
            fontWeight: 600,
          }}
        >
          {titleText}
        </Typography>
        <Typography mb={6}>{subtitleText}</Typography>
      </Grid>
    </Grid>
  );
};
export default FormHeader;
