import React, { forwardRef } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Box,
  Button,
  useMediaQuery,
} from '@mui/material';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import SVGRenderer from '../../../utils/SVGrenderer';
import dayjs from 'dayjs';
import { ERROR_RED, MAIN_ORANGE } from '../../../constant/colors';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { useTheme } from '@emotion/react';
import useFetchInitData from '../../../hooks/useFetchInitData';

/**
 * 운영 시간 컴포넌트
 * @param control
 * @param dirtyFields
 * @param reset
 * @param values
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param setValue
 * @returns {JSX.Element}
 * @constructor
 */

function OperatingHours({ dirtyFields, control, pageFrom, register }, ref) {
  const { initData } = useFetchInitData();
  const dayTypes = initData?.businessHourList;
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const watchOperatingHours = useWatch({
    name: 'operatingHours',
    control,
  });

  const {
    fields: operationTimeFields,
    append: appendOperationTime,
    remove: removeOperationTime,
  } = useFieldArray({
    control,
    name: 'operatingHours',
  });

  const handleRemoveOperationTime = index => {
    if (watchOperatingHours.length === 1) {
      removeOperationTime(index);
      appendOperationTime({
        day: null,
        openingTime: null,
        closingTime: null,
      });
    } else {
      removeOperationTime(index);
    }
  };
  function findMatchingDayType(field) {
    const matchingDayType = dayTypes?.find(dayType => dayType.name === field.value);
    return matchingDayType ? matchingDayType.value : field.value;
  }

  return (
    <SectionContainer style={{ marginBottom: '36px', marginTop: '36px' }}>
      <Typography
        sx={{
          color:
            (pageFrom === 'editPlace' || pageFrom === 'comparison') &&
            dirtyFields?.operatingHours?.some(operating =>
              Object.values(operating).includes(true),
            ) &&
            ERROR_RED,
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        운영시간*
      </Typography>
      <ContentContainer>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3}>
            {operationTimeFields?.map((item, index) => (
              <FormControl key={item.id} fullWidth sx={{ paddingBottom: '16px' }}>
                <Controller
                  name={`operatingHours.${index}.day`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      value={(!field.value && 'none') || findMatchingDayType(field)}
                      sx={{
                        fontSize: { xs: '0.85rem', md: '1rem' },
                        height: '55px',
                        color: {
                          '.MuiInputBase-input': {
                            color: !field.value ? 'gray' : 'black',
                          },
                        },
                      }}
                    >
                      {dayTypes?.map(day => (
                        <MenuItem key={day.id} value={day.id}>
                          {day.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ))}
          </Grid>

          <Grid item xs={4}>
            {operationTimeFields.map((item, index) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} key={item.id}>
                <Controller
                  name={`operatingHours.${index}.openingTime`}
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{
                        paddingBottom: '16px',
                      }}
                    >
                      <TimePicker
                        {...field}
                        value={field.value ? dayjs(`2023-10-03 ${field.value}`) : null}
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        format="HH:mm"
                        label="시작 시간"
                        onChange={value => {
                          const formattedTime = dayjs(value).format('HH:mm');
                          field.onChange(formattedTime);
                        }}
                        sx={{
                          height: '55px',
                          '.MuiInputLabel-root': {
                            fontSize: { xs: '0.85rem', md: '1rem' },
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            ))}
          </Grid>

          <Grid item xs={4}>
            {operationTimeFields?.map((item, index) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} key={item.id}>
                <Controller
                  name={`operatingHours.${index}.closingTime`}
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ paddingBottom: '16px' }}>
                      <TimePicker
                        {...field}
                        value={field.value ? dayjs(`2023-10-03 ${field.value}`) : null}
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        format="HH:mm"
                        label="종료 시간"
                        onChange={value => {
                          const formattedTime = dayjs(value).format('HH:mm');
                          field.onChange(formattedTime);
                        }}
                        sx={{
                          height: '55px',
                          '.MuiInputLabel-root': {
                            fontSize: { xs: '0.85rem', md: '1rem' },
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            ))}
          </Grid>
          <Grid item xs={1}>
            {operationTimeFields?.map((item, index) => (
              <Controller
                key={item.id}
                name=""
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      height: '72px',
                      paddingBottom: '16px',
                      cursor: 'pointer',
                    }}
                    display="flex"
                    alignItems="center"
                    onClick={() => handleRemoveOperationTime(index)}
                  >
                    <SVGRenderer {...field} type="delete" height="100px" />
                  </Box>
                )}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container flexDirection="row-reverse">
          <Button
            component="label"
            sx={{
              background: 'white',
              color: MAIN_ORANGE,
              height: '30px',

              border: 1,
              borderRadius: '20px',
              borderColor: MAIN_ORANGE,
              borderWidth: 1,
              paddingY: 1,
              paddingX: 2,
            }}
            onClick={() =>
              appendOperationTime({
                day: null,
                openingTime: null,
                closingTime: null,
              })
            }
          >
            운영시간 추가 +
          </Button>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
}

export default forwardRef(OperatingHours);
