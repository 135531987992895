import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { Box } from '@mui/material';

const LoadingIndicator = ({ bgcolorOption }) => {
  const loadingStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: bgcolorOption === true ? 'rgba(0, 0, 0, .7)' : 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <>
      <Box sx={loadingStyle}>
        <CircularProgress />
      </Box>
    </>
  );
};

export default LoadingIndicator;
