import React, { useState } from 'react';
import { MAIN_ORANGE } from '../constant/colors';
import styled from 'styled-components';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function DateSortArrows({ sortKey, setSortKey, handleSearch }) {
  const handleSorted = () => {
    if (sortKey === 'created_new') {
      setSortKey('created_older');
    } else {
      setSortKey('created_new');
    }
    handleSearch();
  };

  return (
    <ArrowContainer>
      {sortKey === 'created_new' ? (
        <ArrowDropUpIcon onClick={() => handleSorted()} sx={{ color: MAIN_ORANGE }} />
      ) : (
        <ArrowDropDownIcon onClick={() => handleSorted()} sx={{ color: MAIN_ORANGE }} />
      )}
    </ArrowContainer>
  );
}

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
