import React, { useState } from 'react';
import useRedirect from '../../../hooks/useRedirect';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataHeader from '../../../components/DataList/components/DataHeader';
import DataItem from '../../../components/DataList/components/DataItem';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import { Container, Grid, useMediaQuery } from '@mui/material';
import Header from '../../../components/Header';
import { useTheme } from '@emotion/react';
import { adminApi } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import getMaxPage from '../../../hooks/getMaxPage';
import ListCountToggle from '../PlaceConfirm/components/ListCountToggle';
import DateSelector from '../../../components/InputForm/components/DateSelector';
import PlaceConfirmSearch from '../PlaceConfirm/components/PlaceConfirmSearch';
import FileDownload from '../SettlementConfirm/components/FileDownloadButton';
import {
  ListAndDateControls,
  PlaceConfirmSearchContainer,
  FlexContainer,
} from '../PlaceConfirm/PlaceConfirm';
import CustomButton from '../../../components/CustomButton';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import API_URL from '../../../api/urls';
import LoadingIndicator from '../../../components/LoadingIndicator';

const AdminSettlementResult = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const setModalProp = useSetRecoilState(modalProps);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [maxPage, setMaxPage] = useState(1);
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(15);
  const searchMenuItems = [
    { label: '아이디', value: 'provider' },
    { label: '장소명', value: 'place' },
  ];
  const [checkedProposal, setCheckedProposal] = useState([]);
  const [category, setCategory] = useState('provider');
  const [isAllChecked, setIsAllChecked] = useState(false);

  useRedirect({ admin: true });

  const queryClient = useQueryClient();

  const navigateAndDeselectAll = (_, value) => {
    setCurrentPage(value);
    setCheckedProposal([]);
    setIsAllChecked(false);
  };

  const fetchSettlementResult = async (query, currentPage, limit, startDate, endDate) => {
    try {
      const response = await adminApi.get(
        '/api/v1/place-information-provider/admin/adjustment-requests',
        {
          params: {
            page: currentPage,
            category: category,
            keyword: query,
            limit: limit,
            startDate: startDate,
            endDate: endDate,
          },
        },
      );
      getMaxPage(response?.data?.adjustmentCount, setMaxPage, limit);

      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const { isLoading, data } = useQuery(
    ['fetchSettlementResult', query, currentPage, limit, startDate, endDate],
    () => fetchSettlementResult(query, currentPage, limit, startDate, endDate),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );
  // TODO: 아이디, 유저 실명을 둘 다 받아오고 있는데 어떤 데이터 보여줄 건지 선택 필요
  const settlementResult = data && data.adjustments;
  const headerList = [
    { label: '신청일자', grid: 1.9, key: 'createdAt' },
    { label: '처리일자', grid: 1.9, key: 'proceededAt' },
    { label: '아이디', grid: 2, key: 'username' },
    { label: '신청내역', grid: 3.2, key: 'receipt' },
    { label: '금액', grid: 1, key: 'totalAmount' },
    { label: '상태', grid: 1.2, key: 'statusDisplay' },
    { label: null, grid: 0.8, key: 'checkbox' },
  ];

  const checkBoxHandler = (e, itmeId) => {
    if (e.target.checked) {
      setCheckedProposal([...checkedProposal, itmeId]);
    } else {
      const newPlaceIds = checkedProposal.filter(id => id !== itmeId);
      setCheckedProposal(newPlaceIds);
    }
  };

  const handleCancelProposal = () => {
    if (checkedProposal.length === 0) {
      toast.error('항목이 선택되지 않았습니다.');
      return;
    }

    setModalProp({
      titleText: '정산 취소',
      text: '선택된 정산 항목을 취소 처리하시겠습니까?',
      text2: '해당 항목은 자동으로 정산 요청 목록으로 이동됩니다.',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '확인',
      onPress: () => setModalProp(null),
      onPress2: () => {
        deleteProposalRequest();
        setModalProp(null);
      },
    });
  };

  const navigateHandler = id => {
    navigate(`/admin/settlementconfirm/${id}`);
  };

  const deleteProposalRequest = async () => {
    try {
      const response = await adminApi.post(
        `api/v1/place-information-provider/admin/adjustment-requests/revoke`,
        { adjustmentIds: checkedProposal },
      );
      toast.success('정산 취소 처리 되었습니다.');
      queryClient.invalidateQueries(['fetchSettlementResult']);
      setCheckedProposal([]);
      setIsAllChecked(false);
    } catch (error) {
      const response = error.response.data;
      if (response.code === 'BadRequest') {
        setModalProp({
          titleText: '요청에 처리되지 않은 정산서가 있습니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      } else if (response.code === 'NotPermitted') {
        setModalProp({
          titleText: '삭제할 수 있는 권한이 없습니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      } else if (response.code === 'DoesNotExist') {
        setModalProp({
          titleText: '존재하지 않는 제안서 입니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      } else {
        setModalProp({
          titleText: '서버 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  return (
    <>
      <Container
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="정산 처리 결과"
          subTextFirstLine="유저의 정산 요청 및 승인된 정산 내역을"
          subTextSecondLine="확인할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />
        <DataContainerWrapper>
          <DataContainer>
            <ListAndDateControls>
              <ListCountToggle isMdScreen={isMdScreen} setLimit={setLimit} />
              <DateSelector
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
              />
            </ListAndDateControls>

            <FlexContainer style={{ justifyContent: 'space-between' }}>
              <PlaceConfirmSearchContainer>
                <PlaceConfirmSearch
                  setQuery={setQuery}
                  isMdScreen={isMdScreen}
                  searchMenuItems={searchMenuItems}
                  handleSearch={() =>
                    fetchSettlementResult(query, currentPage, limit, startDate, endDate)
                  }
                  setCategory={setCategory}
                  setCurrentPage={setCurrentPage}
                />
              </PlaceConfirmSearchContainer>
              <div style={{ marginLeft: 'auto', marginBottom: '8px' }}>
                <CustomButton
                  isPrimaryButtonOrange
                  buttonHandler={() => handleCancelProposal()}
                  text="취소"
                />
              </div>
            </FlexContainer>

            <DataHeader
              setIsAllChecked={setIsAllChecked}
              isAllChecked={isAllChecked}
              showCheckbox
              headerList={headerList}
              fontSize={isMdScreen && '0.65rem'}
              checkedIds={checkedProposal}
              setCheckedIds={setCheckedProposal}
              data={settlementResult}
            />
            {isLoading && <LoadingIndicator />}
            <Grid container>
              {settlementResult?.length ? (
                settlementResult?.map((result, index) => (
                  <DataItem
                    showCheckbox
                    checked={checkedProposal.some(id => id === result.id)}
                    fontSize={isMdScreen && '0.65rem'}
                    headerList={headerList}
                    resultList={settlementResult}
                    status={result.status}
                    result={result}
                    key={index}
                    index={index}
                    pageFrom="settlementResult"
                    checkBoxHandler={checkBoxHandler}
                    navigateHandler={() => navigateHandler(result.providerId)}
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>데이터가 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            navigateAndDeselectAll={navigateAndDeselectAll}
          />
          <div style={{ position: 'fixed', bottom: 20, right: '5vw', width: '15vw' }}>
            <FileDownload
              width="100%"
              ids="adjustment_ids"
              adjustments={checkedProposal}
              setModalProp={setModalProp}
              url={`${API_URL}/api/v1/place-information-provider/admin/adjustment-result-sheet`}
              fileName="정산 처리결과 목록.xlsx"
            />
          </div>
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default AdminSettlementResult;
