import { atom } from 'recoil';
import { adminApi, userApi } from '../api/api';

const setTokenToApi = (token, key) => {
  if (key === 'recentUserAtom') {
    userApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    adminApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const userEffect =
  key =>
  ({ setSelf, onSet, trigger }) => {
    const loadPersisted = () => {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(savedValue);
        setTokenToApi(savedValue, key);
      }
    };

    if (trigger === 'get') {
      loadPersisted();
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        const token = newValue;
        localStorage.setItem(key, token);
        setTokenToApi(token, key);
      }
    });
  };

export const userToken = atom({
  key: 'userToken',
  default: '',
  effects_UNSTABLE: [userEffect('recentUserAtom')],
});

export const adminToken = atom({
  key: 'adminToken',
  default: '',
  effects_UNSTABLE: [userEffect('recentAdminAtom')],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {
    id: null,
    username: null,
    nickname: null,
    name: null,
    proposalStatus: {
      pending: null,
      approved: null,
      hold: null,
      rejected: null,
    },
    bankAccount: {
      bank: null,
      accountNumber: null,
    },
  },
});

export const adminInfo = atom({
  key: 'adminInfo',
  default: {
    nickname: null,
  },
});

export const placeTemp = atom({
  key: 'placeTemp',
  default: null,
});

export const modalProps = atom({
  key: 'modalProps',
  default: null,
});
