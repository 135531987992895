import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import SVGRenderer from '../../utils/SVGrenderer';
import { Box, Button } from '@mui/material';
import { DESCRIPTION_VARIATION } from '../../components/InputForm/components/Description';

const convertImages = imageName => {
  switch (imageName) {
    case '텐트가능':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993292740.png';
    case '음식반입금지':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993271206.png';
    case '음식반입가능':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993294749.png';
    case '주차가능':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993296321.png';
    case '위생관리중':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993297755.png';
    case '유모차대여':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993301490.png';
    case '애견동반':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_169198729818.png';
    case '수유실':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_169200906024.png';
    case '식사 음료 스낵':
      return 'https://babygo.s3.amazonaws.com/media/amenities/resized_168993280319.png';
    default:
      return '';
  }
};

const PlacePreviewPage = ({ values, openPreviewModal, setOpenPreviewModal }) => {
  const [placeData, setPlaceData] = useState({
    title: '',
    images: [],
    address: '',
    basicInfo: '',
    operatingTime: [],
    priceChart: [],
    convenience: [],
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const innerPadding = () => {
    if (windowSize.width >= 500) {
      return '24x';
    }
    if (windowSize.width >= 400) {
      return '20px';
    }
    return '16px';
  };

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const {
      name: title,
      images,
      address,
      description,
      operatingHours: operatingTime,
      products: priceChart,
      amenities: convenience,
    } = values;

    const filteredOperatingTime = operatingTime.filter(item => item.day !== null);
    const filteredPriceChart = priceChart.filter(item => item.name !== null);
    let basicInfo = '';
    const keys = ['introduction', 'info', 'guide', 'room', 'checkInOut', 'tip', 'parking'];
    keys.forEach(key => {
      if (description.hasOwnProperty(key) && description[key].trim().length > 0) {
        const descriptionData = DESCRIPTION_VARIATION[key];
        basicInfo += `${descriptionData.label}\n
${description[key]}\n\n`;
      }
    });
    setPlaceData({
      title,
      images,
      address,
      basicInfo,
      operatingTime: filteredOperatingTime,
      priceChart: filteredPriceChart,
      convenience,
    });
  }, []);

  return (
    <>
      <div
        style={{
          top: 0,
          left: 0,
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          visibility: openPreviewModal ? 'visible' : 'hidden',
          zIndex: 999999,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setOpenPreviewModal(false)}
      >
        <Box sx={style} onClick={e => e.stopPropagation()}>
          <div
            style={{
              zIndex: 10000,
              position: 'sticky',
              top: '4%',
              height: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'pink',
            }}
          >
            <Button
              sx={{
                background: 'red',
                '&:hover': {
                  backgroundColor: '#e30a0a',
                },
                fontSize: '1rem',
                top: 0,
                color: 'white',
              }}
              onClick={() => setOpenPreviewModal(false)}
            >
              닫기
            </Button>
          </div>
          <MainBody>
            <MainContainer>
              <PlaceImageSwiper images={placeData.images} />
              <ContentContainer>
                <TitleContainer style={{ padding: `0 ${innerPadding()}` }}>
                  <TitleText>{placeData.title}</TitleText>
                  <TelBtn>
                    <SVGRenderer type="전화걸기" marginRight="4px" />
                    전화
                  </TelBtn>
                </TitleContainer>
                <HeartAndStarContainer style={{ padding: `0 ${innerPadding()}` }}>
                  <SVGRenderer type="찜수하트" marginRight="7px" />
                  0
                  <SVGRenderer type="별점" marginLeft="20px" marginRight="7px" />
                  0.0
                </HeartAndStarContainer>
                <AddressText style={{ padding: `0 ${innerPadding()}` }}>
                  <SVGRenderer type="나침반" marginRight="7px" />
                  00km 이내
                </AddressText>
                <AddressText style={{ padding: `0 ${innerPadding()}` }}>
                  <SVGRenderer type="주소복사아이콘" marginRight="7px" />
                  {placeData.address}
                </AddressText>
                <CarText style={{ padding: `0 ${innerPadding()}` }}>
                  여기는 차로 0시 00분 정도 달려야해요
                </CarText>
                <Divider marginTop="12px" marginBottom="24px" />
                <BasicInfoTitleText>기본 정보</BasicInfoTitleText>
                <SectionTitleText style={{ padding: `0 ${innerPadding()}` }}>
                  <SVGRenderer type="전구아이콘" marginRight="7px" />
                  애가&nbsp;<span style={{ color: '#FAB174' }}>TIP</span>
                </SectionTitleText>

                <BasicInfoBox>{placeData.basicInfo}</BasicInfoBox>

                {placeData.operatingTime.length > 0 ? (
                  <>
                    <Divider marginTop="24px" marginBottom="24px" />
                    <SectionTitleText style={{ padding: `0 ${innerPadding()}` }}>
                      <SVGRenderer type="운영시간아이콘" marginRight="7px" />
                      운영시간
                    </SectionTitleText>
                    <SectionItemContainer style={{ padding: `0 ${innerPadding()}` }}>
                      {placeData.operatingTime.map((item, index) => {
                        if (item.day) {
                          return (
                            <OperatingHourComp
                              key={index + 'operatingTime'}
                              day={item.day}
                              time={item.openingTime + '~' + item.closingTime}
                            />
                          );
                        }
                      })}
                    </SectionItemContainer>
                  </>
                ) : null}

                {placeData.priceChart.length > 0 ? (
                  <>
                    <Divider marginTop="24px" marginBottom="24px" />
                    <SectionTitleText style={{ padding: `0 ${innerPadding()}` }}>
                      <SVGRenderer type="가격아이콘" marginRight="7px" />
                      가격
                    </SectionTitleText>
                    <SectionItemContainer style={{ padding: `0 ${innerPadding()}` }}>
                      {placeData.priceChart.map((item, index) => {
                        if (item.name) {
                          return (
                            <PriceChartComp
                              key={index + 'priceChart'}
                              name={item.name}
                              price={String(item.price).toLocaleString() + ' 원'}
                            />
                          );
                        }
                      })}
                    </SectionItemContainer>
                  </>
                ) : null}

                {placeData.convenience.length > 0 ? (
                  <>
                    <Divider marginTop="24px" marginBottom="24px" />
                    <SectionTitleText style={{ padding: `0 ${innerPadding()}` }}>
                      <SVGRenderer type="편의시설아이콘" marginRight="7px" />
                      편의시설
                    </SectionTitleText>
                    <ConvenienceContainer>
                      <div style={{ flexDirection: 'row', display: 'flex', width: 'auto' }}>
                        {placeData.convenience.map((item, index) => {
                          return (
                            <ConvenienceItem key={index + 'convenience'}>
                              <img
                                alt="convenience"
                                src={convertImages(item.name)}
                                style={{ width: '30px', height: '30px', marginBottom: '12px' }}
                              />
                              {item.name}
                            </ConvenienceItem>
                          );
                        })}
                      </div>
                    </ConvenienceContainer>
                  </>
                ) : null}
              </ContentContainer>
            </MainContainer>
          </MainBody>
        </Box>
      </div>
    </>
  );
};

export default PlacePreviewPage;

const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: lightgray;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 512px;
  background-color: white;
`;

const PlaceImageSwiper = ({ images }) => {
  return (
    <Swiper
      style={{
        width: '100%',
        '--swiper-pagination-color': '#FAB714',
        '--swiper-pagination-bottom': '24px',
      }}
      centeredSlides={true}
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      modules={[Navigation, Pagination, Autoplay]}
    >
      {images
        ? images.map((image, index) => {
            return (
              <SwiperSlide key={index + 'image'}>
                <div style={{ width: '100%', height: '100%' }}>
                  <img
                    alt="imag"
                    src={image.url}
                    style={{ width: '100%', objectFit: 'cover', aspectRatio: '1' }}
                  />
                </div>
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
  );
};

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '2%',
  borderRadius: '5px',
  overflowY: 'auto',
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  border-radius: 12px 12px 0 0;
  margin-top: -12px;
  background-color: white;
  z-index: 98;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-top: 30px;
  margin-bottom: 16px;
`;

const TitleText = styled.span`
  flex: 1;
  margin-right: 24px;
  max-lines: 2;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: 'GmarketSansMedium', sans-serif;
`;

const TelBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 24px;
  background-color: #fab714;
  color: white;
  line-height: 0px;
`;

const HeartAndStarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  color: #545750;
  padding: 0 24px;
  margin-bottom: 21px;
`;

const AddressText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  color: #545750;
  padding: 0 24px;
  margin-bottom: 8px;
`;

const CarText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 10px;
  color: #545750;
  padding: 0 24px;
  margin-top: -4px;
  margin-bottom: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 12px;
  background-color: #f9f9f9;
  margin-top: ${props => props.marginTop || '0px'};
  margin-bottom: ${props => props.marginBottom || '0px'};
`;

const BasicInfoTitleText = styled.div`
  font-size: 18px;
  font-weight: 700;
  font-family: 'GmarketSansBold', sans-serif;
  margin-bottom: 20px;
  color: #fbaf17;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SectionTitleText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: 'GmarketSansMedium', sans-serif;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

const BasicInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  align-self: center;
  min-height: 100px;
  border-radius: 8px;
  background-color: #f6f6f6;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  white-space: pre-line;
  word-break: break-all;
`;

const SectionItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 24px;
`;

const OperatingHourComp = ({ day, time }) => {
  return (
    <OperatingItem>
      <span style={{ minWidth: '60px' }}>{day}</span>
      &nbsp;{time.replace(/null/g, '')}
    </OperatingItem>
  );
};

const OperatingItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
`;

const PriceChartComp = ({ name, price }) => {
  return (
    <PriceItem>
      <span>{name}</span>
      <span>{price}</span>
    </PriceItem>
  );
};

const PriceItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
`;

const ConvenienceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  padding: 0 24px;
  overflow: scroll;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ConvenienceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 18px;
  font-size: 10px;
  color: #545750;
  flex-shrink: 0;
`;
