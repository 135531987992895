import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { TextField, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export default function Amount({ isMdScreen, register, isOpenPlace, amount }) {
  return (
    <>
      <DescText style={{ paddingBottom: '20px' }}>
        {`*현재 제출된 정산금 : ${amount ? amount : 0}원`}
      </DescText>
      <SectionContainer>
        <div
          style={{
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
          }}
        >
          <Typography
            sx={{
              flexShrink: 0,
              width: '80px',
              marginBottom: '10px',
              marginRight: '6%',
              fontSize: isMdScreen && '0.8rem',
            }}
          >
            정산금
          </Typography>
        </div>
        <ContentContainer style={{ marginTop: '-16px' }}>
          {!isOpenPlace && <DescText>*폐업 요청 제안서입니다.</DescText>}
          <TextField
            {...register('amount')}
            onChange={e => {
              e.target.value = e.target.value.replace(/[^0-9-]/g, '');
              register('amount').onChange(e);
            }}
            placeholder="해당 제안서의 정산 금액을 입력해 주세요."
            type="text"
            style={{ flex: 1, minWidth: '280px' }}
            fullWidth
            sx={{
              '.MuiInputBase-input': {
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
              },
            }}
          />
        </ContentContainer>
      </SectionContainer>
    </>
  );
}

export const DescText = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  margin-bottom: 8px;
`;
