import React from 'react';
import styled from 'styled-components';

const CapsLockIndicator = () => <Bubble>Caps Lock이 켜져 있습니다.</Bubble>;

const Bubble = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  border-radius: 5px;
  margin: 10px 0;
`;

export default CapsLockIndicator;
