import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function SettlementAmountDisplay({ approvedProposals }) {
  return (
    <Grid container flexDirection="column" alignItems="flex-end" mt={2} mb={2}>
      <Typography
        sx={{
          color: '#5E5E5E',
          fontWeight: 700,
          fontSize: '1.25rem',
        }}
      >
        +
        {approvedProposals?.reduce((acc, item) => acc + Number(item.payAmount), 0).toLocaleString()}
      </Typography>
      <Typography
        sx={{
          color: '#5E5E5E',
        }}
      >
        정산 가능 금액{' '}
      </Typography>
      <Typography
        sx={{
          color: '#cccccc',
        }}
      >
        (1만원 이상부터 정산 가능합니다.)
      </Typography>
    </Grid>
  );
}
