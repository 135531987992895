import React, { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import useRedirect from '../../hooks/useRedirect';
import { Container, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import SearchMain from '../SearchPlace/SearchMain';
import { GuideButton } from '../../components/StyledButton';
import { CanEditablePlaceListModal } from './CanEditablePlaceListModal';

const SearchPlace = () => {
  const navigate = useNavigate();
  useRedirect();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isVisibleEditList, setIsVisibleEditList] = useState(false);
  const onClickEditList = useCallback(() => {
    setIsVisibleEditList(true);
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ marginBottom: '8vw', paddingBottom: '6vw' }}>
      <GuideButton>장소 등록 가이드</GuideButton>
      <EditListButton onClick={onClickEditList} />
      <Header
        isSmallScreen={isSmallScreen}
        isGreeting={false}
        titleText="장소등록 및 수정"
        subTextFirstLine="신규 장소 등록 혹은 기존 장소에 대한 정보를"
        subTextSecondLine="수정할 수 있습니다."
        navigateToDashBoard={() => navigate('/dashboard')}
      />
      <SearchMain />
      <CanEditablePlaceListModal
        navigate={navigate}
        isVisibleEditList={isVisibleEditList}
        setIsVisibleEditList={setIsVisibleEditList}
      />
    </Container>
  );
};

class EditListButton extends React.Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { onClick } = this.props;
    return (
      <div
        onClick={onClick}
        style={{
          color: '#FBAF17',
          backgroundColor: 'white',
          display: 'flex',
          padding: '0.6rem',
          width: 'fit-content',
          borderRadius: '5px',
          fontSize: '0.7rem',
          marginTop: '5px',
          cursor: 'pointer',
        }}
      >
        수정 목록 리스트
      </div>
    );
  }
}

export default SearchPlace;
