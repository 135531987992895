import dayjs from 'dayjs';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { StyledCheckbox } from '../../Checkbox';
import EditButton from '../../EditButton';
import { styled } from 'styled-components';
import { MAIN_ORANGE } from '../../../constant/colors';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useTheme } from '@emotion/react';
import StyledButton from '../../StyledButton';
import Container from '../../Container';

export default function DataItem({
  navigateHandler,
  status,
  resultList,
  index,
  headerList,
  result,
  category,
  handleOpenPlaceDetail,
  pageFrom,
  checked,
  checkBoxHandler,
  showCheckbox,
  fontSize,
  promptUserRejection,
  promptUserApproval,
}) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const formatResult = (key, value) => {
    if (
      (key === 'createdAt' ||
        key === 'proceededAt' ||
        key === 'requestedAt' ||
        key === 'modifiedAt' ||
        key === 'appliedAt') &&
      value !== null
    ) {
      return dayjs(value).format('YY-MM-DD');
    }
    if (key === 'amount' || (key === 'payAmount' && value !== null)) {
      return value?.toLocaleString();
    }

    return value;
  };
  const statusColor = status => {
    if (status === 'requested') {
      return '#6B6B6B';
    }
    if (status === 'approved') {
      return '#0CAA09';
    }
    if (status === 'pending') {
      return '#6B6B6B';
    }
    if (status === 'hold') {
      return '#FFA800';
    }
    if (status === 'rejected') {
      return '#E84444';
    }
    if (status === 'new') {
      return '#FF4D35';
    }
    if (status === 'revise') {
      return '#1E78FF';
    }
  };

  const getTextColor = (key, status) => {
    if (key === 'statusDisplay') {
      return statusColor(status);
    } else if (key === 'categoryDisplay' && pageFrom === 'settlementRequest') {
      return statusColor(status);
    } else if (key === 'categoryDisplay') {
      return statusColor(category);
    } else if (key === 'providerId' || key === 'processor') {
      return '#5E5E5E';
    } else if (key === 'payAmount') {
      return '#5E5E5E';
    }
    return null;
  };

  const navigate = useNavigate();

  return (
    <Grid
      key={index}
      container
      sx={{
        borderBottom: '1.2px solid #BBB',
        alignItems: 'center',
        '&:hover': {
          cursor:
            pageFrom === 'placeConfirm' || pageFrom === 'settlementConfirm' ? 'pointer' : 'default',
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      {headerList?.map((list, index) => (
        <Grid
          item
          xs={list.grid}
          paddingY={2}
          paddingX={list.key === 'name' && 1}
          sx={{ display: 'flex', flexDirection: list.key === 'createdAt' ? 'column' : 'row' }}
          justifyContent="center"
          alignItems="center"
          key={index}
          onClick={() => {
            if (
              (pageFrom === 'placeConfirm' || pageFrom === 'settlementConfirm') &&
              list.key === 'name' &&
              handleOpenPlaceDetail
            ) {
              handleOpenPlaceDetail();
            } else if (
              (pageFrom === 'placeConfirm' ||
                pageFrom === 'settlementConfirm' ||
                pageFrom === 'settlementResult') &&
              list.key === 'username' &&
              navigateHandler
            ) {
              navigateHandler();
            }
            if (pageFrom === 'settlementRequest') {
              navigate(`/propsedplace/${result.id}`, { state: { result: '승인' } });
            }
          }}
        >
          {(() => {
            switch (list.key) {
              case 'checkbox':
                if (showCheckbox) {
                  return (
                    <StyledCheckbox
                      type="checkbox"
                      checked={checked}
                      onChange={e => checkBoxHandler(e, result.id)}
                    />
                  );
                }
                break;
              case 'createdAt':
                const modifiedDate = result['modifiedAt']
                  ? formatResult('modifiedAt', result['modifiedAt'])
                  : null;
                return (
                  <>
                    <Typography sx={{ fontSize: fontSize && fontSize }}>
                      {formatResult(list.key, result[list.key])}
                    </Typography>
                    {modifiedDate && result['modifiedAt'] !== result['createdAt'] && (
                      <Typography
                        sx={{ fontSize: fontSize ? '0.5rem' : '0.65rem', color: '#6B6B6B' }}
                      >
                        (재제출 : {modifiedDate})
                      </Typography>
                    )}
                  </>
                );

              case 'status':
                return (
                  <Typography
                    sx={{ fontSize: fontSize && fontSize, color: statusColor(result['status']) }}
                  >
                    {pageFrom === 'settlementConfirm' && result['status'] === 'approved'
                      ? '승인됨'
                      : result['status'] === 'requested'
                      ? '요청됨'
                      : '거절됨'}
                  </Typography>
                );
                break;

              case 'modifiedAt':
                break;

              case 'signUpRequestButton':
                return (
                  <>
                    <Container isMdScreen={isMdScreen} style={{ gap: '4px' }}>
                      <StyledButton isRejectButton onClick={() => promptUserRejection(list.id)}>
                        거절
                      </StyledButton>
                      <StyledButton onClick={() => promptUserApproval(list.id)}>승인</StyledButton>
                    </Container>
                  </>
                );

              case 'editButton':
                return <EditButton navigateTo={`/admin/editUser/${result.id}`} />;

              case 'receipt':
                return (
                  <>
                    <ToolTipContainer>
                      <Typography
                        sx={{
                          fontSize: fontSize && fontSize,
                          '&:hover': {
                            color: MAIN_ORANGE,
                          },
                          width: '100%',
                        }}
                      >
                        {result.receipt.length > 1
                          ? `${result.receipt[0]?.name} 외 ${result.receipt.length - 1}개`
                          : result.receipt[0]?.name}
                      </Typography>

                      <ScrollContainer>
                        {result.receipt?.map(place => (
                          <PlaceName key={place}>
                            <ReceiptContainer>
                              <Typography
                                sx={{
                                  fontSize: fontSize && fontSize,
                                }}
                                paddingRight={2}
                              >
                                {place.category === 'revise' ? '장소수정' : '장소등록'}
                              </Typography>
                              <Typography
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '20vw',
                                  fontSize: fontSize && fontSize,
                                }}
                              >
                                {place.name}
                              </Typography>
                              <Typography sx={{ fontSize: fontSize && fontSize }}>
                                {place.amount}원
                              </Typography>
                            </ReceiptContainer>
                          </PlaceName>
                        ))}
                      </ScrollContainer>
                    </ToolTipContainer>
                  </>
                );

              default:
                return (
                  <Typography
                    sx={{
                      color: getTextColor(list.key, status),
                      wordBreak: 'break-all',
                      fontSize: (fontSize && fontSize) || (list.key === 'modifiedAt' && '0.7rem'),
                      borderBottom: '1px solid transparent',
                      '&:hover': {
                        color:
                          list.key === 'username' &&
                          (pageFrom === 'settlementConfirm' || pageFrom === 'settlementResult') &&
                          MAIN_ORANGE,
                        borderBottomColor:
                          list.key === 'username' &&
                          (pageFrom === 'settlementConfirm' || pageFrom === 'settlementResult')
                            ? MAIN_ORANGE
                            : '',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    {list.key === 'payAmount' && ' + '}
                    {formatResult(list.key, result[list.key])}
                  </Typography>
                );
            }
          })()}
        </Grid>
      ))}
    </Grid>
  );
}

export const ToolTipContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: 600px;
`;

export const ScrollContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  overflow: auto;
  max-height: 250px;
  padding: 2vh;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;

  ${ToolTipContainer}:hover & {
    display: block;
    position: absolute;
    border: 1.5px solid ${MAIN_ORANGE};
    border-radius: 5px;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const PlaceName = styled.div`
  padding: 1px;
  height: auto;
`;

const ReceiptContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
