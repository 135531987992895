import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../../api/urls';
import { adminApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import Header from '../../../components/Header';
import { Container, Grid, useMediaQuery } from '@mui/material';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import DataHeader from '../../../components/DataList/components/DataHeader';
import DataItem from '../../../components/DataList/components/DataItem';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import getMaxPage from '../../../hooks/getMaxPage';
import LoadingIndicator from '../../../components/LoadingIndicator';

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useRedirect({ admin: true });

  const fetchUsers = async ({ queryKey }) => {
    const [_, currentPage] = queryKey;
    try {
      const response = await adminApi.get(
        `${API_URL}/api/v1/place-information-provider/admin/providers?page=${currentPage}`,
      );
      getMaxPage(response.data?.totalCount, setMaxPage, 15);

      return response.data;
    } catch (error) {}
  };

  const { isLoading, data } = useQuery(['fetchUsers', currentPage], fetchUsers);
  const settlementResult = data && data.providers;

  const headerList = [
    { label: '아이디', grid: 2.5, key: 'username' },
    { label: '실명', grid: 2, key: 'name' },
    { label: '닉네임', grid: 2.5, key: 'nickname' },
    { label: '은행', grid: 2, key: 'bank' },
    { label: '계좌번호', grid: 2.6, key: 'accountNumber' },
    { label: null, grid: 0.4, key: 'editButton' },
  ];

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="회원 관리"
          subTextFirstLine="회원 상세 정보를 확인하고"
          subTextSecondLine="설정을 관리할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />
        <DataContainerWrapper>
          <DataContainer>
            <DataHeader headerList={headerList} fontSize={isMdScreen && '0.8rem'} />
            <Grid container>
              {isLoading && <LoadingIndicator />}
              {settlementResult?.length ? (
                settlementResult?.map((result, index) => (
                  <DataItem
                    headerList={headerList}
                    resultList={settlementResult}
                    result={result}
                    status={result.status}
                    key={index}
                    index={index}
                    fontSize={isMdScreen && '0.8rem'}
                    // navigateTo="/admin/editUser:id"
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>유저 목록이 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default Users;
