import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function SectionData({ data, isMdScreen, name }) {
  return (
    <SectionContainer>
      <div
        style={{
          width: '80px',
          marginTop: '20px',
          marginRight: '6%',
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            width: '80px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          {name}
        </Typography>
      </div>
      <ContentContainer>
        <Grid container spacing={1} paddingX="1vw" sx={{ marginTop: '20px' }}>
          <Typography
            disabled
            fullWidth
            type="text"
            color="#9E9E9E"
            sx={{ fontSize: isMdScreen && '0.8rem' }}
          >
            {data}
          </Typography>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
}
