import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import Header from '../../../components/Header';
import ProposedPlaceMain from '../ProposedPlace/ProposedMain';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const ProposedPlace = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="제안한 장소"
          subTextFirstLine="내가 제안한 장소와"
          subTextSecondLine="승인 보류 반려 여부를 확인할 수 있습니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <ProposedPlaceMain />
      </Container>
    </>
  );
};

export default ProposedPlace;
