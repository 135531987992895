import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTheme } from '@emotion/react';
import { Grid, Paper, Container, useMediaQuery, Typography, Button } from '@mui/material';
import API_URL from '../../../../../api/urls';
import { userApi } from '../../../../../api/api';
import { modalProps } from '../../../../../recoilAtom/recoilAtom';
import { validateAndScroll, scrollToElement } from '../../../../../api/validateAndScroll';
import useRedirect from '../../../../../hooks/useRedirect';
import { useCustomCategory } from '../../../../../hooks/useCustomCategory';
import useFetchInitData from '../../../../../hooks/useFetchInitData';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import ReadOnlyForm from '../../../../../components/ReadOnlyForm';
import Header from '../../../../../components/Header';
import AdminComment from '../../../../../components/AdminComment';
import Name from '../../../../../components/InputForm/components/Name';
import Address from '../../../../../components/InputForm/components/Address';
import Amenities from '../../../../../components/InputForm/components/Amenities';
import IsInside from '../../../../../components/InputForm/components/IsInside';
import Images from '../../../../../components/InputForm/components/Images';
import OperatingHours from '../../../../../components/InputForm/components/OperatingHours';
import Products from '../../../../../components/InputForm/components/Products';
import PhoneNumber from '../../../../../components/InputForm/components/PhoneNumber';
import HomePage from '../../../../../components/InputForm/components/HomePage';
import Description from '../../../../../components/InputForm/components/Description';
import SubmitButton from '../../../../../components/InputForm/components/SubmitButton';
import { MAIN_ORANGE } from '../../../../../constant/colors';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import isMobileDevice from '../../../../../hooks/isMobileDevice';
import Preview from '../../../../../components/InputForm/components/Preview';
import PlaceStatusPreviewPage from './PlaceStatusPreviewPage';
import { FormController } from '../../../../../utils/formController';

const PlaceStatusPage = () => {
  const isMobile = isMobileDevice();
  const { handleNavigate } = useRedirect();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const nameRef = useRef();
  const amenitiesRef = useRef();
  const isInsideRef = useRef();
  const imagesRef = useRef();
  const endDateRef = useRef();
  const operatingRef = useRef();
  const productsRef = useRef();
  const { initData } = useFetchInitData();
  const [editMode, setEditMode] = useState(false);
  const setModalProp = useSetRecoilState(modalProps);
  const { id } = useParams();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.editable) {
      setEditMode(true);
    }
  }, []);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handleNavigateList = () => {
    navigate('/propsedplace');
    setModalProp(null);
  };
  const fetchProposalsDetail = async id => {
    try {
      const response = await userApi.get(
        `${API_URL}/api/v1/place-information-provider/me/proposals/${id}`,
      );

      if (response.status === 200) {
        setData(response.data.proposal);
      }
    } catch (error) {
      setModalProp({
        text: '장소 정보 불러오기에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
        buttonText: '확인',
        onPress: () => handleNavigateList(),
      });
    }
  };

  const [data, setData] = useState({});

  useEffect(() => {
    fetchProposalsDetail(id);
  }, []);

  useEffect(() => {
    if (data) {
      reset({
        name: data?.name,
        address: data?.address,
        jibun: data?.jibun,
        detailAddress: data?.detailAddress,
        phoneNumber: data?.phoneNumber,
        homepage: data?.homepage,
        isInside: data?.isInside,
        startDate: data?.startedAt?.split(' ')[0],
        startTime: data?.startedAt?.split(' ')[1] || null,
        endDate: data?.endedAt?.split(' ')[0],
        endTime: data?.endedAt?.split(' ')[1] || null,
        operatingHours: data?.operatingHours?.length
          ? data?.operatingHours
          : [
              {
                day: null,
                openingTime: null,
                closingTime: null,
              },
            ],

        products: data?.products?.length
          ? data?.products
          : [
              {
                name: null,
                price: null,
              },
            ],

        description: data?.description,
        placeCategory: data?.placeCategory,
        amenities: data?.amenities,
        keywords: data?.keywords,
        region: data?.region,
        images: data?.images,
      });
    }
  }, [data]);

  const {
    register,
    control,
    watch,
    setValue,
    reset,
    setError,
    trigger,
    formState,
    errors,
    getValues,
    handleSubmit,
  } = useForm();
  const allValues = getValues();
  const values = watch();

  console.log('FuCK: ', allValues, values);

  const handleNavigateSearch = () => {
    navigate('/searchplace');
    setModalProp(null);
  };

  const showExitEditMode = () => {
    setModalProp({
      titleText: '수정 페이지에서 나가시겠습니까?',
      text: '현재 수정 중인 내용이 삭제 됩니다.',
      isSelect: true,
      buttonText: '나가기',
      buttonText2: '취소',
      isPrimaryButtonOrange: true,
      onPress: () => {
        window.history.go(-1);
        setModalProp(null);
      },
      onPress2: () => {
        setModalProp(null);
      },
    });
  };

  const isAllNull = obj => Object.values(obj).every(key => key === null);

  const { adminCategory } = useCustomCategory();

  const onSubmit = async formData => {
    const isValidateForm = FormController.validateForm(formData, setModalProp);

    if (isValidateForm) {
      const isEndDateInvalid =
        !formData.endDate ||
        formData.endDate === 'undefined' ||
        formData.endDate === 'Invalid Date';
      const isEndTimeInvalid =
        !formData.endTime || formData.endTime === null || formData.endTime === 'Invalid Date';

      const isStartDateInvalid =
        !formData.startDate ||
        formData.startDate === 'undefined' ||
        formData.startDate === 'Invalid Date';
      const isStartTimeInvalid =
        !formData.startTime || formData.startTime === null || formData.startTime === 'Invalid Date';

      let formattedStartDateTime = null;
      let formattedEndDateTime = null;
      if (isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = null;
      } else if (!isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} 23:59`;
      } else if (!isEndDateInvalid && !isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} ${formData.endTime}`;
      }
      if (isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = null;
      } else if (!isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} 23:59`;
      } else if (!isStartDateInvalid && !isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} ${formData.startTime}`;
      }

      const updateData = {
        placeId: data.id,
        name: formData.name,
        address: formData.address,
        jibun: formData.jibun,
        detailAddress: formData.detailAddress,
        phoneNumber: formData.phoneNumber,
        homepage: formData.homepage,
        isInside: formData.isInside,
        products: formData.products,
        description: formData.description,
        amenities: formData.amenities,
        images: formData.images,
        startedAt: formattedStartDateTime,
        endedAt: formattedEndDateTime,
        operatingHours: formData.operatingHours,
        region: formData.region,
      };

      if (updateData.products.length === 1 && isAllNull(updateData.products[0])) {
        updateData.products = null;
      }
      await handlePlaceSubmit(updateData);
    }
  };

  const handlePlaceSubmit = async updateData => {
    setModalProp(null);
    try {
      if (data?.result === '보류') {
        const response = await userApi.post(
          `${API_URL}/api/v1/place-information-provider/me/proposals/${id}/resubmit`,
          updateData,
        );
      } else if (data?.result === '승인대기') {
        const response = await userApi.patch(
          `api/v1/place-information-provider/me/proposals/${id}`,
          updateData,
        );
      }

      setModalProp({
        titleText: '제출이 완료되었습니다',
        text: '내부 검토 후, 장소 등록이 진행될 예정입니다.',
        text2: '완료까지 약 며칠의 시간이 소요 될 수 있습니다.',
        isSelect: true,
        buttonText: '목록보기',
        buttonText2: '작성하기',
        onPress: () => handleNavigateList(),
        onPress2: () => handleNavigateSearch(),
      });
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          text: '장소 등록에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => {
            setModalProp(null);
          },
        });
      }
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const notifyDelete = () => {
    setModalProp({
      titleText: '제안서 삭제',
      text: '승인 대기 중인 제안서가 삭제 됩니다.',
      text2: '정말 삭제하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '삭제',
      onPress: () => {
        setModalProp(null);
      },
      onPress2: () => {
        sendDeleteRequest();
      },
    });
  };

  const sendDeleteRequest = async () => {
    try {
      const response = await userApi.delete(`api/v1/place-information-provider/me/proposals/${id}`);
      setModalProp(null);
      if (response?.data?.code === 'ok') {
        setModalProp({
          titleText: '제안서 삭제가 완료되었습니다',
          text: '제안서 목록 페이지로 이동합니다.',
          buttonText: '목록보기',
          onPress: () => handleNavigateList(),
        });
      }
    } catch (error) {
      const code = error?.response?.data?.code;
      if (code === 'NotAuthorized') {
        setModalProp({
          titleText: '로그인 정보가 만료 되었습니다.',
          text: '다시 로그인 해 주세요.',
          buttonText: '확인',
          onPress: () => handleNavigate(),
        });
      } else if (code === 'DoesNotExist') {
        setModalProp({
          titleText: '존재하지 않는 제안서 입니다.',
          text: '제안서 목록으로 이동합니다.',
          buttonText: '확인',
          onPress: () => handleNavigateList(),
        });
      } else if (code === 'DeletePermissionError') {
        setModalProp({
          titleText: '잘못된 접근입니다.',
          text: '제안서 목록으로 이동합니다.',
          buttonText: '확인',
          onPress: () => handleNavigateList(),
        });
      } else {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const goBackProposedPlace = e => {
    e.stopPropagation();
    if (data?.result === '보류' || editMode) {
      setModalProp({
        titleText: '제안서 작성 취소',
        text: '현재 작성 중인 제안서가 삭제됩니다.',
        text2: '작성을 취소하시겠습니까?',
        isSelect: true,
        buttonText: '작성 취소',
        buttonText2: '닫기',
        onPress: () => {
          navigate(-1);
          setModalProp(null);
        },
        onPress2: () => setModalProp(null),
      });
    } else {
      navigate('/propsedplace');
    }
  };
  return (
    <>
      {isMobile && (
        <Grid
          container
          className="mobileDiv"
          sx={{
            minHeight: '40px',
            position: 'fixed',
            top: 0,
            backgroundColor: MAIN_ORANGE + 90,
            borderTop: '1.6px solid #FBAF17',
            zIndex: 99999,
            opacity: 0,
          }}
          alignContent="center"
          paddingY={2}
        >
          <NavigateBeforeIcon
            sx={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }}
            onClick={goBackProposedPlace}
          />
        </Grid>
      )}

      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Grid>
          <RenderHeader isSmallScreen={isSmallScreen} navigate={navigate} data={data} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper
              sx={{
                paddingTop: '6vw',
                paddingBottom: '8vw',
                paddingLeft: '6vw',
                paddingRight: '6vw',
              }}
              elevation={3}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: '1rem',
                }}
              >
                {data?.result === '승인대기' && (
                  <div>
                    <Button
                      sx={{
                        border: '1px solid',

                        marginRight: 1,
                      }}
                      onClick={() => (editMode ? showExitEditMode() : handleEditMode())}
                    >
                      {editMode ? '수정 취소' : '수정'}
                    </Button>
                    <Button
                      sx={{
                        border: '1px solid',
                      }}
                      onClick={() => notifyDelete()}
                    >
                      삭제
                    </Button>
                  </div>
                )}
              </div>

              {(data?.result === '반려' || data?.result === '승인' || data?.result === '보류') &&
                data?.comment && <AdminComment comment={data?.comment} />}

              {editMode || data?.result === '보류' ? (
                <>
                  <Grid container flexDirection="row-reverse" mb={1}>
                    <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>
                      *는 필수 작성 항목입니다.
                    </Typography>
                  </Grid>

                  <Name //
                    register={register}
                    errors={errors}
                    ref={nameRef}
                    control={control}
                    pageFrom="editPlace"
                  />

                  <Address
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    register={register}
                    pageFrom="editPlace"
                    initData={initData}
                  />

                  <Amenities
                    initData={initData}
                    control={control}
                    errors={errors}
                    ref={amenitiesRef}
                    setValue={setValue}
                    amenities={data?.amenities}
                  />

                  <IsInside //
                    control={control}
                    errors={errors}
                    ref={isInsideRef}
                    pageFrom="editPlace"
                  />

                  <Images
                    setModalProp={setModalProp}
                    control={control}
                    ref={imagesRef}
                    errors={errors}
                    pageFrom="editPlace"
                  />

                  <OperatingHours
                    control={control}
                    reset={reset}
                    values={allValues}
                    pageFrom="editPlace"
                    setValue={setValue}
                    ref={operatingRef}
                  />

                  <Products
                    control={control}
                    reset={reset}
                    register={register}
                    values={allValues}
                    setValue={setValue}
                    ref={productsRef}
                  />

                  <PhoneNumber //
                    register={register}
                    data={data}
                    control={control}
                  />

                  <HomePage //
                    register={register}
                  />

                  <Description
                    register={register}
                    setValue={setValue}
                    control={control}
                    data={data}
                    endDateRef={endDateRef}
                  />
                  <Preview setOpenPreviewModal={setOpenPreviewModal} />
                  <SubmitButton
                    pageFrom="editPage"
                    control={control}
                    data={data}
                    submitText="수정하기"
                  />
                  {openPreviewModal && (
                    <PlaceStatusPreviewPage
                      values={allValues}
                      setOpenPreviewModal={setOpenPreviewModal}
                      openPreviewModal={openPreviewModal}
                    />
                  )}
                </>
              ) : (
                <ReadOnlyForm
                  values={values}
                  formData={data}
                  register={register}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  setError={setError}
                  trigger={trigger}
                  formState={formState}
                  adminCategory={adminCategory}
                  errors={errors}
                  setModalProp={setModalProp}
                />
              )}
            </Paper>
          </form>
        </Grid>
      </Container>
    </>
  );
};

export default PlaceStatusPage;

function RenderHeader({ isSmallScreen, navigate, data }) {
  const headerProps = {
    isSmallScreen,
    navigateToDashBoard: () => navigate('/dashboard'),
  };

  let headerTexts = {};

  if (data?.result === '승인대기') {
    headerTexts = {
      titleText: '승인대기 페이지',
      subTextFirstLine: '해당 장소는 관리자의 장소 승인을 기다리고 있습니다.',
      subTextSecondLine: '관리자의 승인 후 정산이 가능합니다.',
    };
  } else if (data?.result === '승인') {
    headerTexts = {
      titleText: '승인 페이지',
      subTextFirstLine: '장소 등록이 최종 승인되어 앱에 등록된 페이지입니다.',
    };
  } else if (data?.result === '반려') {
    headerTexts = {
      titleText: '반려 페이지',
      subTextFirstLine: '규정 상의 이유로 반려된 장소입니다.',
      subTextSecondLine: '반려된 장소는 수정 및 재제출이 불가합니다.',
    };
  } else if (data?.result === '보류') {
    headerTexts = {
      titleText: '보류된 장소',
      subTextFirstLine: '규정 상의 이유로 보류된 장소입니다.',
      subTextSecondLine: '정보 수정 후, 다시 제출이 가능합니다.',
    };
  }

  return <Header {...headerProps} {...headerTexts} />;
}
