import { useState, useEffect } from 'react';
const useDebounce = (fetchFunction, delay) => {
  const [data, setData] = useState(null);
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, [timerId]);

  const debounceFetch = e => {
    clearTimeout(timerId);
    setTimerId(
      setTimeout(async () => {
        const resultData = await fetchFunction(e);
        setData(resultData);
      }, delay),
    );
  };

  return [data, debounceFetch];
};

export default useDebounce;
