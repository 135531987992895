const requiredFields = {
  '기본 템플릿': {
    keys: ['info', 'tip', 'parking'],
    text: ['장소안내', '이용 Tip', '주차안내'],
  },
  '숙박/캠핑·글램핑 템플릿': {
    keys: ['room', 'checkInOut', 'tip', 'parking'],
    text: ['객실안내', '체크인/아웃', '이용 Tip', '주차안내'],
  },
  '축제·공연 템플릿': {
    keys: ['introduction', 'guide', 'tip', 'parking'],
    text: ['소개', '이용안내', '이용 Tip', '주차안내'],
  },
  '전시·체험·관람 템플릿': {
    keys: ['introduction', 'guide', 'tip', 'parking'],
    text: ['소개', '이용안내', '이용 Tip', '주차안내'],
  },
};

export const FormController = {
  validateForm: (form, setModalProp) => {
    const template = form.description.template;
    const fields = requiredFields[template];

    const isOperatingHourInvalid = form.operatingHours.some(
      operatingHour =>
        !operatingHour.openingTime ||
        operatingHour.openingTime === 'Invalid Date' ||
        !operatingHour.closingTime ||
        operatingHour.closingTime === 'Invalid Date' ||
        !operatingHour.day,
    );

    const isProductsInvalid = form.products.some(
      product =>
        !product.name ||
        isNaN(product.price) ||
        product.price === null ||
        product.price === undefined ||
        product.price === '',
    );

    const isEndDateInvalid =
      !form.endDate || form.endDate === 'undefined' || form.endDate === 'Invalid Date';
    const isEndTimeInvalid =
      !form.endTime || form.endTime === null || form.endTime === 'Invalid Date';

    const isMissingDescriptionField = fields?.keys.some((key, index) => {
      if (!form.description[key]) {
        setModalProp({
          titleText: '필수 작성 항목이 누락 되었습니다.',
          text: `이용안내 중 ${fields.text[index]} 설명을 입력해주세요`,
          buttonText: '확인',
          isPrimaryButtonOrange: true,
          onPress: () => setModalProp(null),
        });
        return true;
      }
    });

    if (!isEndTimeInvalid && isEndDateInvalid) {
      setModalProp({
        titleText: '종료일이 누락되었습니다',
        text: '종료 날짜를 선택해 주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    }

    if (form.name.length < 1) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '등록하실 곳의 업체명을 입력해주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (form.address.length < 1) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '주소 및 상세 주소를 입력해 주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (form.amenities.length < 1) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '시설 정보를 체크해 주세요.',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (!form.isInside) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '실내외 여부를 선택해 주세요',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (form.images.length < 4) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '장소 사진을 4장 이상 첨부해주세요',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (isOperatingHourInvalid) {
      setModalProp({
        titleText: '운영 시간을 확인해 주세요',
        text: '운영 시간은 빈 칸 없이 기입해 주세요',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (isProductsInvalid) {
      setModalProp({
        titleText: '상품 요금을 확인해 주세요',
        text: '상품 요금은 빈 칸 없이 기입해 주세요',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (!form.description.template) {
      setModalProp({
        titleText: '필수 작성 항목이 누락 되었습니다.',
        text: '이용안내를 입력해 주세요',
        buttonText: '확인',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
      return false;
    } else if (isMissingDescriptionField) {
      return false;
    } else {
      return true;
    }
  },
};
