import { useCallback, useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Grid, Typography, useMediaQuery, Button, Modal } from '@mui/material';
import TextArea from '../../TextArea';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';
import { styled } from 'styled-components';
import { useTheme } from '@emotion/react';
import Date from './Date';
const TEMPLATES = {
  '기본 템플릿': {
    name: '기본 템플릿',
    text: '😊 장소 소개\n📌\n📌',
    image: '/default_tooltip.png',
    inputData: ['info', 'tip', 'parking'],
    needEndDate: false,
  },
  '숙박/캠핑·글램핑 템플릿': {
    name: '숙박/캠핑·글램핑 템플릿',
    text: '😊장소 소개\n📌\n📌\n\n📌입실 : / 퇴실 :\n📌객실\n✅',
    image: '/accommodation_tooltip.png',
    inputData: ['room', 'checkInOut', 'tip', 'parking'],
    needEndDate: true,
  },
  '축제·공연 템플릿': {
    name: '축제·공연 템플릿',
    text: '✔️예매사이트\n\n📢공연 소개\n\n😊기본 정보\n📌관람 시간 :\n📌기간 :\n📌시간\n\n📌장소 :\n🐤관람가 :',
    image: '/event_tooltip.png',
    inputData: ['introduction', 'guide', 'tip', 'parking'],
    needEndDate: true,
  },
  '전시·체험·관람 템플릿': {
    name: '전시·체험·관람 템플릿',
    text: '📢소개\n\n😊기본 정보\n📌기간 :\n📌시간\n\n📌장소 :',
    image: '/exhibition_tooltip.png',
    inputData: ['introduction', 'guide', 'tip', 'parking'],
    needEndDate: false,
  },
};

const TEMPLATE_KEYS = [
  '기본 템플릿',
  '숙박/캠핑·글램핑 템플릿',
  '축제·공연 템플릿',
  '전시·체험·관람 템플릿',
];
export const DESCRIPTION_VARIATION = {
  introduction: {
    label: '소개',
    tooltip: '/placeInfo_tooltip.png',
  },
  info: {
    label: '장소안내',
    tooltip: '/placeInfo_tooltip.png',
  },
  guide: {
    label: '이용안내',
    tooltip: '/useInfo_tooltip.png',
  },
  room: {
    label: '객실안내',
    tooltip: '/roomInfo_tooltip.png',
  },
  checkInOut: {
    label: '체크인/아웃',
    tooltip: '/checkInInfo_tooltip.png',
  },
  tip: {
    label: '이용 Tip',
    tooltip: '/useTip_tooltip.png',
  },
  parking: {
    label: '주차안내',
    tooltip: '/parkInfo_tooltip.png',
  },
};

/**
 * 장소정보 컴포넌트
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param register
 * @param dirtyFields
 * @param setValue
 * @param control
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */

export default function Description({ setValue, control, children, endDateRef, readOnly = false }) {
  const [tooltipImage, setTooltipImage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);
  const [changeWarningModalVisible, setChangeWarningModalVisible] = useState(false);

  const closeCategoryModal = () => {
    setChangeWarningModalVisible(false);
    setTempTemplate(null);
  };

  useEffect(() => {
    if (!tempTemplate) {
      return;
    }
    if (!!selectedTemplate) {
      if (tempTemplate?.name !== selectedTemplate?.name) {
        setChangeWarningModalVisible(true);
      }
    } else {
      setSelectedTemplate(tempTemplate);
      tempTemplate && setValue('description', { template: tempTemplate.name, ...watchDescription });
    }
  }, [tempTemplate]);

  const onTemplateChanged = () => {
    setChangeWarningModalVisible(false);
    setValue('description', { template: tempTemplate.name });
    setSelectedTemplate(tempTemplate);
  };

  const watchDescription = useWatch({
    name: 'description',
    control,
  });
  useEffect(() => {
    if ((watchDescription?.template?.length || 0) > 0) {
      setSelectedTemplate(TEMPLATES[watchDescription.template]);
    }
  }, [watchDescription]);

  const onValueChanged = (key, value) => {
    const newValue = {};
    newValue[key] = value;
    setValue('description', {
      ...watchDescription,
      ...newValue,
    });
  };

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      flexDirection="column"
      minHeight={240}
      alignItems="start"
      paddingTop="32px"
      paddingBottom="32px"
    >
      <Grid container flexDirection="column" flex>
        {/* 메인 헤더 */}
        <InfoTitle isMdScreen={isMdScreen} />
        {/* 템플릿 선택 swiper */}
        {!readOnly && (
          <TemplateSelectComp
            isMdScreen={isMdScreen}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setTempTemplate}
            setTooltipImage={setTooltipImage}
          />
        )}
        {/* 템플릿 예시이미지 표시 */}
        <Grid container>
          {tooltipImage && (
            <TemplateExample>
              <img
                src={tooltipImage}
                alt="place tooltip"
                style={{ width: '90vw', maxWidth: '500px' }}
              />
            </TemplateExample>
          )}
        </Grid>
        {/* 종료일 입력 필요 카테고리 일시 종료일 입력폼 표시 */}
        {selectedTemplate?.needEndDate ? (
          <>
            <Date control={control} pageFrom="addPlace" ref={endDateRef} />
          </>
        ) : (
          <div style={{ height: '3px' }} />
        )}

        {selectedTemplate?.inputData.map((item, index) => {
          if (DESCRIPTION_VARIATION.hasOwnProperty(item)) {
            const descriptionData = DESCRIPTION_VARIATION[item];

            return (
              <TextAreaInput
                key={descriptionData.label}
                title={descriptionData.label}
                dataKey={item}
                tooltip={descriptionData.tooltip}
                isMdScreen={isMdScreen}
                value={watchDescription[item]}
                onValueChanged={onValueChanged}
                readOnly={readOnly}
              />
            );
          } else {
            return null;
          }
        })}
      </Grid>
      <CategoryChangeModal
        visible={changeWarningModalVisible}
        closeCategoryModal={closeCategoryModal}
        setCategory={onTemplateChanged}
      />
    </Grid>
  );
}

const InfoTitle = ({ isMdScreen }) => (
  <Grid container display="flex" marginBottom={1} flexDirection="column">
    <Typography
      mr={2}
      sx={{
        fontSize: isMdScreen && '0.8rem',
      }}
    >
      이용안내 문구*
    </Typography>
    <Typography
      mr={2}
      sx={{
        color: '#bbb',
        fontSize: isMdScreen ? '0.75rem' : '0.85rem',
      }}
    >
      템플릿을 클릭하여 작성을 시작해보세요!
    </Typography>
  </Grid>
);

const TemplateSelectComp = ({
  isMdScreen,
  selectedTemplate,
  setSelectedTemplate,
  setTooltipImage,
}) => (
  <TemplateContainer>
    {TEMPLATE_KEYS.map((key, index) => {
      const item = TEMPLATES[key];
      return (
        <TemplateItemDiv
          key={index}
          onClick={() => {
            setSelectedTemplate(item);
          }}
          style={{
            border: `1px solid ${key === selectedTemplate?.name ? MAIN_ORANGE : 'lightgray'}`,
          }}
        >
          <Typography
            sx={{
              color: 'black',
              fontSize: isMdScreen ? '0.7rem' : '0.8rem',
              marginRight: '5px',
            }}
          >
            {item.name}{' '}
          </Typography>
          <Typography
            sx={{
              color: MAIN_ORANGE,
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlineOffset: '5px',
              fontSize: isMdScreen ? '0.7rem' : '0.8rem',

              '&:hover': {
                fontWeight: 'bold',
              },
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            onMouseOver={() => setTooltipImage(item.image)}
            onMouseOut={() => setTooltipImage(null)}
          >
            예시
          </Typography>
        </TemplateItemDiv>
      );
    })}
  </TemplateContainer>
);

const TemplateExample = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid ${MAIN_ORANGE};
  left: 5vw;
`;

const TemplateContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: 'yellow';
  margin-bottom: 12px;

  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateItemDiv = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid ${MAIN_ORANGE};
  border-radius: 20px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
`;

const ContainerTitle = ({ title, isMdScreen, onMouseOver, onMouseOut }) => (
  <Grid container display="flex" marginBottom={1} marginTop={2}>
    <Typography
      mr={2}
      sx={{
        fontSize: isMdScreen ? '0.7rem' : '0.8rem',
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        color: MAIN_ORANGE,
        cursor: 'pointer',
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
        fontSize: isMdScreen ? '0.7rem' : '0.8rem',

        '&:hover': {
          fontWeight: 'bold',
        },
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      예시
    </Typography>
  </Grid>
);

const TextAreaInput = ({
  title,
  dataKey,
  isMdScreen,
  tooltip,
  value,
  onValueChanged,
  readOnly = false,
}) => {
  const [placeInfoExVisible, setPlaceInfoExVisible] = useState(false);
  return (
    <>
      <ContainerTitle
        title={title}
        isMdScreen={isMdScreen}
        onMouseOver={() => setPlaceInfoExVisible(true)}
        onMouseOut={() => setPlaceInfoExVisible(false)}
      />
      {title === '이용 Tip' && (
        <Grid container marginBottom={1}>
          <Typography sx={{ fontSize: isMdScreen ? '0.7rem' : '0.9rem' }}>
            해당 장소 이용시 tip을 알려주세요!
          </Typography>
        </Grid>
      )}
      <Grid container>
        {placeInfoExVisible && (
          <TemplateExample>
            <img src={tooltip} alt="place tooltip" style={{ width: '90vw', maxWidth: '500px' }} />
          </TemplateExample>
        )}
        <AutoDotTextArea
          value={value}
          onChange={value => {
            onValueChanged(dataKey, value);
          }}
          isDot={true}
          style={{ minHeight: '150px', fontSize: '0.85rem' }}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
};

const AutoDotTextArea = ({ value, onChange, isDot, ...props }) => {
  const [text, setText] = useState(value);
  const [cursorPosition, setCursorPosition] = useState(0);

  const textAreaRef = useRef(null);
  useEffect(() => {
    setText(value || '');
  }, [value]);

  useEffect(() => {
    onChange(text);
  }, [text]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.selectionStart = cursorPosition + 1;
      textAreaRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);
  useEffect(() => {
    if (props.disabled) {
      textAreaRef.current.disabled = true;
      textAreaRef.current.style.color = '#9E9E9E';
    }
  }, []);

  const handleKeyDown = e => {
    if (isDot) {
      if (e.key === 'Enter') {
        e.preventDefault();
        setText(prev => {
          const prevText = prev.slice(0, e.target.selectionStart);
          const nextText = prev.slice(e.target.selectionStart);
          const next = `${prevText}\n• ${nextText}`;
          setCursorPosition(e.target.selectionStart + 3);
          return next;
        });
        // 엔터를 감지하면 textArea의 스크롤을 살짝 내린다.
        textAreaRef.current.scrollTop += 23;
        return;
      }
    }
  };

  const handleChange = e => {
    setText(e.target.value);
  };

  const handleClick = useCallback(() => {
    if (isDot && text === '') {
      setText('• ');
    }
  }, [text]);

  return (
    <TextArea
      ref={textAreaRef}
      value={text}
      onChange={handleChange}
      onClick={handleClick}
      onKeyPress={handleKeyDown}
      autoFocus={false}
      type="text"
      disabled
      {...props}
    />
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  borderRadius: '5px',
};

const CategoryChangeModal = ({ visible, closeCategoryModal, setCategory }) => {
  return (
    <Modal
      open={visible}
      onClose={() => closeCategoryModal()}
      sx={{ boxShadow: 'none', border: 'none', borderRadius: '20' }}
    >
      <Box sx={style}>
        <Grid container flexDirection="column">
          <Typography mb={1}>작성 중인 장소 정보 내용이 초기화 됩니다.</Typography>
          <Typography mb={2}>카테고리를 변경 하시겠습니까?</Typography>
        </Grid>

        <Grid container flexDirection="row" spacing={2}>
          <Grid item xs={6}>
            <Button
              sx={{
                width: '100%',
                height: '3rem',
                border: '1px solid',
                backgroundColor: MAIN_ORANGE,
                '&:hover': {
                  backgroundColor: FILTER_ORANGE,
                },
              }}
              onClick={() => {
                setCategory();
              }}
            >
              <Typography color="white">변경</Typography>
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              sx={{
                width: '100%',
                height: '3rem',
                border: '1px solid',
                backgroundColor: '#ffffff',
                '&:hover': {
                  backgroundColor: '#ffffff',
                },
              }}
              onClick={() => {
                closeCategoryModal();
              }}
            >
              <Typography>취소</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
