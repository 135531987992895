import React, { forwardRef } from 'react';
import { Grid, Typography, TextField, useMediaQuery } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { ERROR_RED } from '../../../constant/colors';
import { styled } from 'styled-components';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { useTheme } from '@emotion/react';
import preventEnterSubmit from '../../../hooks/preventEnterSubmit';
import { calculateFontSize } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

/**
 * 장소명 컴포넌트
 * @param errors
 * @param register
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */

function Name(
  {
    //
    errors,
    control,
    comparisonOriginalData = null,
    changedProperty,
    pageFrom,
    register,
  },
  ref,
) {
  const watchName = useWatch({
    name: 'name',
    control,
  });
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SectionContainer>
      <Typography
        sx={{
          color:
            (pageFrom === 'editPlace' || pageFrom === 'comparison') && changedProperty
              ? ERROR_RED
              : 'initial',
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        장소명*
      </Typography>
      <ContentContainer>
        <TextField
          {...register('name')}
          defaultValue=""
          placeholder="등록하실 곳의 업체명을 입력해주세요."
          type="text"
          fullWidth
          disabled={(pageFrom === 'readonly' || pageFrom === 'comparison') && true}
          style={{ flex: 1, minWidth: '280px' }}
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
          onKeyPress={preventEnterSubmit}
        />
        {comparisonOriginalData && watchName !== comparisonOriginalData?.name ? (
          <Grid item xs={10}>
            <Typography
              type="text"
              fullWidth
              disabled
              color="#707070"
              sx={{ padding: '16.5px 14px' }}
            >
              {comparisonOriginalData?.name}
            </Typography>
          </Grid>
        ) : (
          <Typography mt={0.5} sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
            {errors?.name && errors?.name?.message}
          </Typography>
        )}
      </ContentContainer>
    </SectionContainer>
  );
}

export default forwardRef(Name);
