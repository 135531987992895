import { Stack, Pagination } from '@mui/material';
import React from 'react';

export default function PaginationButton({
  maxPage,
  currentPage,
  setCurrentPage,
  navigateAndDeselectAll,
}) {
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  return (
    <Stack paddingY={5} display="flex" alignItems="center">
      <Pagination
        count={maxPage}
        color="primary"
        page={currentPage}
        onChange={navigateAndDeselectAll ? navigateAndDeselectAll : handleChangePage}
      />
    </Stack>
  );
}
