import React, { useState } from 'react';
import {
  Typography,
  Button,
  Modal,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { userApi } from '../../../api/api';
import API_URL, { EMAIL_ADDRESS } from '../../../api/urls';
import { useRecoilValue } from 'recoil';
import { userInfo } from '../../../recoilAtom/recoilAtom';
import { useMutation } from '@tanstack/react-query';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';

const bankList = [
  '국민은행',
  '기업은행',
  '농협은행',
  '신한은행',
  '하나은행',
  '우리은행',
  '한국씨티은행',
  'SC제일은행',
  '경남은행',
  '광주은행',
  '대구은행',
  '도이치은행',
  '부산은행',
  '비엔피파리바은행',
  '산림조합',
  '산업은행',
  '새마을금고',
  '수출입은행',
  '수협은행',
  '신협',
  '우체국',
  '저축은행',
  '전북은행',
  '제주은행',
  '중국건설은행',
  '중국공상은행',
  '중국은행',
  '지역농축협',
  '카카오뱅크',
  '케이뱅크',
  '토스뱅크',
];

const SettlementModal = ({
  open,
  handleModalClose,
  handleRequestModalOpen,
  approvedProposals,
  queryClient,
}) => {
  const userAccountData = useRecoilValue(userInfo);
  const [settlementError, setSettlementError] = useState(null);

  const { handleSubmit, formState, control } = useForm({
    defaultValues: {
      accountNumber: null,
      bank: userAccountData?.bankAccount?.bank,
      proposalIds: approvedProposals.map(proposal => proposal.id),
    },
  });

  const settlementMutation = useMutation({
    mutationFn: settlementData => {
      const response = userApi.post(
        `${API_URL}/api/v1/place-information-provider/me/adjustments`,
        settlementData,
      );
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchApprovedProposal']);
      handleModalClose();
      handleRequestModalOpen();
    },
    onError: error => {
      if (error?.response?.data?.code === 'AlreadyRequested') {
        setSettlementError(error?.response?.data?.message);
        return;
      }
      if (error?.response?.data?.code === 'ProposalDoesNotExist') {
        setSettlementError(error?.response?.data?.message);
        return;
      }
      if (error?.response?.data?.code === 'NeedBankAccount') {
        setSettlementError(error?.response?.data?.message);
        return;
      }
    },
  });

  const handleSubmitSettlement = data => {
    setSettlementError(null);
    settlementMutation.mutate(data);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    maxWidth: '35rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 5,
    borderRadius: '5px',
  };

  return (
    <form>
      <Modal
        open={open}
        onClose={handleModalClose}
        sx={{ boxShadow: 'none', border: 'none', borderRadius: '20' }}
      >
        <Box sx={style}>
          <Typography variant="h6" mb={3} sx={{ color: ' #282422', fontWeight: 600 }}>
            {approvedProposals
              ?.reduce((acc, item) => acc + Number(item.payAmount), 0)
              .toLocaleString()}
            원 정산 요청하시겠습니까?
          </Typography>
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{userAccountData?.bankAccount?.bank || ''}</span>
              <div style={{ height: 12 }} />
              <span>{userAccountData?.bankAccount?.accountNumber || ''}</span>
            </div>

            <div style={{ height: 12 }} />
            <Grid container>
              <Typography sx={{ color: MAIN_ORANGE, fontSize: '15px' }}>
                정산요청 이후 애기야가자 이메일({EMAIL_ADDRESS})로
                <br />
                신분증 사본과 통장 사본을 보내주세요!
              </Typography>
              <Typography sx={{ color: MAIN_ORANGE, fontSize: '12px' }}>
                (33,340원 이상일 경우 3.3% 사업 소득세 발생으로 해당 금액을 제외한 정산 금액이
                입급됩니다.)
              </Typography>
            </Grid>
            <div style={{ height: 12 }} />

            {settlementError && (
              <Grid container sx={{ height: '25px' }} mb={1}>
                <Typography sx={{ color: ' #E54D4D' }}>{settlementError}</Typography>
              </Grid>
            )}

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    height: '3rem',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid',
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                  onClick={handleModalClose}
                >
                  <Typography color="#FBAF17">취소</Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    height: '3rem',
                    border: '1px solid',

                    backgroundColor: MAIN_ORANGE,
                    '&:hover': {
                      backgroundColor: FILTER_ORANGE,
                    },
                  }}
                  type="submit"
                  onClick={handleSubmit(handleSubmitSettlement)}
                >
                  <Typography color="white">확인</Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
    </form>
  );
};

export default SettlementModal;
