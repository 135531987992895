import { useCallback } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { passwordRegex } from '../../../utils/regexRules';
import FormContainer from './FormContainer';
import { TextField } from '@mui/material';

const validatePasswordValue = e => {
  if (e.target.value.length === 0) {
    return '필수 입력란입니다.';
  }
  if (!passwordRegex.test(e.target.value)) {
    return '사용 불가능한 비밀번호입니다. 영문, 숫자를 조합하여 8~30자로 만들어주세요.';
  }
};

export default function Password({ register, setValue, pageFrom }) {
  const [passwordError, debouncePassword] = useDebounce(validatePasswordValue, 2000);

  const passwordOnChange = useCallback(event => {
    debouncePassword(event);
    setValue('password', event.target.value);
  }, []);

  return (
    <FormContainer label="비밀번호" error={passwordError}>
      <TextField
        {...register('password', {
          ...(pageFrom !== 'editUser' && { required: '필수 입력란입니다.' }),
          pattern: {
            value: /^[A-Za-z0-9~!@#\$%\^&\*\_\-+=`|\\\(\)\{\}\[\]:;"'<>,.?/]{8,30}$/,
            message: '사용 불가능한 비밀번호입니다. 영문, 숫자를 조합하여 8~30자로 만들어주세요.',
          },
        })}
        onChange={passwordOnChange}
        placeholder="영문, 숫자를 조합하여 8~30자로 만들어주세요."
        type="password"
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </FormContainer>
  );
}
