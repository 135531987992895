import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Paper, Container, useMediaQuery, Typography, Button } from '@mui/material';
import Header from '../../../components/Header';
import { userApi } from '../../../api/api';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import { useSetRecoilState } from 'recoil';
import { useTheme } from '@emotion/react';
import API_URL from '../../../api/urls';
import { useParams } from 'react-router-dom';
import useRedirect from '../../../hooks/useRedirect';
import { useNavigate } from 'react-router-dom';
import { scrollToElement } from '../../../api/validateAndScroll';
import Name from '../../../components/InputForm/components/Name';
import Address from '../../../components/InputForm/components/Address';
import Amenities from '../../../components/InputForm/components/Amenities';
import IsInside from '../../../components/InputForm/components/IsInside';
import Images from '../../../components/InputForm/components/Images';
import OperatingHours from '../../../components/InputForm/components/OperatingHours';
import Products from '../../../components/InputForm/components/Products';
import PhoneNumber from '../../../components/InputForm/components/PhoneNumber';
import HomePage from '../../../components/InputForm/components/HomePage';
import Description from '../../../components/InputForm/components/Description';
import SubmitButton from '../../../components/InputForm/components/SubmitButton';
import useFetchInitData from '../../../hooks/useFetchInitData';
import isMobileDevice from '../../../hooks/isMobileDevice';
import { GuideButton } from '../../../components/StyledButton';
import dayjs from 'dayjs';
import usePreventBack from '../../../hooks/usePreventBack';
import Preview from '../../../components/InputForm/components/Preview';
import PlacePreviewPage from '../../PlacePreview/PlacePreviewPage';
import { FormController } from '../../../utils/formController';

const RevisePage = () => {
  const { handleNavigate } = useRedirect();
  const theme = useTheme();
  const setModalProp = useSetRecoilState(modalProps);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { initData } = useFetchInitData();

  const { id } = useParams();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const { preventGoBack } = usePreventBack({
    openPreviewModal,
    setOpenPreviewModal,
  });

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);

  const nameRef = useRef();
  const addressRef = useRef();
  const amenitiesRef = useRef();
  const isInsideRef = useRef();
  const imagesRef = useRef();
  const endDateRef = useRef();
  const operatingRef = useRef();
  const productsRef = useRef();
  const [data, setData] = useState({});

  const isAllNull = obj => Object.values(obj).every(key => key === null);
  const isAllFalse = obj => (obj ? Object.values(obj).every(value => value === true) : false);

  const fetchProposalsDetail = async placeId => {
    try {
      const response = await userApi.get(
        `${API_URL}/api/v1/place-information-provider/places/revise`,
        { params: { placeId } },
      );

      if (response.status === 200 && !!response.data.place) {
        setData(response.data.place);
        isIn2WeeksCheck(response.data.place.modifiedAt);
        resetFormData(response.data.place);
      }
    } catch (error) {
      setModalProp({
        text: '장소 정보 불러오기에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
        buttonText: '확인',
        onPress: () => handleNavigateSearch(),
      });
    }
  };

  const { register, control, setValue, reset, setError, formState, handleSubmit, getValues } =
    useForm();

  const isIn2WeeksCheck = modifiedAt => {
    if (dayjs(modifiedAt).isBefore(dayjs().add(14, 'days'))) {
      setModalProp({
        titleText: '최근에 수정된 장소입니다!',
        text: '2주 이내에 수정된 장소입니다.\r\n최근에 수정된 장소의 정보수정 요청은 반려될 수 있습니다!',
        buttonText: '그래도 수정하겠습니다',
        isPrimaryButtonOrange: true,
        onPress: () => setModalProp(null),
      });
    }
  };

  const resetFormData = place => {
    reset({
      name: place?.name,
      address: place?.address,
      jibun: place?.jibun,
      region: place?.region,
      detailAddress: '',
      phoneNumber: place?.phoneNumber,
      homepage: place?.homepage,
      isInside: place?.isInside,
      startDate: place?.startedAt ? place?.startedAt.split(' ')[0] : '',
      startTime: place?.startedAt ? place?.startedAt.split(' ')[1] : '',
      endDate: place?.endedAt ? place?.endedAt.split(' ')[0] : '',
      endTime: place?.endedAt ? place?.endedAt.split(' ')[1] : '',
      operatingHours: place?.operatingHours?.length
        ? place?.operatingHours.map(({ name, startAt, endAt }) => ({
            day: name,
            openingTime: startAt,
            closingTime: endAt,
          }))
        : [
            {
              day: null,
              openingTime: null,
              closingTime: null,
            },
          ],

      products: place?.products?.length
        ? place?.products
        : [
            {
              name: null,
              price: null,
            },
          ],

      description: place?.description || {},
      amenities: place?.amenities ? place?.amenities : [],
      images: place?.images ? place?.images : [],
    });
  };

  useEffect(() => {
    fetchProposalsDetail(id);
  }, []);

  const { errors, dirtyFields } = formState;

  const handleNavigateList = () => {
    navigate('/propsedplace');
    setModalProp(null);
  };

  const handleNavigateSearch = () => {
    navigate('/searchplace');
    setModalProp(null);
  };

  const isMobile = isMobileDevice();

  const onSubmit = async formData => {
    const isValidateForm = FormController.validateForm(formData, setModalProp);
    if (isValidateForm) {
      const isEndDateInvalid =
        !formData.endDate ||
        formData.endDate === 'undefined' ||
        formData.endDate === 'Invalid Date';
      const isEndTimeInvalid =
        !formData.endTime || formData.endTime === null || formData.endTime === 'Invalid Date';

      const isStartDateInvalid =
        !formData.startDate ||
        formData.startDate === 'undefined' ||
        formData.startDate === 'Invalid Date';
      const isStartTimeInvalid =
        !formData.startTime || formData.startTime === null || formData.startTime === 'Invalid Date';

      let formattedStartDateTime = null;
      let formattedEndDateTime = null;
      if (isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = null;
      } else if (!isEndDateInvalid && isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} 23:59`;
      } else if (!isEndDateInvalid && !isEndTimeInvalid) {
        formattedEndDateTime = `${formData.endDate} ${formData.endTime}`;
      }
      if (isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = null;
      } else if (!isStartDateInvalid && isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} 23:59`;
      } else if (!isStartDateInvalid && !isStartTimeInvalid) {
        formattedStartDateTime = `${formData.startDate} ${formData.startTime}`;
      }

      const region =
        formData.region?.length === 0
          ? `${formData.address.split(' ')[0]} / ${formData.address.split(' ')[1]}`
          : formData.region;

      const filteredImages = formData.images.map(({ id, ...rest }) => rest);

      const updateData = {
        placeId: id,
        name: formData.name,
        address: formData.address,
        jibun: formData.jibun,
        detailAddress: formData.detailAddress,
        phoneNumber: formData.phoneNumber,
        homepage: formData.homepage,
        isInside: formData.isInside,
        products: formData.products,
        description: formData.description,
        amenities: formData.amenities,
        images: filteredImages,
        startedAt: formattedStartDateTime,
        endedAt: formattedEndDateTime,
        operatingHours: formData.operatingHours,
        region: region,
        isOpen: true,
        device: isMobile ? 'mobile' : 'pc',
      };
      if (updateData.products.length === 1 && isAllNull(updateData.products[0])) {
        updateData.products = null;
      }

      await handlePlaceSubmit(updateData);
    }
  };
  const handlePlaceSubmit = async updateData => {
    setModalProp(null);

    try {
      const response = await userApi.post(
        `${API_URL}/api/v1/place-information-provider/places/revise`,
        updateData,
      );

      setModalProp({
        titleText: '제출이 완료되었습니다',
        text: '내부 검토 후, 장소 등록이 진행될 예정입니다.',
        text2: '완료까지 약 며칠의 시간이 소요 될 수 있습니다.',
        isSelect: true,
        buttonText: '목록보기',
        buttonText2: '작성하기',
        proposalId: response.data.proposalId,
        onPress: () => handleNavigateList(),
        onPress2: () => handleNavigateSearch(),
      });
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          text: '장소 등록에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
          buttonText: '확인',
          onPress: () => {
            setModalProp(null);
          },
        });
      }
    }
  };

  const allValues = getValues();

  const notifyClosure = async () => {
    const sendClosureRequest = async () => {
      setModalProp(null);
      const updateData = { ...data, placeId: id, isOpen: false };
      try {
        const response = await userApi.post(
          `${API_URL}/api/v1/place-information-provider/places/revise`,
          updateData,
        );

        setModalProp({
          titleText: '제출이 완료되었습니다',
          text: '내부 검토 후, 정보 수정이 진행될 예정입니다.',
          text2: '완료까지 약 며칠의 시간이 소요 될 수 있습니다.',
          isSelect: true,
          buttonText: '목록보기',
          buttonText2: '작성하기',
          onPress: () => handleNavigateList(),
          onPress2: () => handleNavigateSearch(),
        });
      } catch (error) {
        const code = error?.response?.data?.code;
        if (code === 'NotAuthenticated') {
          setModalProp({
            titleText: '로그인 정보가 만료 되었습니다.',
            text: '다시 로그인 해 주세요.',
            buttonText: '확인',
            onPress: () => handleNavigate(),
          });
        } else {
          setModalProp({
            text: '정보 수정에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
            buttonText: '확인',
            onPress: () => {
              setModalProp(null);
            },
          });
        }
      }
    };

    setModalProp({
      titleText: '폐업된 장소인가요?',
      text: '해당 장소가 폐업 되었다면 제보해 주세요.',
      text2: '내부 검토 후 해당 정보가 앱에 등록 됩니다.',
      isSelect: true,
      buttonText: '폐업 됐어요',
      buttonText2: '취소',
      isPrimaryButtonOrange: true,
      onPress: () => sendClosureRequest(),
      onPress2: () => setModalProp(null),
    });
  };

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <GuideButton>장소 등록 가이드</GuideButton>
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="장소 정보 수정"
          subTextFirstLine="장소 수정을 신청해주시면, 애기야가자에서 검토 후,"
          subTextSecondLine="최종 승인 후, 앱에 등록됩니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper
            sx={{
              paddingTop: '6vw',
              paddingBottom: '8vw',
              paddingLeft: '6vw',
              paddingRight: '6vw',
            }}
            elevation={3}
          >
            <>
              <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  sx={{
                    border: '1px solid',
                    marginBottom: 2,
                  }}
                  onClick={() => notifyClosure()}
                >
                  폐업 제보
                </Button>
              </div>
              <Grid container flexDirection="row-reverse" mb={1}>
                <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>
                  *는 필수 작성 항목입니다.
                </Typography>
              </Grid>

              <Name //
                register={register}
                errors={errors}
                ref={nameRef}
                control={control}
                pageFrom="editPlace"
                changedProperty={dirtyFields?.name}
              />

              <Address
                control={control}
                errors={errors}
                ref={addressRef}
                changedProperty={dirtyFields.address || dirtyFields.detailAddress}
                setValue={setValue}
                register={register}
                pageFrom="editPlace"
                initData={initData}
              />

              <Amenities
                initData={initData}
                control={control}
                errors={errors}
                dirtyFields={dirtyFields}
                ref={amenitiesRef}
                setValue={setValue}
                pageFrom="editPlace"
              />

              <IsInside //
                changedProperty={dirtyFields.isInside}
                control={control}
                errors={errors}
                ref={isInsideRef}
                pageFrom="editPlace"
              />

              <Images
                changedProperty={dirtyFields?.images?.some(image =>
                  Object.values(image).includes(true),
                )}
                setModalProp={setModalProp}
                control={control}
                ref={imagesRef}
                errors={errors}
                pageFrom="editPlace"
              />

              <OperatingHours
                dirtyFields={dirtyFields}
                control={control}
                reset={reset}
                values={allValues}
                pageFrom="editPlace"
                setValue={setValue}
                register={register}
              />

              <Products
                changedProperty={dirtyFields?.products?.some(product =>
                  Object.values(product).includes(true),
                )}
                dirtyFields={dirtyFields}
                control={control}
                reset={reset}
                register={register}
                values={allValues}
                setValue={setValue}
                ref={productsRef}
              />

              <PhoneNumber //
                changedProperty={dirtyFields?.phoneNumber}
                register={register}
                data={data}
                control={control}
              />

              <HomePage //
                dirtyFields={dirtyFields}
                register={register}
                changedProperty={dirtyFields?.homepage}
              />

              <Description
                dirtyFields={dirtyFields}
                register={register}
                setValue={setValue}
                control={control}
                endDateRef={endDateRef}
              />
              {/* <RevisePriceComp control={control} isOpenPlace={true} /> */}
              <Preview setOpenPreviewModal={setOpenPreviewModal} />
              <SubmitButton pageFrom="editPage" control={control} data={data} />
            </>
          </Paper>
        </form>
      </Container>
      {openPreviewModal && (
        <PlacePreviewPage
          values={allValues}
          setOpenPreviewModal={setOpenPreviewModal}
          openPreviewModal={openPreviewModal}
        />
      )}
    </>
  );
};

export default RevisePage;
