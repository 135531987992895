import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../../api/urls';
import { adminApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import Header from '../../../components/Header';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import getMaxPage from '../../../hooks/getMaxPage';
import DataHeader from '../../../components/DataList/components/DataHeader';
import LoadingIndicator from '../../../components/LoadingIndicator';
import DataItem from '../../../components/DataList/components/DataItem';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';

const SignUpRequest = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { handleNavigate } = useRedirect({ admin: true });
  const setModalProp = useSetRecoilState(modalProps);
  const [maxPage, setMaxPage] = useState(1);
  const headerList = [
    { label: '가입일자', grid: 2, key: 'appliedAt' },
    { label: '아이디', grid: 1.5, key: 'username' },
    { label: '실명', grid: 2, key: 'name' },
    { label: '은행', grid: 1.5, key: 'bank' },
    { label: '계좌번호', grid: 2, key: 'accountNumber' },
    { label: '닉네임', grid: 1.5, key: 'nickname' },
    { label: null, grid: 1.5, key: 'signUpRequestButton' },
  ];
  const fetchProviders = async page => {
    try {
      const response = await adminApi.get(
        '/api/v1/place-information-provider/admin/provider-registers',
        {
          params: {
            page: currentPage,
          },
        },
      );
      getMaxPage(response?.data?.totalCount, setMaxPage, 15);
      return response?.data;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '로그인 정보가 만료 되었습니다.',
          text: '다시 로그인 해 주세요.',
          buttonText: '확인',
          onPress: () => handleNavigate(),
        });
      }
    }
  };

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['readProviders', currentPage], () =>
    fetchProviders(currentPage),
  );

  const providers = data?.providers;

  const approveUser = useMutation({
    mutationFn: ({ result, id }) =>
      adminApi.post(`${API_URL}/api/v1/place-information-provider/admin/providers/${id}/confirm`, {
        result,
      }),
    onSettled: () => queryClient.invalidateQueries(['readProviders']),
  });

  const processUserApproval = id => {
    toast.success('가입 승인 처리 되었습니다.');
    approveUser.mutate({ result: 'approved', id: id });
    setModalProp(null);
  };

  const processUserRejection = id => {
    toast.error('가입 거절 처리 되었습니다.');
    approveUser.mutate({ result: 'rejected', id: id });
    setModalProp(null);
  };

  const promptUserApproval = id => {
    setModalProp({
      titleText: '가입 승인',
      text: '해당 유저의 가입을 승인 하시겠습니까?',
      isSelect: true,
      buttonText: '승인',
      buttonText2: '닫기',
      onPress: () => processUserApproval(id),
      onPress2: () => setModalProp(null),
      isPrimaryButtonOrange: true,
    });
  };

  const promptUserRejection = id => {
    setModalProp({
      titleText: '가입 거절',
      text: '해당 유저의 가입을 거절 하시겠습니까?',
      isSelect: true,
      buttonText: '거절',
      buttonText2: '닫기',
      onPress: () => processUserRejection(id),
      onPress2: () => setModalProp(null),
      isPrimaryButtonOrange: true,
    });
  };

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="회원가입 관리"
          subTextFirstLine="유저의 회원 가입 요청을 확인하고,"
          subTextSecondLine="승인 및 거부 할 수 있습니다."
          navigateToDashBoard={() => navigate('/admin/dashboard')}
        />

        <DataContainerWrapper
          pageFrom="placeConfirm"
          isMdScreen={isMdScreen}
          isLargeScreen={isLargeScreen}
        >
          <DataContainer>
            <DataHeader
              headerList={headerList}
              data={providers}
              pageFrom="placeConfirm"
              fontSize={isMdScreen && '0.75rem'}
            />
            {isLoading && <LoadingIndicator />}
            <Grid container>
              {providers?.length ? (
                providers?.map((provider, index) => (
                  <DataItem
                    fontSize={isMdScreen && '0.75rem'}
                    pageFrom="placeConfirm"
                    headerList={headerList}
                    resultList={providers}
                    result={provider}
                    key={index}
                    index={index}
                    promptUserRejection={() => promptUserRejection(provider.id)}
                    promptUserApproval={() => promptUserApproval(provider.id)}
                  />
                ))
              ) : (
                <>
                  {!isLoading && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '30vh', color: '#5E5E5E' }}
                    >
                      <Grid item>신규 회원 가입 요청이 존재하지 않습니다.</Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DataContainer>
          <PaginationButton
            maxPage={maxPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default SignUpRequest;
