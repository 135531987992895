import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { styled } from 'styled-components';
import { Typography } from '@mui/material';

// 기본료 500원 - name, address, amenities, dexcription, isInside
// 장당 100원 - images
// 각 100원 - operatingHours, products
// 각 50원 - phoneNumber, homepage
// 200원 - 팁

const checkBasicPrice = (watch, previouseData) => {
  const basicPrice = 200;
  const basicPriceKeys = ['name', 'address', 'isInside'];
  const basicPriceKeysLength = basicPriceKeys.length;
  let basicPriceCount = 0;

  for (let i = 0; i < basicPriceKeysLength; i++) {
    if (!!watch[basicPriceKeys[i]]) {
      basicPriceCount++;
    }
  }

  if (watch?.amenities?.length > 0) {
    basicPriceCount += 1;
  }
  return basicPriceCount >= 4 ? basicPrice : 0;
};

const checkImagePrice = (watch, previouseData) => {
  const imagePrice = 100;
  const imagePriceKeys = ['images'];
  const imagePriceKeysLength =
    watch[imagePriceKeys]?.length > 5 ? 5 : watch[imagePriceKeys]?.length;
  let imagePriceCount = 0;

  for (let i = 0; i < imagePriceKeysLength; i++) {
    if (!!watch[imagePriceKeys][i]) {
      imagePriceCount++;
    }
  }

  return imagePriceCount * imagePrice;
};

const checkOperatingHoursPrice = watch => {
  const operatingHoursPriceKey = 'operatingHours';
  let hoursArr = watch[operatingHoursPriceKey]?.filter(
    item => !!item.day && !!item.openingTime && !!item.closingTime,
  );

  if (hoursArr?.length === 0) {
    return 0;
  }

  return 100;
};

const checkProductsPrice = watch => {
  const productsPriceKey = 'products';
  let productsArr = watch[productsPriceKey]?.filter(item => item.name !== '' && !isNaN(item.price));
  if (productsArr?.length === 0) {
    return 0;
  }
  return 100;
};

const checkPhoneNumberPrice = watch => {
  const phoneNumberPriceKey = 'phoneNumber';
  if (watch[phoneNumberPriceKey]?.length < 8) {
    return 0;
  }
  return 50;
};

const checkHomepagePrice = watch => {
  const homepagePriceKey = 'homepage';
  if (watch[homepagePriceKey]?.length < 10) {
    return 0;
  }
  return 50;
};

const checkInfoPrice = (watch, infoKey, price) => {
  if (watch['description']?.includes(`${infoKey}`)) {
    return price;
  }
  return 0;
};

/** */
const ExpectedPriceComp = ({ control, admin }) => {
  const previouseData = useRef();
  const watch = useWatch({
    control,
  });

  const [expectedPrice, setExpectedPrice] = useState({
    basicPrice: 0,
    imagePrice: 0,
    operatingHoursPrice: 0,
    productsPrice: 0,
    phoneNumberPrice: 0,
    homepagePrice: 0,
    //
    placeInfoPrice: 0,
    useInfoPrice: 0,
    parkInfoPrice: 0,
    useTipPrice: 0,
    roomInfoPrice: 0,
    checkInPrice: 0,
    introducePrice: 0,
  });

  useEffect(() => {
    if (watch) {
      if (admin) {
        if (expectedPrice.basicPrice === 0) {
          setExpectedPrice({
            basicPrice: checkBasicPrice(watch),
            imagePrice: checkImagePrice(watch),
            operatingHoursPrice: checkOperatingHoursPrice(watch),
            productsPrice: checkProductsPrice(watch),
            phoneNumberPrice: checkPhoneNumberPrice(watch),
            homepagePrice: checkHomepagePrice(watch),
            placeInfoPrice: checkInfoPrice(watch, '장소 안내', 250),
            useInfoPrice: checkInfoPrice(watch, '이용 안내', 150),
            parkInfoPrice: checkInfoPrice(watch, '주차 안내', 100),
            useTipPrice: checkInfoPrice(watch, 'TIP', 150),
            roomInfoPrice: checkInfoPrice(watch, '객실 안내', 150),
            checkInPrice: checkInfoPrice(watch, '체크인/아웃', 100),
            introducePrice: checkInfoPrice(watch, '소개', 100),
          });
        }
      } else {
        setExpectedPrice({
          basicPrice: checkBasicPrice(watch),
          imagePrice: checkImagePrice(watch),
          operatingHoursPrice: checkOperatingHoursPrice(watch),
          productsPrice: checkProductsPrice(watch),
          phoneNumberPrice: checkPhoneNumberPrice(watch),
          homepagePrice: checkHomepagePrice(watch),
          placeInfoPrice: checkInfoPrice(watch, '장소 안내', 250),
          useInfoPrice: checkInfoPrice(watch, '이용 안내', 150),
          parkInfoPrice: checkInfoPrice(watch, '주차 안내', 100),
          useTipPrice: checkInfoPrice(watch, 'TIP', 150),
          roomInfoPrice: checkInfoPrice(watch, '객실 안내', 150),
          checkInPrice: checkInfoPrice(watch, '체크인/아웃', 100),
          introducePrice: checkInfoPrice(watch, '소개', 100),
        });
      }
    }
  }, [watch]);

  return (
    <PriceContainer>
      <PriceWrapper>
        <DescText>
          <span style={{ color: 'gray', fontSize: '0.65rem' }}>(필수 입력에 따른)</span> 기본 정산금
        </DescText>
        <PriceText>{`${expectedPrice.basicPrice}원`}</PriceText>
      </PriceWrapper>
      {expectedPrice.imagePrice > 0 && (
        <PriceWrapper>
          <DescText>
            <span style={{ color: 'gray', fontSize: '0.65rem' }}>(장당 100원, 최대 500원) </span>
            이미지 추가금
          </DescText>
          <PriceText>{`+ ${expectedPrice.imagePrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {/* TODO: 중복 컴포넌트 합치기 */}
      {expectedPrice.operatingHoursPrice > 0 && (
        <PriceWrapper>
          <DescText>운영시간 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.operatingHoursPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.productsPrice > 0 && (
        <PriceWrapper>
          <DescText>상품요금 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.productsPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.phoneNumberPrice > 0 && (
        <PriceWrapper>
          <DescText>전화번호 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.phoneNumberPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.homepagePrice > 0 && (
        <PriceWrapper>
          <DescText>홈페이지 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.homepagePrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.placeInfoPrice > 0 && (
        <PriceWrapper>
          <DescText>장소 안내 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.placeInfoPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.introducePrice > 0 && (
        <PriceWrapper>
          <DescText>소개 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.introducePrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.useInfoPrice > 0 && (
        <PriceWrapper>
          <DescText>이용 안내 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.useInfoPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.useTipPrice > 0 && (
        <PriceWrapper>
          <DescText>장소이용 Tip 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.useTipPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.parkInfoPrice > 0 && (
        <PriceWrapper>
          <DescText>주차 안내 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.parkInfoPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.roomInfoPrice > 0 && (
        <PriceWrapper>
          <DescText>객실 안내 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.roomInfoPrice}원`}</PriceText>
        </PriceWrapper>
      )}
      {expectedPrice.checkInPrice > 0 && (
        <PriceWrapper>
          <DescText>체크인/아웃 추가금</DescText>
          <PriceText>{`+ ${expectedPrice.checkInPrice}원`}</PriceText>
        </PriceWrapper>
      )}

      <div style={{ width: '260px', border: '0.8px solid lightgray', marginBottom: '12px' }} />
      <PriceWrapper>
        <span>예상 총 정산금</span>
        <PriceText style={{ fontSize: '1rem' }}>{`${
          expectedPrice.basicPrice +
          expectedPrice.imagePrice +
          expectedPrice.operatingHoursPrice +
          expectedPrice.productsPrice +
          expectedPrice.phoneNumberPrice +
          expectedPrice.homepagePrice +
          expectedPrice.placeInfoPrice +
          expectedPrice.useInfoPrice +
          expectedPrice.parkInfoPrice +
          expectedPrice.useTipPrice +
          expectedPrice.roomInfoPrice +
          expectedPrice.checkInPrice +
          expectedPrice.introducePrice
        }원`}</PriceText>
      </PriceWrapper>
      <Typography
        sx={{
          color: '#bbb',
          fontSize: '0.8rem',
        }}
      >
        (예상 정산금은 작성된 항목에 따라 변동될 수 있습니다.)
      </Typography>
    </PriceContainer>
  );
};

export default ExpectedPriceComp;

const DescText = styled.span`
  font-size: 0.8rem;
`;

const PriceText = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 65px;
  font-size: 0.8rem;
`;

const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;
  margin-bottom: 20px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 10px;
`;
