import { Grid } from '@mui/material';
import SVGRenderer from '../../../utils/SVGrenderer';

export default function SingInLogo({ isMdScreen, isSmallScreen }) {
  return (
    <Grid container justifyContent="center" mt={4}>
      <SVGRenderer
        type="logoWithBabyGo"
        width={isSmallScreen ? '40vw' : isMdScreen ? '30vw' : '20vw'}
      />
    </Grid>
  );
}
