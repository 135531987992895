import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import SVGRenderer from '../utils/SVGrenderer';
import { MAIN_ORANGE, FILTER_ORANGE } from '../constant/colors';
import styled from 'styled-components';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Button,
  Box,
  Checkbox,
  Divider,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextArea from './TextArea';
import useFetchInitData from '../hooks/useFetchInitData';
import { useTheme } from '@emotion/react';
import { ContentContainer, SectionContainer } from '../pages/AddPlace/AddPlace';
import useEndDateVisibility from '../hooks/useEndDateVisibility';
import Keywords from './InputForm/components/Keywords';
import Amount from './InputForm/components/Amount';
import ExpectedPriceComp from './InputForm/components/ExpectedPriceComp';
import Description from './InputForm/components/Description';

const ReadOnlyForm = ({
  register,
  values,
  control,
  admin,
  adminCategory,
  setValue,
  errors,
  setModalProp,
  amount,
}) => {
  const { initData } = useFetchInitData();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isOpenPlace = values?.isOpen;
  const {
    fields: operationTimeFields,
    append: appendOperationTime,
    remove: removeOperationTime,
  } = useFieldArray({
    control,
    name: 'operatingHours',
  });
  const {
    fields: productPriceFields,
    append: appendProductPrice,
    remove: removeProductPrice,
  } = useFieldArray({
    control,
    name: 'products',
  });
  const {
    fields: imagesFields,
    append: appendImages,
    remove: removeImages,
  } = useFieldArray({
    control,
    name: 'images',
  });

  return (
    <>
      <Grid container flexDirection="column" mb={1}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: isMdScreen && '0.8rem',
              width: '100%',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            *는 필수 작성 항목입니다.
          </Typography>
        </div>
      </Grid>
      {/* 장소명 */}
      <Name register={register} isMdScreen={isMdScreen} />

      {/* 장소 주소 */}
      <Address register={register} isMdScreen={isMdScreen} initData={initData} />
      {admin && values?.region ? (
        <Region admin={admin} register={register} values={values} isMdScreen={isMdScreen} />
      ) : null}

      {/* 시설정보 */}
      <Amenities isMdScreen={isMdScreen} control={control} initData={initData} />
      {/* 실내외 여부 */}
      <IsInside control={control} isMdScreen={isMdScreen} />
      <Images isMdScreen={isMdScreen} imagesFields={imagesFields} />
      <Divider variant="middle" />

      <div style={{ marginTop: '36px' }} />
      {/* 운영시간 */}
      <OperatingHours
        operationTimeFields={operationTimeFields}
        control={control}
        isMdScreen={isMdScreen}
        initData={initData}
      />
      {/* 상품 요금 */}
      <Products
        isMdScreen={isMdScreen}
        productPriceFields={productPriceFields}
        control={control}
        register={register}
      />
      {/* 전화번호 */}
      <PhoneNumber register={register} isMdScreen={isMdScreen} />
      {/* 홈페이지 */}
      <HomePage register={register} isMdScreen={isMdScreen} />
      {values.endDate && (
        <>
          <Date control={control} isMdScreen={isMdScreen} />
          <div style={{ marginBottom: '12px' }} />
        </>
      )}
      {/* 장소정보 */}
      <Grid
        container
        flexDirection="column"
        minHeight={240}
        alignItems="start"
        paddingTop="32px"
        mb={4}
      >
        <Grid container flexDirection="column" flex>
          <Description setValue={setValue} control={control} readOnly={true} />
        </Grid>
      </Grid>

      {/* {admin && <ExpectedPriceComp pageFrom="addPlace" control={control} admin />} */}

      {admin && (
        <Amount
          isMdScreen={isMdScreen}
          register={register}
          isOpenPlace={isOpenPlace}
          control={control}
          amount={amount}
        />
      )}

      {admin && (
        <Keywords
          errors={errors}
          setModalProp={setModalProp}
          useFieldArray={useFieldArray}
          control={control}
          changedProperty={null}
          pageFrom="addPlace"
          keywordsInput
          values
        />
      )}

      {admin && (
        <Category
          control={control}
          isMdScreen={isMdScreen}
          admin
          initData={initData}
          adminCategory={adminCategory}
        />
      )}
    </>
  );
};

export default ReadOnlyForm;

//////////////////////////////////////////////////////////////////
//장소 사진 관련 컴포넌트들 start
//////////////////////////////////////////////////////////////////
const SeasonImagesComp = ({ title, subTitle, isMdScreen, imagesFields }) => {
  const [seasonImages, setSeasonImages] = useState([]);
  useEffect(() => {
    setSeasonImages(imagesFields);
  }, [imagesFields]);

  return (
    <>
      {seasonImages.length > 0 && (
        <SeasonDiv>
          <SeasonText Text={title} subText={subTitle} isMdScreen={isMdScreen} />
          <ImageButtonContainerDiv>
            <ImageContainerDiv>
              {seasonImages.map((item, index) => (
                <ImageContainer key={index}>
                  <img
                    src={item.url}
                    alt={`thumbnail-${index}`}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </ImageContainer>
              ))}
            </ImageContainerDiv>
          </ImageButtonContainerDiv>
        </SeasonDiv>
      )}
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const SeasonDiv = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const ImageButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainerDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  flex: 1;
`;

const ImageContainer = styled.div`
  position: relative;
  padding-right: 0.5rem;
  padding-bottom: 12px;
  padding-top: 12px;
`;

const SeasonText = ({ Text, subText, isMdScreen }) => (
  <SeasonTextDiv>
    <Typography
      sx={{
        fontSize: isMdScreen && '0.8rem',
        width: '100px',
      }}
    >
      {Text}
    </Typography>
    <Typography
      sx={{
        fontSize: '12px',
        color: '#828282',
        width: '100px',
      }}
    >
      {subText}
    </Typography>
  </SeasonTextDiv>
);

const SeasonTextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
//////////////////////////////////////////////////////////////////
//장소 사진 관련 컴포넌트들 end
//////////////////////////////////////////////////////////////////

const Name = ({ register, isMdScreen }) => (
  <SectionContainer>
    <Typography
      sx={{
        flexShrink: 0,
        width: '80px',
        marginBottom: '10px',
        marginRight: '6%',
        fontSize: isMdScreen && '0.8rem',
      }}
    >
      장소명*
    </Typography>
    <ContentContainer>
      <TextField
        {...register('name', {
          required: '필수 입력란입니다.',
        })}
        defaultValue=""
        placeholder="등록하실 곳의 업체명을 입력해주세요."
        type="text"
        fullWidth
        disabled
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </ContentContainer>
  </SectionContainer>
);

const Address = ({ register, isMdScreen }) => (
  <SectionContainer>
    <div style={{ flexShrink: 0, width: '80px', marginBottom: '10px', marginRight: '6%' }}>
      <Typography
        sx={{
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        장소 주소*
      </Typography>
      <Typography
        sx={{
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        (도로명)
      </Typography>
    </div>
    <ContentContainer>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item display="flex" xs={10}>
          <TextField
            {...register('address', {
              required: '필수 입력란입니다.',
            })}
            fullWidth
            placeholder="등록하실 곳의 주소를 입력해주세요."
            type="text"
            sx={{
              marginRight: 1,
              '.MuiInputBase-input': {
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
              },
            }}
            disabled
          />
        </Grid>
        <Grid item display="flex" xs={2}>
          <Button
            fullWidth
            sx={{
              background: MAIN_ORANGE,
              '&:hover': {
                backgroundColor: FILTER_ORANGE,
              },
            }}
          >
            <SearchIcon sx={{ color: 'white' }} />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <TextField
          {...register('detailAddress')}
          placeholder="상세주소를 입력해주세요."
          type="text"
          fullWidth
          disabled
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        />
      </Grid>
    </ContentContainer>
  </SectionContainer>
);

const Region = ({ isMdScreen, admin, register, values }) => (
  <>
    <SectionContainer style={{ paddingTop: '10px' }}>
      <Typography
        sx={{
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        시군구*
      </Typography>
      <ContentContainer>
        <Grid container spacing={1} paddingX="1vw">
          <Typography
            {...register('region')}
            disabled
            fullWidth
            type="text"
            mt={1}
            color="#9E9E9E"
            sx={{ fontSize: isMdScreen && '0.8rem', marginBottom: '10px' }}
          >
            {admin && values?.region}
          </Typography>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  </>
);

export const Category = ({ control, setValue, isMdScreen, adminCategory }) => {
  const categoryData = useWatch({
    control,
    name: 'placeCategories',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'placeCategories',
  });

  return (
    <SectionContainer>
      <div style={{ display: 'flex', width: '80px', marginBottom: '10px', marginRight: '6%' }}>
        <Typography
          sx={{
            fontSize: isMdScreen && '0.8rem',
            flexShrink: 0,
            marginRight: '10px',
          }}
        >
          카테고리*
        </Typography>
      </div>
      <ContentContainer>
        <Select
          fullWidth
          placeholder="카테고리를 선택해주세요."
          sx={{
            fontSize: { xs: '0.85rem', md: '1rem' },
            height: '55px',
          }}
        >
          {adminCategory?.map(item => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => {
                if (!fields.find(field => field.name === item.name)) {
                  append(item);
                }
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <div style={{ display: 'flex', width: '100%', marginTop: '12px', flexWrap: 'wrap' }}>
          {Array.isArray(fields) &&
            fields.map((category, index) => {
              return (
                <CategoryItem
                  key={category.id}
                  keyword={category.name}
                  handleDeleteCategory={() => {
                    remove(fields.findIndex(item => item.id === category.id));
                  }}
                  pageFrom="editPlace"
                />
              );
            })}
        </div>
      </ContentContainer>
    </SectionContainer>
  );
};

class CategoryItem extends React.PureComponent {
  render() {
    const { keyword, handleDeleteCategory, pageFrom, isMdScreen } = this.props;
    return (
      <Button
        sx={{
          borderColor: MAIN_ORANGE,
          border: '1px solid',
          borderRadius: 50,
          marginRight: 1,
          marginBottom: 1,
          height: isMdScreen && '7vw',
          fontSize: isMdScreen && '0.5rem',
          flexShrink: 0,
        }}
        onClick={() => {
          if (pageFrom === 'editPlace' || pageFrom === 'addPlace') {
            handleDeleteCategory();
          }
        }}
        key={keyword}
      >
        <Typography
          sx={{
            fontSize: { xs: '0.7rem', md: '1rem', flexShrink: 0 },
          }}
        >
          {keyword}
        </Typography>
        <CloseIcon
          sx={{
            marginLeft: 1,
            fontSize: { xs: '0.7rem', md: '1rem' },
          }}
        />
      </Button>
    );
  }
}

const Amenities = ({ isMdScreen, control, initData }) => (
  <Grid container flexDirection="row" minHeight={240} alignItems="start" mb={4}>
    <Grid container flexDirection="column" flex>
      <Grid item xs={2}>
        <Typography
          mb={1}
          sx={{
            flexShrink: 0,
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          시설정보*
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>
          해당하는 모든 사항을 선택해주세요.
        </Typography>
      </Grid>
      <Grid container>
        <Box
          display="flex"
          flexWrap="wrap"
          alignContent="center"
          paddingX="1vw"
          paddingTop="1.5vw"
          paddingBottom="40px"
        >
          <Controller
            name="amenities"
            control={control}
            render={({ field }) => (
              <>
                {initData?.amenities?.map(service => (
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    key={service.id}
                    style={{
                      flexBasis: '33.33%',
                      padding: '8px',
                      alignItems: 'center',
                    }}
                    flexDirection="row"
                  >
                    {/* 각 체크박스는 동일한 field 객체를 공유 */}
                    <Checkbox
                      {...field}
                      checked={field?.value?.some(item => item.id === service.id) || false}
                      value={service.name}
                      style={{ padding: 0, marginRight: isMdScreen ? '5px' : '8px' }}
                      disabled
                    />
                    <Typography mt={0.5} sx={{ fontSize: isMdScreen && '0.8rem' }}>
                      {service.name}
                    </Typography>
                  </Grid>
                ))}
              </>
            )}
          />
        </Box>
      </Grid>

      <Divider variant="middle" />
      <Grid item xs={2} />
    </Grid>
  </Grid>
);

const IsInside = ({ control, isMdScreen }) => (
  <Grid container direction="row" alignItems="center" mb={4}>
    <Typography
      mr={3}
      sx={{
        fontSize: isMdScreen && '0.8rem',
      }}
    >
      실내외 여부*
    </Typography>

    <RadioGroup row>
      <Controller
        name="isInside"
        control={control}
        render={({ field }) => (
          <>
            <FormControlLabel
              {...field}
              value="실내"
              checked={field.value === '실내'}
              onChange={() => field.onChange('실내')}
              label="실내"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: isMdScreen && '0.8rem',
                },
              }}
              control={<Radio disabled />}
            />
            <FormControlLabel
              {...field}
              value="실외"
              checked={field.value === '실외'}
              onChange={() => field.onChange('실외')}
              label="실외"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: isMdScreen && '0.8rem',
                },
              }}
              control={<Radio disabled />}
            />
            <FormControlLabel
              {...field}
              value="실내외"
              checked={field.value === '실내외'}
              onChange={() => field.onChange('실내외')}
              label="실내외"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: isMdScreen && '0.8rem',
                },
              }}
              control={<Radio disabled />}
            />
          </>
        )}
      />
    </RadioGroup>
    <Grid item xs={2} />
  </Grid>
);

const Images = ({ isMdScreen, imagesFields }) => (
  <>
    {imagesFields.length > 0 && (
      <>
        <Divider variant="middle" />
        <MainContainer>
          <SeasonDiv style={{ display: 'flex', flex: 1 }}>
            <Typography
              sx={{
                fontSize: isMdScreen && '0.8rem',
                width: '100px',
              }}
            />
            <Typography sx={{ fontSize: '0.8rem', flex: 1 }}>
              {/* 사진은 총 21장 첨부가능하며 JPG, JPEG, PNG 파일만 등록이 가능합니다. */}
            </Typography>
          </SeasonDiv>
          <SeasonImagesComp title="장소사진" isMdScreen={isMdScreen} imagesFields={imagesFields} />
        </MainContainer>
      </>
    )}
  </>
);

const Date = ({ control, isMdScreen }) => (
  <>
    <Grid container alignItems="center" display="flex" paddingTop="24px">
      <Grid container flexDirection="row-reverse" mb={1.5}>
        <Typography sx={{ fontSize: '0.8rem' }}>
          공연 일정 또는 운영 기간 종료일이 있을 경우 입력해 주세요.
        </Typography>
      </Grid>
    </Grid>
    <SectionContainer>
      <Typography
        sx={{
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        종료일
      </Typography>
      <ContentContainer>
        <Grid item xs={10} display="flex">
          <Grid item xs={6}>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    value={value ? dayjs(value) : null}
                    format="YY-MM-DD"
                    disabled
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={6} style={{ marginLeft: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="endTime"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ width: '100%' }}>
                    <TimePicker
                      {...field}
                      ampm={false}
                      openTo="hours"
                      views={['hours', 'minutes']}
                      format="HH:mm"
                      label="종료 시간"
                      value={field.value ? dayjs(`2023-10-03 ${field.value}`) : null}
                      disabled
                    />
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  </>
);

const OperatingHours = ({ operationTimeFields, control, isMdScreen, initData }) => {
  const dayTypes = initData?.businessHourList;
  function findMatchingDayType(field) {
    const matchingDayType = dayTypes?.find(dayType => dayType.name === field.value);
    return matchingDayType ? matchingDayType.value : field.value;
  }

  return (
    <SectionContainer style={{ marginBottom: '36px', marginTop: '36px' }}>
      <Typography
        sx={{
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        운영시간
      </Typography>

      <ContentContainer>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3}>
            {operationTimeFields.length ? (
              operationTimeFields?.map((item, index) => (
                <FormControl key={item.id} sx={{ width: '100%', paddingBottom: '16px' }}>
                  <Controller
                    name={`operatingHours.${index}.day`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        displayEmpty
                        value={(!field.value && 'none') || findMatchingDayType(field)}
                        disabled
                        sx={{
                          fontSize: { xs: '0.8rem', md: '1rem' },
                          height: '55px',
                        }}
                      >
                        {dayTypes?.map(day => (
                          <MenuItem key={day.id} value={day.id}>
                            {day.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              ))
            ) : (
              <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                <Select
                  displayEmpty
                  value="none"
                  disabled
                  sx={{
                    fontSize: { xs: '0.7rem', md: '1rem' },
                    height: '55px',
                  }}
                >
                  {dayTypes?.map(day => (
                    <MenuItem key={day.id} value={day.id}>
                      {day.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={4}>
            {operationTimeFields.length ? (
              operationTimeFields.map((item, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={item.id}>
                  <Controller
                    name={`operatingHours.${index}.openingTime`}
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                        <TimePicker
                          {...field}
                          value={field.value ? dayjs(`2023-10-03 ${field.value}`) : null}
                          ampm={false}
                          openTo="hours"
                          views={['hours', 'minutes']}
                          format="HH:mm"
                          label="시작 시간"
                          disabled
                        />
                      </FormControl>
                    )}
                  />
                </LocalizationProvider>
              ))
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                  <TimePicker
                    value={null}
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes']}
                    format="HH:mm"
                    label="시작 시간"
                    disabled
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          </Grid>

          <Grid item xs={4}>
            {operationTimeFields.length ? (
              operationTimeFields?.map((item, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={item.id}>
                  <Controller
                    name={`operatingHours.${index}.closingTime`}
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                        <TimePicker
                          {...field}
                          value={field.value ? dayjs(`2023-10-03 ${field.value}`) : null}
                          ampm={false}
                          openTo="hours"
                          views={['hours', 'minutes']}
                          format="HH:mm"
                          label="종료 시간"
                          disabled
                        />
                      </FormControl>
                    )}
                  />
                </LocalizationProvider>
              ))
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl sx={{ width: '100%', paddingBottom: '16px' }}>
                  <TimePicker
                    value={null}
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes']}
                    format="HH:mm"
                    label="종료 시간"
                    disabled
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          </Grid>
          <Grid item xs={1}>
            {/* 운영 시간 삭제 버튼 */}
            {operationTimeFields.length ? (
              operationTimeFields?.map(item => (
                <Controller
                  key={item.id}
                  name=""
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        height: '72px',
                        paddingBottom: '16px',
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SVGRenderer {...field} type="delete" height="100px" />
                    </Box>
                  )}
                />
              ))
            ) : (
              <Box
                sx={{
                  height: '72px',
                  paddingBottom: '16px',
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SVGRenderer type="delete" height="100px" />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container flexDirection="row-reverse">
          <Button
            component="label"
            sx={{
              background: 'white',
              color: MAIN_ORANGE,
              height: '30px',
              border: 1,
              borderRadius: '20px',
              borderColor: MAIN_ORANGE,
              borderWidth: 1,
              paddingY: 1,
              paddingX: 2,
            }}
          >
            운영시간 추가 +
          </Button>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
};

const Products = ({ isMdScreen, productPriceFields, register, control }) => (
  <SectionContainer style={{ marginBottom: '36px' }}>
    <Typography
      sx={{
        fontSize: isMdScreen && '0.8rem',
        flexShrink: 0,
        width: '80px',
        marginBottom: '10px',
        marginRight: '6%',
      }}
    >
      상품 요금
    </Typography>
    <ContentContainer>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={5}>
          {productPriceFields.length ? (
            productPriceFields.map((item, index) => (
              <TextField
                key={item.id}
                {...register(`products.${index}.name`)}
                placeholder="이름"
                type="text"
                sx={{
                  paddingBottom: '16px',
                  width: '100%',
                  '.MuiInputBase-input': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                  },
                  '.MuiInputBase-root': {
                    height: '55px',
                  },
                }}
                disabled
              />
            ))
          ) : (
            <TextField
              placeholder="이름"
              type="text"
              sx={{
                paddingBottom: '16px',
                width: '100%',
                '.MuiInputBase-input': {
                  fontSize: { xs: '0.8rem', md: '1rem' },
                },
                '.MuiInputBase-root': {
                  height: '55px',
                },
              }}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {productPriceFields.length ? (
            productPriceFields.map((item, index) => (
              <TextField
                key={item.id}
                {...register(`products.${index}.price`)}
                placeholder="가격"
                type="text"
                sx={{
                  paddingBottom: '16px',
                  width: '100%',
                  '.MuiInputBase-input': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                  },
                  '.MuiInputBase-root': {
                    height: '55px',
                  },
                }}
                disabled
              />
            ))
          ) : (
            <TextField
              placeholder="가격"
              type="text"
              sx={{
                paddingBottom: '16px',
                width: '100%',
                '.MuiInputBase-input': {
                  fontSize: { xs: '0.8rem', md: '1rem' },
                },
                '.MuiInputBase-root': {
                  height: '55px',
                },
              }}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={1}>
          {/* 상품 요금 삭제 버튼 */}
          {productPriceFields.length ? (
            productPriceFields?.map(item => (
              <Controller
                key={item.id}
                name=""
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      height: '72px',
                      paddingBottom: '16px',
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SVGRenderer {...field} type="delete" height="100px" />
                  </Box>
                )}
              />
            ))
          ) : (
            <Box
              sx={{
                height: '72px',
                paddingBottom: '16px',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SVGRenderer type="delete" height="100px" />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container flexDirection="row-reverse">
        <Button
          component="label"
          sx={{
            background: 'white',
            color: MAIN_ORANGE,
            height: '30px',
            border: 1,
            borderRadius: '20px',
            borderColor: MAIN_ORANGE,
            borderWidth: 1,
            paddingY: 1,
            paddingX: 2,
          }}
        >
          상품리스트 추가 +
        </Button>
      </Grid>
    </ContentContainer>
  </SectionContainer>
);

const PhoneNumber = ({ register, isMdScreen }) => (
  <SectionContainer style={{ marginBottom: '24px' }}>
    <Typography
      sx={{
        flexShrink: 0,
        width: '80px',
        marginBottom: '10px',
        marginRight: '6%',
        fontSize: isMdScreen && '0.8rem',
      }}
    >
      전화번호
    </Typography>
    <ContentContainer>
      <TextField
        {...register('phoneNumber')}
        placeholder="업체에 연락 가능한 번호를 입력해주세요."
        type="text"
        fullWidth
        disabled
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </ContentContainer>
  </SectionContainer>
);

const HomePage = ({ register, isMdScreen }) => (
  <SectionContainer style={{ marginBottom: '24px' }}>
    <Typography
      sx={{
        flexShrink: 0,
        width: '80px',
        marginBottom: '10px',
        marginRight: '6%',
        fontSize: isMdScreen && '0.8rem',
      }}
    >
      홈페이지
    </Typography>
    <ContentContainer>
      <TextField
        {...register('homepage')}
        defaultValue=""
        placeholder="업체 홈페이지나 혹은 예약페이지를 입력해주세요."
        type="text"
        fullWidth
        disabled
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </ContentContainer>
  </SectionContainer>
);
