import { Grid, Typography, TextField, Button, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PlaceInfoModal from '../../../pages/SearchPlace/RevisePage/PlaceInfoModal';
import { forwardRef, useEffect, useState } from 'react';
import { MAIN_ORANGE, FILTER_ORANGE, ERROR_RED } from '../../../constant/colors';
import { useTheme } from '@emotion/react';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import preventEnterSubmit from '../../../hooks/preventEnterSubmit';
import { calculateFontSize } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

/**
 * 장소 주소 컴포넌트
 * @param errors
 * @param register
 * @param setValue
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */

function Address(
  {
    errors,
    setValue,
    register,
    pageFrom,
    changedProperty,
    comparisonOriginalData,
    control,
    initData,
  },
  ref,
) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SectionContainer>
      <div style={{ flexShrink: 0, width: '80px', marginBottom: '10px', marginRight: '6%' }}>
        <Typography
          sx={{
            color:
              ((pageFrom === 'editPlace' && changedProperty) ||
                (pageFrom === 'comparison' && changedProperty)) &&
              ERROR_RED,
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          장소 주소*
        </Typography>
        <Typography
          sx={{
            color:
              ((pageFrom === 'editPlace' && changedProperty) ||
                (pageFrom === 'comparison' && changedProperty)) &&
              ERROR_RED,
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          (도로명)
        </Typography>
      </div>
      <ContentContainer>
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item display="flex" xs={10}>
            <TextField
              {...register('address')}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              placeholder="등록하실 곳의 주소를 입력해주세요."
              type="text"
              onClick={() => {
                setOpen(true);
              }}
              disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
              sx={{
                marginRight: 1,
                '.MuiInputBase-input': {
                  fontSize: {
                    xs: '0.75rem',
                    sm: '0.875rem',
                    md: '1rem',
                  },
                },
              }}
              onKeyPress={preventEnterSubmit}
            />
            {open && (
              <PlaceInfoModal
                setValue={setValue}
                open={open}
                setOpen={setOpen}
                initData={initData}
              />
            )}
          </Grid>
          <Grid item display="flex" xs={2}>
            <Button
              fullWidth
              onClick={() => setOpen(true)}
              disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
              sx={{
                background: MAIN_ORANGE,
                '&:hover': {
                  backgroundColor: FILTER_ORANGE,
                },
              }}
            >
              <SearchIcon sx={{ color: 'white' }} />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <TextField
            {...register('detailAddress')}
            placeholder="상세주소를 입력해주세요."
            type="text"
            fullWidth
            disabled={pageFrom === 'comparison' || pageFrom === 'readonly'}
            sx={{
              '.MuiInputBase-input': {
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
              },
            }}
            onKeyPress={preventEnterSubmit}
          />
        </Grid>
        <Grid
          item
          xs={10}
          container
          flexDirection="column"
          minHeight={(pageFrom === 'comparison' && 50) || 20}
        >
          <Typography sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
            {errors?.address?.message || errors?.detailAddress?.message}
          </Typography>
          {pageFrom === 'comparison' && comparisonOriginalData?.address && (
            <Typography>{`기존 주소 : ${comparisonOriginalData?.address}`}</Typography>
          )}
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
}

export default forwardRef(Address);
