import React from 'react';
import { Typography, Button, Modal, Box, Grid } from '@mui/material';
import { FILTER_ORANGE, MAIN_ORANGE } from '../../../constant/colors';
import { EMAIL_ADDRESS } from '../../../api/urls';

const RequestCompletedModal = ({ open, handleModalClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '27rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 5,
    paddingBottom: 5,
    borderRadius: '5px',
  };
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      sx={{ boxShadow: 'none', border: 'none', borderRadius: '20' }}
    >
      <Box sx={style} display="flex" flexDirection="column">
        <Typography variant="h6" mb={1.25} sx={{ color: ' #282422', fontWeight: 600 }}>
          정산 요청이 완료되었습니다.
        </Typography>

        <Grid container>
          <Typography>요청 확인 후, 입금이 진행 될 예정입니다.</Typography>
          <Typography>완료까지 약 며칠의 시간이 소요 될 수 있습니다.</Typography>

          <div style={{ height: 24 }} />
          <Grid container>
            <Typography sx={{ color: MAIN_ORANGE }}>
              이메일({EMAIL_ADDRESS})로
              <br />
              신분증 사본과 통장 사본을 보내주세요!
            </Typography>
          </Grid>
          <div style={{ height: 12 }} />

          <Button
            sx={{
              width: '100%',
              height: '2.5rem',
              // marginTop: '1rem',
              backgroundColor: MAIN_ORANGE,
              '&:hover': {
                backgroundColor: FILTER_ORANGE,
              },
            }}
            onClick={handleModalClose}
          >
            <Typography color="white">확인</Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RequestCompletedModal;
