import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { FILTER_ORANGE } from '../constant/colors';

export default function EditButton({ navigateTo }) {
  const navigate = useNavigate();
  return (
    <EditIcon
      onClick={() => navigate(navigateTo)}
      sx={{
        fontSize: '1.5rem',
        color: '#D2D2D2FF',
        cursor: 'pointer',
        '&:hover': {
          color: FILTER_ORANGE,
        },
      }}
      className="editIcon"
    />
  );
}
