import { TextField, Typography, useMediaQuery } from '@mui/material';
import { ERROR_RED } from '../../../constant/colors';
import { ContentContainer, SectionContainer } from '../../../pages/AddPlace/AddPlace';
import { useTheme } from '@emotion/react';
import preventEnterSubmit from '../../../hooks/preventEnterSubmit';

/**
 * 홈페이지 컴포넌트
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param register
 * @param dirtyFields
 * @returns {JSX.Element}
 * @constructor
 */

export default function HomePage({ changedProperty, register }) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SectionContainer>
      <Typography
        sx={{
          color: changedProperty && ERROR_RED,
          flexShrink: 0,
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
          fontSize: isMdScreen && '0.8rem',
        }}
      >
        홈페이지*
      </Typography>
      <ContentContainer>
        <TextField
          {...register('homepage')}
          defaultValue=""
          placeholder="업체 홈페이지나 혹은 예약페이지를 입력해주세요."
          type="text"
          fullWidth
          onKeyPress={preventEnterSubmit}
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        />
      </ContentContainer>
    </SectionContainer>
  );
}
