import React, { useEffect, useState } from 'react';
import { ContentContainer, SectionContainer } from '../../pages/AddPlace/AddPlace';
import { Typography, Grid, useMediaQuery, Divider } from '@mui/material';
import { styled } from 'styled-components';
import { useFieldArray, useWatch } from 'react-hook-form';

import Name from '../InputForm/components/Name';
import Address from '../InputForm/components/Address';
import Amenities from '../InputForm/components/Amenities';
import useFetchInitData from '../../hooks/useFetchInitData';
import IsInside from '../InputForm/components/IsInside';
import Images from '../InputForm/components/Images';
import OperatingHours from '../InputForm/components/OperatingHours';
import Products from '../InputForm/components/Products';
import PhoneNumber from '../InputForm/components/PhoneNumber';
import HomePage from '../InputForm/components/HomePage';
import Amount from '../InputForm/components/Amount';
import Keywords from '../InputForm/components/Keywords';
import { Category } from '../ReadOnlyForm';
import { theme } from '../../styles/theme';
import { useCustomCategory } from '../../hooks/useCustomCategory';
import TextArea from '../TextArea';
import Date from '../InputForm/components/Date';
import SectionData from '../InputForm/components/SectionData';
import Description, { DESCRIPTION_VARIATION } from '../InputForm/components/Description';

function sortObjectKeys(arr1) {
  return arr1.map(item =>
    Object.keys(item)
      .sort()
      .reduce((result, key) => {
        result[key] = item[key];
        return result;
      }, {}),
  );
}

/**
 * 이전 장소 데이터와 수정된 장소 데이터를 비교하는 컴포넌트
 */
const ComparePatchComp = ({ register, control, formData, values, setValue, handleModalClose }) => {
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { adminCategory } = useCustomCategory();
  const { initData } = useFetchInitData(handleModalClose);

  const watch = useWatch({
    control,
  });

  const { append: appendImages } = useFieldArray({
    control,
    name: 'images',
  });

  return (
    <MainContainer>
      {/* 장소명 확인 */}
      <Name //
        register={register}
        control={control}
      />
      {formData.originalPlace
        ? watch.name !== formData.originalPlace?.name && (
            <CompareTextComp value={formData.originalPlace?.name} />
          )
        : null}
      {/* 주소 확인 */}
      <Address initData={initData} register={register} control={control} setValue={setValue} />
      {formData.originalPlace
        ? watch.address + watch.detailAddress !== formData.originalPlace?.address && (
            <CompareTextComp value={formData.originalPlace?.address} />
          )
        : null}
      <SectionData name="지번" data={values?.jibun} isMdScreen={isMdScreen} />
      <SectionData name="시군구" data={values?.region} isMdScreen={isMdScreen} />
      {/* 시설정보 */}
      <Amenities register={register} control={control} initData={initData} />
      {formData.originalPlace
        ? JSON.stringify(watch.amenities) !== JSON.stringify(formData.originalPlace?.amenities) && (
            <CompareObjComp value={formData.originalPlace?.amenities} />
          )
        : null}
      {/* 실내외 */}
      <IsInside control={control} />
      {formData.originalPlace
        ? watch.isInside !== formData.originalPlace?.isInside && (
            <CompareTextComp value={formData.originalPlace?.isInside} />
          )
        : null}
      {/* 장소사진 */}
      <Images setModalProp={() => null} control={control} pageFrom="editPlace" />
      <div style={{ height: '1rem' }} />
      {formData.originalPlace
        ? JSON.stringify(sortObjectKeys(watch.images || [])) !==
            JSON.stringify(sortObjectKeys(formData.originalPlace?.images || [])) && (
            <CompareImages value={formData.originalPlace?.images} appendImages={appendImages} />
          )
        : null}
      {/* 운영시간 */}
      <OperatingHours control={control} pageFrom="editPlace" />
      {formData.originalPlace
        ? JSON.stringify(sortObjectKeys(watch.operatingHours || [])) !==
            JSON.stringify(sortObjectKeys(formData.originalPlace?.operatingHours || [])) && (
            <CompareOperatingHours value={formData.originalPlace?.operatingHours || []} />
          )
        : null}
      {/* 상품요금 */}
      <Products control={control} register={register} />
      {formData.originalPlace
        ? JSON.stringify(sortObjectKeys(watch.products || [])) !==
            JSON.stringify(sortObjectKeys(formData.originalPlace?.products || [])) && (
            <CompareProducts value={formData.originalPlace?.products || []} />
          )
        : null}
      {/* 전화번호 */}
      <PhoneNumber register={register} control={control} />
      {formData.originalPlace
        ? watch.phoneNumber !== formData.originalPlace?.phoneNumber && (
            <CompareTextComp value={formData.originalPlace?.phoneNumber} />
          )
        : null}
      {/* 홈페이지 */}
      <HomePage register={register} />
      {formData.originalPlace
        ? watch.homepage !== formData.originalPlace?.homepage && (
            <CompareTextComp value={formData.originalPlace?.homepage} />
          )
        : null}
      <Date control={control} isMdScreen={isMdScreen} />
      {/* 상세설명 */}
      <Description setValue={setValue} control={control} />
      {formData.changedProperty?.indexOf('description') !== -1 ? (
        <CompareDescComp value={formData.originalPlace?.description || {}} />
      ) : null}
      <Divider sx={{ width: '100%', margin: '1rem 0' }} />
      {/* 정산금 확인 */}
      {/* {formData.originalPlace ? (
        <RevisePriceComp control={control} isOpenPlace={true} admin formData={formData} />
      ) : (
        <ExpectedPriceComp pageFrom="addPlace" control={control} admin />
      )} */}
      <Amount
        register={register}
        isOpenPlace={formData.isOpen}
        amount={formData.amount}
        isMdScreen={isMdScreen}
      />
      {/* 해시태그 */}
      <Keywords control={control} keywordsInput values pageFrom="editPlace" />
      {/* 카테고리 */}
      <Category
        control={control}
        isMdScreen={isMdScreen}
        adminCategory={adminCategory}
        setValue={setValue}
      />
    </MainContainer>
  );
};

export default ComparePatchComp;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const CompareImages = ({ value, appendImages }) => (
  <>
    <SectionContainer>
      <div
        style={{
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: '0.8rem',
          }}
        >
          원본 이미지
        </Typography>
      </div>
      <ContentContainer style={{ borderRadius: 4, backgroundColor: '#eee', padding: '10px' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '10px' }}>
          {value.map((item, index) => {
            return (
              <img
                key={index}
                src={item.url}
                alt="1"
                style={{ width: '100%', objectFit: 'cover' }}
                onClick={e => {
                  e.preventDefault();
                  appendImages({
                    url: item.url,
                    season: '사계절',
                  });
                }}
              />
            );
          })}
        </div>
      </ContentContainer>
    </SectionContainer>
    <Divider sx={{ width: '100%', margin: '1rem 0' }} />
  </>
);

const CompareProducts = ({ value }) => (
  <>
    <ContentContainer style={{ borderRadius: 4, backgroundColor: '#eee', padding: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {value.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'red',
                lineHeight: '1.3rem',
              }}
            >
              <div style={{ flex: 1, marginRight: '10px' }}>{item.name}</div>
              <div style={{ flex: 1, marginRight: '10px' }}>{item.price}</div>
            </div>
          );
        })}
      </div>
    </ContentContainer>
    <Divider sx={{ width: '100%', margin: '1rem 0' }} />
  </>
);

const CompareOperatingHours = ({ value }) => (
  <>
    <ContentContainer style={{ borderRadius: 4, backgroundColor: '#eee', padding: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {value.map((item, index) => {
          return (
            <div key={index} style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
              <div style={{ width: '100px', marginRight: '10px' }}>{item.day}</div>
              <div style={{ width: '100px', marginRight: '10px' }}>{item.openingTime}</div>
              <div style={{ width: '100px', marginRight: '10px' }}>{item.closingTime}</div>
            </div>
          );
        })}
      </div>
    </ContentContainer>

    <Divider sx={{ width: '100%', margin: '1rem 0' }} />
  </>
);

const DescInput = ({ register, isMdScreen }) => {
  return (
    <SectionContainer>
      <div
        style={{
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            width: '80px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          장소소개
        </Typography>
      </div>
      <ContentContainer>
        <TextArea
          {...register('description')}
          name="description"
          placeholder="상세설명을 입력해주세요."
          fullWidth
          rows={10}
          multiline
          style={{ width: '100%', height: '100%', fontSize: isMdScreen && '0.8rem' }}
        />
      </ContentContainer>
    </SectionContainer>
  );
};

const CompareDescComp = ({ value, isMdScreen }) => {
  const keys = ['introduction', 'info', 'guide', 'room', 'checkInOut', 'tip', 'parking'];
  return (
    <SectionContainer style={{ flexDirection: 'column' }}>
      <div
        style={{
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            width: '100px',
            marginBottom: '10px',
            marginRight: '6%',
            fontSize: isMdScreen && '0.8rem',
          }}
        >
          장소소개 원본
        </Typography>
      </div>
      <div />
      <ContentContainer>
        {keys.map(key => {
          if (value.hasOwnProperty(key)) {
            return (
              <div>
                <Typography
                  mr={2}
                  sx={{
                    fontSize: isMdScreen ? '0.7rem' : '0.8rem',
                  }}
                >
                  {DESCRIPTION_VARIATION[key]?.label || '본문'}
                </Typography>
                <div style={{ height: '5px' }} />
                <TextArea
                  value={value[key]}
                  disabled={true}
                  style={{ minHeight: '150px', fontSize: '0.85rem', color: '#9E9E9E' }}
                />
                <div style={{ height: '15px' }} />
              </div>
            );
          }
        })}
      </ContentContainer>
    </SectionContainer>
  );
};

const CompareObjComp = ({ value }) => {
  return (
    <Grid container spacing={1} paddingX="1vw">
      <Typography disabled fullWidth type="text" mt={1} color="red">
        {value.map(item => item.name).join(', ')}
      </Typography>
    </Grid>
  );
};

const CompareTextComp = ({ value }) => {
  return (
    <SectionContainer>
      <div
        style={{
          width: '80px',
          marginBottom: '10px',
          marginRight: '6%',
        }}
      />
      <ContentContainer>
        <Grid container spacing={1} paddingX="1vw" style={{ overflow: 'auto' }}>
          <Typography
            // disabled
            fullWidth
            type="text"
            mt={1}
            color="red"
            style={{ wordWrap: 'break-all', overflowWrap: 'break-word' }}
          >
            {value}
          </Typography>
        </Grid>
      </ContentContainer>
    </SectionContainer>
  );
};
