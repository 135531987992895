import React from 'react';

import { Typography, Button, Box, Grid } from '@mui/material';
import { FILTER_ORANGE, MAIN_ORANGE } from '../constant/colors';
import { useNavigate } from 'react-router-dom';

/**
 * @param {object} props
 * @param {boolean} props.visible 창이 보일지를 선택하는 state입니다
 * @param {() => void} props.setVisible  창이 보일지를 설정하는 함수 입니다
 * @param {string} props.titleText 알림창의 타이틀 텍스트 입니다
 * @param {string} props.text 알림창의 첫번째 줄 텍스트 입니다
 * @param {string} props.text2 알림창의 두번째 줄 텍스트 입니다 (텍스트 2)
 * @param {string} props.buttonText 1버튼의 텍스트 입니다
 * @param {() => any} props.onPress 1버튼을 눌렀을때 작동하는 함수입니다
 * @param {boolean | undefined} props.isSelect 2버튼 생성
 * @param {boolean | undefined} props.buttonText2 2버튼의 택스트
 * @param {()=> void} props.onPress2 2버튼을 눌렀을때 작동하는 함수입니다
 * @param isPrimaryButtonOrange 오렌지색 버튼을 주 버튼으로 설정합니다
 */
const Alert = ({
  visible,
  setVisible,
  titleText,
  text,
  text2,
  buttonText,
  onPress,
  buttonText2,
  onPress2,
  isSelect,
  isPrimaryButtonOrange,
  proposalId,
}) => {
  const navigate = useNavigate();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25rem',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4.5,
    pb: proposalId ? 3 : 4.5,
    borderRadius: '5px',
  };

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        visibility: visible ? 'visible' : 'hidden',
        zIndex: 999999,
      }}
    >
      <Box sx={style}>
        <Typography variant="h6" mb={1} sx={{ color: ' #282422', fontWeight: 600 }}>
          {titleText}
        </Typography>
        <Typography mb={text2 ? null : 2} sx={{ color: ' #282422' }}>
          {text}
        </Typography>
        <Typography mb={1} sx={{ color: ' #282422' }}>
          {text2}
        </Typography>

        {isSelect ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                sx={{
                  width: '100%',
                  height: '3rem',
                  marginTop: '1rem',
                  backgroundColor: isPrimaryButtonOrange ? MAIN_ORANGE : '#FFFFFF',
                  border: '1px solid',
                  '&:hover': {
                    backgroundColor: isPrimaryButtonOrange ? FILTER_ORANGE : '#FFFFFF',
                  },
                }}
                onClick={onPress}
              >
                <Typography color={isPrimaryButtonOrange ? 'white' : '#F99B20'}>
                  {buttonText}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{
                  width: '100%',
                  height: '3rem',
                  border: '1px solid',
                  marginTop: '1rem',
                  backgroundColor: isPrimaryButtonOrange ? '#FFFFFF' : MAIN_ORANGE,
                  '&:hover': {
                    backgroundColor: isPrimaryButtonOrange ? '#FFFFFF' : FILTER_ORANGE,
                  },
                }}
                onClick={onPress2}
              >
                <Typography color={isPrimaryButtonOrange ? MAIN_ORANGE : 'white'}>
                  {buttonText2}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Button
            sx={{
              width: '100%',
              height: '3rem',
              border: '1px solid',
              backgroundColor: MAIN_ORANGE,
              '&:hover': {
                backgroundColor: FILTER_ORANGE,
              },
            }}
            onClick={() => {
              onPress();
              setVisible(null);
            }}
          >
            <Typography color="white">{buttonText}</Typography>
          </Button>
        )}
        {proposalId && (
          <Typography
            color="#bbb"
            fontSize="0.85rem"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlineOffset: '5px',
              marginTop: '1rem',
            }}
            onClick={() => {
              setVisible(null);
              navigate(`/propsedplace/${proposalId}`, { state: { result: '승인대기' } });
            }}
          >
            방금 제안한 장소 수정
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default Alert;
