import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../../../recoilAtom/recoilAtom';
import queryString from 'query-string';
import { userApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import ProposedItem from './ProposedItem';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Typography, Grid, Paper, Box, Divider, useMediaQuery } from '@mui/material';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import { useTheme } from '@emotion/react';
import styled from 'styled-components';
import { MAIN_ORANGE } from '../../../constant/colors';
import getMaxPage from '../../../hooks/getMaxPage';

const tabList = [
  {
    value: 'all',
    label: '전체',
  },
  {
    value: 'pending',
    label: '승인대기',
  },
  {
    value: 'approved',
    label: '승인',
  },
  {
    value: 'hold',
    label: '보류',
  },
  {
    value: 'rejected',
    label: '반려',
  },
];

const ProposedPlaceMain = () => {
  const { handleNavigate } = useRedirect();
  const setModalProp = useSetRecoilState(modalProps);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxPage, setMaxPage] = useState(1);
  const [userTabList, setUserTabList] = useState({ value: 'all', label: '전체' });
  const queryClient = useQueryClient();
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.label && parsed.value) {
      setUserTabList({ ...parsed });
    } else {
      setUserTabList({ value: 'all', label: '전체' });
    }
  }, [window.location.search]);

  const handleChangeTabList = tab => {
    setUserTabList(tab);
    setCurrentPage(1);
  };

  const fetchProposedPlaceList = async ({ queryKey }) => {
    const [, userTabList, currentPage] = queryKey;

    try {
      const response = await userApi.get(`/api/v1/place-information-provider/me/proposals`, {
        params: {
          page: currentPage,
          status: userTabList.value,
        },
      });
      getMaxPage(response?.data?.totalCount, setMaxPage, 15);
      return response?.data;
    } catch (error) {
      if (error?.response?.data) {
        setModalProp({
          titleText: '제안서 목록을 불러오는 데 실패했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const { isLoading, data } = useQuery(
    ['readProposedPlace', userTabList, currentPage],
    fetchProposedPlaceList,
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );

  return (
    <Paper
      alignItems="center"
      flexDirection="column"
      sx={{
        minHeight: '100vh',
        paddingTop: isMdScreen ? '2vw' : '4vw',
        paddingBottom: isMdScreen ? '1vw' : '4vw',
        paddingLeft: isMdScreen ? '2vw' : '5vw',
        paddingRight: isMdScreen ? '2vw' : '5vw',
        transition: 'padding 0.5s ease',
        overflow: 'hidden',
      }}
      elevation={3}
    >
      <Grid container flexDirection="row" mb={1} justifyContent="flex-end">
        {tabList.map((tab, index) => (
          <React.Fragment key={index}>
            <StyledTab
              onClick={() => handleChangeTabList(tab)}
              isActive={tab.value === userTabList.value}
            >
              {tab.label}
            </StyledTab>

            {index !== tabList.length - 1 && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '0.8rem',
                  margin: 'auto 0',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Grid>
      <Box
        sx={{
          flexGrow: 1,
          borderTop: '1.6px solid #FBAF17',
          // borderBottom: '1.2px solid #BBB',
        }}
      >
        <Grid
          container
          sx={{
            minHeight: '40px',
            borderBottom: '1.2px solid #BBB',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
          }}
          alignContent="center"
          paddingY={2}
        >
          <Grid item xs={2.5} display="flex" justifyContent="center">
            <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>작성일자</Typography>
          </Grid>
          <Grid item xs={6.5} display="flex" justifyContent="center">
            <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>장소명</Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            <Typography sx={{ fontSize: isMdScreen && '0.8rem' }}>승인여부</Typography>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="center" />
        </Grid>
        <Grid container style={{ overflow: 'hidden' }}>
          {isLoading && <LoadingIndicator />}

          {data?.proposals.length ? (
            data?.proposals?.map((item, index) => {
              return (
                <ProposedItem
                  key={item.id}
                  item={item}
                  index={index}
                  data={data}
                  isMdScreen={isMdScreen}
                  setModalProp={setModalProp}
                  handleNavigate={handleNavigate}
                  queryClient={queryClient}
                />
              );
            })
          ) : (
            <>
              {!isLoading && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '30vh', color: '#5E5E5E' }}
                >
                  {userTabList.label === '전체' ? (
                    <Grid item>제안한 장소가 없습니다.</Grid>
                  ) : (
                    <Grid item>해당 상태의 제안한 장소가 없습니다.</Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <PaginationButton
        maxPage={maxPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Paper>
  );
};
export default ProposedPlaceMain;

const StyledTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? MAIN_ORANGE : '#6B6B6B')};
  font-weight: ${({ isActive }) => (isActive ? 600 : 'normal')};
  padding: 10px;
  font-size: 0.8rem;

  @media (min-width: 600px) {
    width: 14%;
  }
  @media (min-width: 960px) {
    width: 11%;
  }
`;
