import { TextField } from '@mui/material';
import FormContainer from './FormContainer';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import CapsLockIndicator from '../../../components/InputForm/components/CapsLockIndicator';
import useCapsLock from '../../../hooks/useCapsLock';

export default function ConfirmPassword({
  isPasswordMatch,
  setPasswordMatch,
  control,
  register,
  setValue,
  pageFrom,
}) {
  const capsLockOn = useCapsLock();
  const watchValues = useWatch({
    control,
  });

  const { confirmPassword, password } = watchValues;

  const [timerId, setTimerId] = useState(null);

  const validatePassword = () => {
    setPasswordMatch(password === confirmPassword);
  };

  const debounceValidate = () => {
    clearTimeout(timerId);
    setTimerId(setTimeout(validatePassword, 2000));
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, [timerId]);

  useEffect(() => {
    debounceValidate();
  }, [password, confirmPassword]);

  return (
    <>
      <FormContainer
        label="비밀번호 확인"
        error={isPasswordMatch === false && '비밀번호가 일치하지 않습니다. 다시 입력해주세요.'}
      >
        <TextField
          {...register('confirmPassword', {
            ...(pageFrom !== 'editUser' && { required: '필수 입력란입니다.' }),
          })}
          placeholder="비밀번호를 입력해주세요."
          type="password"
          onChange={e => {
            debounceValidate();
            setValue('confirmPassword', e.target.value);
          }}
          sx={{
            '.MuiInputBase-input': {
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
                md: '1rem',
              },
            },
          }}
        />
        {capsLockOn && <CapsLockIndicator />}
      </FormContainer>
    </>
  );
}
