import { Button, Typography, useMediaQuery } from '@mui/material';
import { FILTER_ORANGE, MAIN_ORANGE } from '../constant/colors';
import { useTheme } from '@emotion/react';

export default function CustomButton({ buttonHandler, text, isPrimaryButtonOrange, marginRight }) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Button
      sx={{
        backgroundColor: isPrimaryButtonOrange ? 'white' : MAIN_ORANGE,
        border: '1px solid',
        marginRight: marginRight ? 1 : 'none',
        '&:hover': {
          backgroundColor: isPrimaryButtonOrange ? 'none' : FILTER_ORANGE,
        },
      }}
      onClick={() => buttonHandler()}
    >
      <Typography
        color={isPrimaryButtonOrange ? '#F99B20' : 'white'}
        sx={{ fontSize: isMdScreen && '0.7rem' }}
      >
        {text}
      </Typography>
    </Button>
  );
}
