import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { userApi } from '../../api/api';
import { styled } from 'styled-components';

export function CanEditablePlaceListModal({ navigate, isVisibleEditList, setIsVisibleEditList }) {
  const [keyword, setKeyword] = useState('');
  const keywordRef = useRef('');
  const nextPageRef = useRef(null);

  const getCanEditablePlaceList = async pageParam => {
    if (pageParam === 1) {
      try {
        const response = await userApi.get('v1/place-information-provider/themes/108/places', {
          params: {
            page: pageParam,
            keyword,
          },
        });
        return response.data;
      } catch (error) {
        throw new Error();
      }
    } else {
      try {
        const response = await userApi.get(pageParam);
        return response.data;
      } catch (error) {
        throw new Error();
      }
    }
  };

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['themePlace', keyword],
    queryFn: ({ pageParam = 1 }) => getCanEditablePlaceList(pageParam),
    getNextPageParam: lastPage => {
      return lastPage?.next;
    },
  });

  const onClickEditListItem = useCallback(async item => {
    const itemOptions = {
      name: item.name,
      value: item.id,
      babyPass: item.hasBabypassCertification,
      pending: item.hasPendingProposal,
      ownerPlace: item.isOwnerPlace,
    };

    if (itemOptions.babyPass) {
      alert('애가패스 전용 장소입니다. 해당 장소는 수정하실 수 없습니다.');
    } else if (itemOptions.ownerPlace || itemOptions.name === '애기야가자') {
      alert('사업주가 관리하는 장소입니다. 해당 장소는 수정하실 수 없습니다.');
    } else if (itemOptions.pending) {
      alert('검토 중인 장소입니다. 해당 장소는 관리자의 검토가 진행 중인 장소입니다.');
    } else {
      setTimeout(() => {
        navigate('/searchplace/' + itemOptions.value);
      }, 100);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 0.5 },
    );

    if (nextPageRef.current) {
      observer.observe(nextPageRef.current);
    }

    return () => {
      if (nextPageRef.current) {
        observer.unobserve(nextPageRef.current);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  return (
    <EditListModal
      style={{ display: isVisibleEditList ? 'flex' : 'none' }}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setIsVisibleEditList(false);
        }
      }}
    >
      <EditListModalInner>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', maxWidth: '320px', alignItems: 'center' }}>
            <input
              style={{ padding: '0.5rem', border: '1px solid lightgray' }}
              placeholder="검색어를 입력해주세요."
              defaultValue={keywordRef.current}
              onChange={e => {
                keywordRef.current = e.target.value;
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  setKeyword(keywordRef.current);
                }
              }}
            />
            <div style={{ width: '1em' }} />
            <EditListCloseButton onClick={() => setKeyword(keywordRef.current)}>
              검색
            </EditListCloseButton>
          </div>
          <EditListCloseButton onClick={() => setIsVisibleEditList(false)}>
            닫기
          </EditListCloseButton>
        </div>
        <div style={{ marginTop: '12px' }} />
        <ListContianer>
          <div>
            {data?.pages.length > 0 &&
              data.pages
                .map(page => page.places)
                .flat()
                ?.map((item, index) => (
                  <EditListItem key={index} item={item} onClickEditListItem={onClickEditListItem} />
                ))}
            <div ref={nextPageRef} style={{ height: 100 }} />
          </div>
        </ListContianer>
      </EditListModalInner>
    </EditListModal>
  );
}

class EditListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { item } = this.props;
    const { isHover } = this.state;
    return isHover !== nextState.isHover || item !== nextProps.item;
  }

  onClick = () => {
    const { item, onClickEditListItem } = this.props;
    onClickEditListItem(item);
  };

  render() {
    const { item } = this.props;
    const { isHover } = this.state;
    return (
      <div
        style={{
          ...editListItemStyle,
          ...(isHover && { backgroundColor: '#f2f2f2' }),
        }}
        onMouseOver={e => {
          e.preventDefault();
          this.setState({
            isHover: true,
          });
        }}
        onMouseLeave={e => {
          e.preventDefault();
          this.setState({
            isHover: false,
          });
        }}
        onClick={this.onClick}
      >
        <div style={{ display: 'flex' }}>
          <figure
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '5px',
              marginRight: '1rem',
              marginLeft: '1rem',
              backgroundColor: 'lightgray',
            }}
          >
            {item.thumbnail && (
              <img
                src={item.thumbnail}
                alt="썸네일"
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '5px',
                }}
              />
            )}
          </figure>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span>{item.name}</span>
            <div style={{ marginTop: '5px' }} />
            <span style={{ color: 'gray' }}>{item.address}</span>
          </div>
        </div>
        {isHover &&
          !(item.hasPendingProposal || item.isOwnerPlace || item.hasBabypassCertification) && (
            <div
              style={{
                position: 'absolute',
                right: '1.5rem',
                backgroundColor: '#FBAF17',
                color: 'white',
                padding: '0.5rem',
                fontSize: '0.8rem',
                borderRadius: '5px',
              }}
            >
              수정하기
            </div>
          )}
        {(item.hasPendingProposal || item.isOwnerPlace || item.hasBabypassCertification) && (
          <div
            style={{
              position: 'absolute',
              width: '98%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'not-allowed',
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            관리자가 검토중인 장소입니다.
          </div>
        )}
      </div>
    );
  }
}

const EditListModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const EditListModalInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  max-width: 850px;

  height: 80vh;
`;

const EditListCloseButton = styled.div`
  display: flex;
  font-size: 1rem;
  background-color: lightgray;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
`;

const ListContianer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow-y: auto;
`;

const editListItemStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem',
  // paddingLeft: '1.5rem',
  width: '100%',
  borderRadius: '5px',
  fontSize: '0.85rem',
  marginTop: '5px',
  cursor: 'pointer',
};
