import React from 'react';
import { Typography, Grid, Paper, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { calculateFontSize } from '../../DashBoard/UserDashBoard/DashBoard';

const commonGridStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const AdminDashBoardCountContainer = ({ proposalStatus }) => {
  const totalCount = Object.values(proposalStatus || {}).reduce((a, b) => a + b, 0);
  const navigate = useNavigate();

  const adminCountData = [
    { count: totalCount, label: '총 제안', value: 'all' },
    { count: proposalStatus?.pendingCount, label: '승인대기', value: 'pending' },
    { count: proposalStatus?.approvedCount, label: '승인', value: 'completed' },
    { count: proposalStatus?.holdCount, label: '보류', value: 'completed' },
    { count: proposalStatus?.rejectedCount, label: '반려', value: 'completed' },
  ];

  const handleNavigateProposed = (value, label) => {
    navigate(`/admin/placeconfirm?label=${label}&value=${value}`);
  };

  return (
    <Paper
      sx={{
        paddingTop: '2.5vw',
        paddingBottom: '2.5vw',
        paddingLeft: '4vw',
        paddingRight: '4vw',
        marginBottom: '2.5vw',
      }}
      elevation={3}
    >
      <Grid container alignItems="center" minHeight={96}>
        {adminCountData.map(({ count, label, value }, index) => (
          <Grid
            onClick={() => handleNavigateProposed(value, label)}
            item
            xs={2.4}
            sx={commonGridStyles}
            key={`dashboard-item-${index}`}
          >
            <Typography sx={{ fontSize: calculateFontSize(true), fontWeight: 600 }}>
              {count || 0}
            </Typography>
            <Typography sx={{ fontSize: calculateFontSize() }}>{label}</Typography>
            {index !== 3 && (
              <Divider orientation="vertical" flexItem sx={{ height: '100%', width: '2px' }} />
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default AdminDashBoardCountContainer;
