import { Typography } from '@mui/material';
import { MAIN_ORANGE } from '../../../constant/colors';
import React from 'react';

export default function Preview({ setOpenPreviewModal }) {
  return (
    <Typography
      sx={{
        color: MAIN_ORANGE,
        cursor: 'pointer',
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
        marginBottom: '0.5rem',
      }}
      onClick={() => setOpenPreviewModal(true)}
    >
      미리보기
    </Typography>
  );
}
