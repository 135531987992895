import { Button, Divider, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useFieldArray, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import API_URL from '../../../api/urls';
import SVGRenderer from '../../../utils/SVGrenderer';
import { MAIN_ORANGE, FILTER_ORANGE, ERROR_RED } from '../../../constant/colors';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { calculateFontSize } from '../../../pages/DashBoard/UserDashBoard/DashBoard';

import heic2any from 'heic2any';

/**
 * 장소 사진 컴포넌트
 * @param errors
 * @param control
 * @param comparisonOriginalData
 * @param changedProperty
 * @param pageFrom { 'readonly' | 'addPlace' | 'comparison' | 'editPlace' }
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */

function Images({ setModalProp, control, errors, changedProperty, pageFrom }) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isWarnImage, setIsWarnImage] = useState(false);

  const watchImages = useWatch({
    name: 'images',
    control,
  });

  /**
   * 이미지 이름에 download 또는 screenshot이 포함되어 있는지 체크
   */
  const checkWarnImage = useCallback(images => {
    if (
      images.some(image => image.name.includes('download') || image.name.includes('screenshot'))
    ) {
      setIsWarnImage(true);
    }
  }, []);

  const {
    fields: imagesFields,
    append: appendImages,
    remove: removeImages,
    prepend: prependImages,
  } = useFieldArray({
    control,
    name: 'images',
  });

  const imageUploadFailedModal = () => {
    setModalProp({
      text: '이미지 첨부에 실패하였습니다. 다시 시도해 주세요.',
      buttonText: '확인',
      onPress: () => setModalProp(null),
    });
  };

  const handleFileUpload = async e => {
    if (watchImages?.length >= 9) {
      setModalProp({
        text: '장소 사진은 총 9장 첨부 가능합니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    if (!e.target.files) {
      return;
    }
    let images = [];
    if (watchImages?.length + e.target.files.length > 9) {
      setModalProp({
        text: '장소 사진은 총 9장 첨부 가능합니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      for (let i = 0; i < 9 - watchImages?.length; i++) {
        images.push(e.target.files[i]);
      }
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(e.target.files[i]);
      }
    }

    checkWarnImage(images);

    images.forEach(async image => {
      const fileType = image.type;
      if (fileType === 'image/heic' || fileType === 'image/HEIC') {
        image = new File(
          [await heic2any({ blob: image, toType: 'image/jpeg' })],
          image.name.split('.')[0] + '.jpg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }
      try {
        const presignedResponse = await axios.post(`${API_URL}/api/v1/image-preupload`, {
          'Content-Type': fileType,
          path: 'place-add',
        });

        const reader = new FileReader();
        reader.readAsArrayBuffer(image);

        try {
          await axios.put(
            `${presignedResponse.data.presigned_url}`,
            image,

            {
              headers: {
                'x-amz-acl': 'public-read',
                'Content-Type': fileType,
              },
              withCredentials: true,
            },
          );

          appendImages({
            url: presignedResponse?.data?.presigned_url?.split('?')[0],
            season: '사계절',
          });
        } catch (error) {
          console.log('error', error);
          imageUploadFailedModal();
        }
        reader.readAsArrayBuffer(image);
      } catch (error) {
        console.log('error', error);
        imageUploadFailedModal();
      }
    });
  };

  const handleDragFileUpload = async e => {
    if (!e.dataTransfer.files) {
      return;
    }
    let images = [];
    if (watchImages?.length + e.dataTransfer.files.length > 9) {
      setModalProp({
        text: '장소 사진은 총 9장 첨부 가능합니다.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      for (let i = 0; i < 9 - watchImages?.length; i++) {
        images.push(e.dataTransfer.files[i]);
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        images.push(e.dataTransfer.files[i]);
      }
    }

    checkWarnImage(images);

    images.forEach(async image => {
      const fileType = image.type;
      if (fileType === 'image/heic' || fileType === 'image/HEIC') {
        image = new File(
          [await heic2any({ blob: image, toType: 'image/jpeg' })],
          image.name.split('.')[0] + '.jpg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      try {
        const presignedResponse = await axios.post(`${API_URL}/api/v1/image-preupload`, {
          'Content-Type': fileType,
          path: 'place-add',
        });
        const reader = new FileReader();
        reader.readAsArrayBuffer(image);

        try {
          await axios.put(`${presignedResponse.data.presigned_url}`, image, {
            headers: {
              'x-amz-acl': 'public-read',
              'Content-Type': fileType,
            },
            withCredentials: true,
          });
          appendImages({
            url: presignedResponse?.data?.presigned_url?.split('?')[0],
            season: '사계절',
          });
        } catch (error) {
          imageUploadFailedModal();
        }
        reader.readAsArrayBuffer(image);
      } catch (error) {
        imageUploadFailedModal();
      }
    });
  };

  const handleDeleteImage = (e, index) => {
    e.preventDefault();
    removeImages(index);
  };

  const imageToForward = (targetImage, targetindex) => {
    removeImages(targetindex);
    prependImages(targetImage);
  };

  return (
    <FileDropComponent handleDragFileUpload={handleDragFileUpload}>
      <MainContainer>
        <SeasonDiv style={{ display: 'flex', flex: 1 }}>
          <Typography
            sx={{
              fontSize: isMdScreen && '0.8rem',
              color:
                (pageFrom === 'editPlace' || pageFrom === 'comparison') && changedProperty
                  ? ERROR_RED
                  : null,
              width: '100px',
            }}
          >
            {/* 장소 사진 */}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', flex: 1 }}>
            사진은 총 9장 첨부가능하며 JPG, JPEG, PNG 파일만 등록이 가능합니다.
            <br />
            파일을 드래그해서 추가하거나, 사진 추가 버튼을 눌러주세요.
          </Typography>
        </SeasonDiv>
        <SeasonImagesComp
          title="장소사진"
          isMdScreen={isMdScreen}
          imagesFields={watchImages}
          handleFileUpload={handleFileUpload}
          handleDeleteImage={handleDeleteImage}
          imageToForward={imageToForward}
        />
        <Typography sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
          {isWarnImage
            ? '직접 찍은 사진 혹은 공식 홈페이지에서 다운로드 받은 파일이 아닌 저작권에 위배된 이미지일 경우 반려될 수 있습니다!'
            : null}
        </Typography>
        <Typography sx={{ color: ' #E54D4D', fontSize: calculateFontSize() }}>
          {errors?.images?.message || errors?.images?.message}
        </Typography>
        <Divider variant="middle" />
      </MainContainer>
    </FileDropComponent>
  );
}
export default Images;

function FileDropComponent({ children, handleDragFileUpload }) {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = e => {
    e.preventDefault();
    if (!dragging) {
      setDragging(true);
    }
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    handleDragFileUpload(e);
    setDragging(false);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        display: 'flex',
        // border: '1px solid black',
        background: dragging ? 'lightgrey' : '',
      }}
    >
      {children}
    </div>
  );
}

const SeasonImagesComp = ({
  title,
  subTitle,
  isMdScreen,
  handleFileUpload,
  imagesFields,
  handleDeleteImage,
  imageToForward,
}) => {
  const [seasonImages, setSeasonImages] = useState([]);

  useEffect(() => {
    setSeasonImages(imagesFields);
  }, [imagesFields]);

  return (
    <SeasonDiv>
      <SeasonText Text={title} subText={subTitle} isMdScreen={isMdScreen} />
      <ImageButtonContainerDiv>
        <ImageRegisterButton
          isMdScreen={isMdScreen}
          handleFileUpload={e => handleFileUpload(e, title)}
        />
        <ImageContainerDiv>
          {seasonImages &&
            seasonImages.map((item, index) => (
              <ImageContainer key={index}>
                <img
                  src={item.url}
                  alt={`thumbnail-${index}`}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
                <RepresentImageBtn
                  style={{ backgroundColor: index === 0 ? MAIN_ORANGE : 'lightgray' }}
                  onClick={() => {
                    imageToForward(item, index);
                  }}
                >
                  대표
                </RepresentImageBtn>

                <RemoveImageButton onClick={e => handleDeleteImage(e, index)} type="button">
                  <SVGRenderer type="delete" />
                </RemoveImageButton>
              </ImageContainer>
            ))}
        </ImageContainerDiv>
      </ImageButtonContainerDiv>
    </SeasonDiv>
  );
};

const RepresentImageBtn = styled.div`
  display: flex;
  position: absolute;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: lightgray;
  border-radius: 20px;
  top: 18px;
  left: 8px;
  color: white;
  font-size: 0.7rem;

  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const SeasonDiv = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const ImageButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainerDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  flex: 1;
`;

const ImageContainer = styled.div`
  position: relative;
  padding-right: 0.5rem;
  padding-bottom: 12px;
  padding-top: 12px;
`;

const SeasonText = ({ Text, subText, isMdScreen }) => (
  <SeasonTextDiv>
    <Typography
      sx={{
        fontSize: isMdScreen && '0.8rem',
        width: '100px',
      }}
    >
      {Text}*
    </Typography>
    <Typography
      sx={{
        fontSize: '12px',
        color: '#828282',
        width: '100px',
      }}
    >
      {subText}
    </Typography>
  </SeasonTextDiv>
);

const SeasonTextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageRegisterButton = ({ isMdScreen, handleFileUpload }) => {
  return (
    <Button
      component="label"
      sx={{
        background: MAIN_ORANGE,
        '&:hover': {
          backgroundColor: FILTER_ORANGE,
        },
        color: 'white',
        width: '90px',
        height: '40px',
      }}
    >
      <Typography
        sx={{
          fontSize: isMdScreen ? '0.7rem' : '0.8rem',
        }}
      >
        사진 추가
      </Typography>
      <input
        type="file"
        hidden
        onChange={e => handleFileUpload(e)}
        accept=".jpg,.jpeg,.png,.heic,.HEIC"
        multiple="multiple"
      />
    </Button>
  );
};

const RemoveImageButton = styled.button`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 18px;
  right: 13px;
`;
