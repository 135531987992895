import { useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalProps } from '../recoilAtom/recoilAtom';

const usePreventBack = ({ openPreviewModal, setOpenPreviewModal }) => {
  const setModalProp = useSetRecoilState(modalProps);

  const ignorePopStateRef = useRef();
  const closeModalShowCount = useRef(-1);

  useEffect(() => {
    ignorePopStateRef.current = openPreviewModal;
  }, [openPreviewModal]);

  const preventGoBack = useCallback(event => {
    window.history.pushState(null, '', window.location.href);
    if (ignorePopStateRef.current) {
      setOpenPreviewModal(false);
    } else {
      showModal();
      closeModalShowCount.current = closeModalShowCount.current - 1;
    }
  }, []);

  const showModal = () => {
    setModalProp({
      titleText: '제안서 작성 취소',
      text: '현재 작성 중인 제안서가 삭제됩니다.',
      text2: '작성을 취소하시겠습니까?',
      isSelect: true,
      buttonText: '작성 취소',
      buttonText2: '닫기',
      onPress: () => {
        window.history.go(closeModalShowCount.current);
        setModalProp(null);
      },
      onPress2: () => {
        setModalProp(null);
        window.history.pushState(null, '', window.location.href);
      },
    });
  };

  return {
    preventGoBack,
  };
};

export default usePreventBack;
