import {
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@mui/material';
import useDebounce from '../../../hooks/useDebounce';
import { Controller } from 'react-hook-form';
import { useTheme } from '@emotion/react';

export default function AccountNumber({
  register,
  setValue,
  control,
  pageFrom,
  existingAccountNumber,
}) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const validateAccountNumberCheck = async e => {
    if (e.target.value.length === 0) {
      return '필수 입력란입니다.';
    }
  };

  const [accountNumberError, debounceAccountNumber] = useDebounce(validateAccountNumberCheck, 2000);

  const bankList = [
    '국민은행',
    '기업은행',
    '농협은행',
    '신한은행',
    '하나은행',
    '우리은행',
    '한국씨티은행',
    'SC제일은행',
    '경남은행',
    '광주은행',
    '대구은행',
    '도이치은행',
    '부산은행',
    '비엔피파리바은행',
    '산림조합',
    '산업은행',
    '새마을금고',
    '수출입은행',
    '수협은행',
    '신협',
    '우체국',
    '저축은행',
    '전북은행',
    '제주은행',
    '중국건설은행',
    '중국공상은행',
    '중국은행',
    '지역농축협',
    '카카오뱅크',
    '케이뱅크',
    '토스뱅크',
  ];

  return (
    <Grid
      container
      direction="column"
      alignItems="left"
      minHeight={129}
      sx={{ paddingBottom: '37px' }}
    >
      <Typography mb={1}>계좌번호</Typography>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={isMdScreen ? 5 : 3}>
          <FormControl fullWidth>
            <Controller
              name="bank"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  value={(!field.value && 'none') || field.value}
                  sx={{
                    '.MuiInputBase-input': {
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem',
                      },
                      minHeight: 0,
                      height: '1.4375em',
                      display: 'flex',
                      alignItems: 'center',
                    },
                    minHeight: 0,
                  }}
                >
                  <MenuItem value="none" disabled>
                    입금은행
                  </MenuItem>
                  {bankList.map((bank, index) => (
                    <MenuItem key={index} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMdScreen ? 7 : 9}>
          <TextField
            fullWidth
            {...register('accountNumber', {
              ...(pageFrom !== 'editUser' && { required: '필수 입력란입니다.' }),
            })}
            placeholder={existingAccountNumber ? existingAccountNumber : '계좌번호를 입력해주세요.'}
            type="text"
            onChange={e => {
              debounceAccountNumber(e);
              setValue('accountNumber', e.target.value);
            }}
            sx={{
              '.MuiInputBase-input': {
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.875rem',
                  md: '1rem',
                },
                height: 'auto',
              },
            }}
          />
        </Grid>
      </Grid>
      <Typography mt={0.5} sx={{ color: ' #E54D4D' }}>
        {accountNumberError && accountNumberError}
      </Typography>
    </Grid>
  );
}
