import { TextField } from '@mui/material';
import FormContainer from './FormContainer';
import { userApi } from '../../../api/api';
import API_URL from '../../../api/urls';
import useDebounce from '../../../hooks/useDebounce';

export default function NickName({ register, setValue }) {
  const validateNicknameCheck = async e => {
    if (e.target.value.length === 0) {
      return '필수 입력란입니다.';
    }

    try {
      await userApi.get(
        `${API_URL}/api/v1/place-information-provider/sign-up/check/nickname?nickname=${e.target.value}`,
      );
    } catch (error) {
      const errorMessageJson = error.response.data;
      if (
        errorMessageJson.code === 'DuplicateNickname' ||
        errorMessageJson.code === 'InvalidParameter'
      ) {
        return errorMessageJson.message;
      }
    }
  };

  const [nickNameCheckError, debounceNickname] = useDebounce(validateNicknameCheck, 2000);

  return (
    <FormContainer label="앱 닉네임" error={nickNameCheckError}>
      <TextField
        {...register('nickname', { required: '필수 입력란입니다.' })}
        placeholder="사용하실 닉네임을 입력해주세요."
        type="text"
        onChange={e => {
          debounceNickname(e);
          setValue('nickname', e.target.value);
        }}
        sx={{
          '.MuiInputBase-input': {
            fontSize: {
              xs: '0.75rem',
              sm: '0.875rem',
              md: '1rem',
            },
          },
        }}
      />
    </FormContainer>
  );
}
