import React, { useState } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../../../api/api';
import styled from 'styled-components';
import StyledButton from '../../../components/StyledButton';
import Container from '../../../components/Container';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MAIN_ORANGE } from '../../../constant/colors';

const ProposedItem = ({
  index,
  item,
  data,
  isMdScreen,
  setModalProp,
  handleNavigate,
  queryClient,
}) => {
  const navigate = useNavigate();
  const navigateToEditPage = id => {
    navigate(id, { state: { editable: true } });
  };

  const handleNavigateList = () => {
    navigate('/propsedplace');
    setModalProp(null);
  };

  const notifyDelete = () => {
    setModalProp({
      titleText: '제안서 삭제',
      text: '승인 대기 중인 제안서가 삭제 됩니다.',
      text2: '정말 삭제하시겠습니까?',
      isSelect: true,
      buttonText: '취소',
      buttonText2: '삭제',
      onPress: () => {
        setModalProp(null);
      },
      onPress2: () => {
        sendDeleteRequest();
      },
    });
  };

  const sendDeleteRequest = async () => {
    try {
      const response = await userApi.delete(
        `api/v1/place-information-provider/me/proposals/${item.id}`,
      );
      setModalProp(null);
      if (response?.data?.code === 'ok') {
        setModalProp({
          titleText: '제안서 삭제가 완료되었습니다',
          buttonText: '닫기',
          onPress: () => {
            queryClient.invalidateQueries(['readProposedPlace']);
            setModalProp(null);
          },
        });
      }
    } catch (error) {
      const code = error?.response?.data?.code;
      if (code === 'NotAuthorized') {
        setModalProp({
          titleText: '로그인 정보가 만료 되었습니다.',
          text: '다시 로그인 해 주세요.',
          buttonText: '확인',
          onPress: () => handleNavigate(),
        });
      } else if (code === 'DoesNotExist') {
        setModalProp({
          titleText: '존재하지 않는 제안서 입니다.',
          text: '제안서 목록으로 이동합니다.',
          buttonText: '확인',
          onPress: () => handleNavigateList(),
        });
      } else if (code === 'DeletePermissionError') {
        setModalProp({
          titleText: '잘못된 접근입니다.',
          text: '제안서 목록으로 이동합니다.',
          buttonText: '확인',
          onPress: () => handleNavigateList(),
        });
      } else {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
      }
    }
  };

  const navigateToProposal = () => {
    navigate(`${item.id}`, { state: { item: item } });
  };

  const [showButton, setShowButton] = useState(false);

  const onClickHamburger = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowButton(!showButton);
  };

  return (
    <HoverItemDiv
      key={item.id}
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottom: '1.2px solid #BBB',
        alignItems: 'center',
        height: '47px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        item
        xs={2.5}
        paddingY={2}
        justifyContent="center"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 2.5 }}
        onClick={() => navigateToProposal()}
      >
        {item.createdAt && (
          <Typography sx={{ fontSize: isMdScreen && '0.7rem' }}>
            {dayjs(item.createdAt).format('YY-MM-DD')}
          </Typography>
        )}
      </div>
      <div
        item
        xs={6.5}
        paddingY={2}
        paddingX={1}
        sx={{ display: 'flex' }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 6.5 }}
        onClick={() => navigateToProposal()}
      >
        <Typography
          sx={{
            fontSize: isMdScreen && '0.7rem',
            wordBreak: 'break-all',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '37vw',
            '&:hover': {
              color: MAIN_ORANGE,
              borderBottomColor: MAIN_ORANGE,
            },
            borderBottom: '1px solid #767676',
            cursor: 'pointer',
          }}
        >
          {item.name}
        </Typography>
      </div>
      <div
        item
        xs={2}
        paddingY={2}
        sx={{ display: 'flex' }}
        justifyContent="center"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 2 }}
        onClick={() => navigateToProposal()}
      >
        <Typography
          sx={{
            fontSize: isMdScreen && '0.7rem',
            color: theme => {
              switch (item.result) {
                case '승인대기':
                  return '#6B6B6B';
                case '반려':
                  return '#E84444';
                case '보류':
                  return '#FFA800';
                case '승인':
                  return '#0CAA09';
                default:
                  return 'none';
              }
            },
          }}
        >
          {!showButton && item.result}
        </Typography>
        <Divider orientation="horizontal" sx={{ width: '1px', height: '40%' }} />
      </div>
      {item.result === '승인대기' ? (
        <>
          <div
            onClick={onClickHamburger}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <MoreVertIcon sx={{ color: '#6b6b6b' }} />
          </div>
          <ButtonAnimationContainer showButton={showButton}>
            <StyledButton
              onClick={() => {
                navigateToEditPage(item.id);
              }}
              style={{
                padding: '7px 15px',
                marginRight: '5px',
                width: '100%',
              }}
            >
              <ButtonText>수정</ButtonText>
            </StyledButton>
            <StyledButton
              isRejectButton
              onClick={() => notifyDelete()}
              style={{ padding: '7px 15px', marginRight: '5px', width: '100%' }}
            >
              <ButtonText>삭제</ButtonText>
            </StyledButton>
          </ButtonAnimationContainer>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }} />
      )}
    </HoverItemDiv>
  );
};

export default ProposedItem;

const ButtonText = styled.p`
  font-size: 0.75rem;
`;

const HoverItemDiv = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;

const ButtonAnimationContainer = styled.div`
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 7vw;

  transform: ${({ showButton }) => (showButton ? 'translateX(0)' : `translateX(300%)`)};
  transition: transform 0.3s ease-in-out;
`;
