import { Box } from '@mui/material';
import React from 'react';

/**
 * @param pageFrom { 'settlementRequest' }
 * @returns {JSX.Element}
 */

export default function DataContainer({ children, pageFrom }) {
  return (
    <Box
      sx={{
        minHeight: '30vh',
        overflow: pageFrom === 'settlementRequest' ? 'auto' : 'visible',
      }}
    >
      {children}
    </Box>
  );
}
