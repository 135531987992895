import React from 'react';
import SVGRenderer from '../utils/SVGrenderer';
import { Typography, Grid, Box, Avatar } from '@mui/material';
import { styled } from 'styled-components';

const Header = ({
  isSmallScreen,
  titleText,
  isGreeting,
  subTextFirstLine,
  subTextSecondLine,
  isLoginPage,
  navigateToDashBoard,
}) => {
  return (
    <HeaderContainer isSmallScreen={isSmallScreen}>
      <HeaderTitleContainer>
        <Typography
          mb={1}
          sx={{
            fontSize: isSmallScreen ? '1.3rem' : '2rem',
            fontWeight: 600,
          }}
          variant={isSmallScreen ? 'h4' : 'h3'}
        >
          {titleText}
        </Typography>
        {isGreeting ? (
          <Typography
            mb={1}
            sx={{
              fontSize: isSmallScreen ? '1.0rem' : '2rem',
              fontWeight: 600,
            }}
            variant={isSmallScreen ? 'h4' : 'h3'}
          >
            환영합니다
          </Typography>
        ) : (
          <>
            <Typography style={{ lineHeight: 1.1, fontSize: isSmallScreen ? '0.9rem' : '1.2rem' }}>
              {subTextFirstLine}
            </Typography>
            <Typography style={{ lineHeight: 1.1, fontSize: isSmallScreen ? '0.9rem' : '1.2rem' }}>
              {subTextSecondLine}
            </Typography>
          </>
        )}
      </HeaderTitleContainer>
      <Grid>
        {isLoginPage ? (
          <Box
            sx={{
              width: '100px',
              height: '100px',
            }}
          />
        ) : (
          <Avatar
            sx={{
              bgcolor: 'white',
              width: isSmallScreen ? '60px' : '100px',
              height: isSmallScreen ? '60px' : '100px',
              position: 'relative',
              top: '-10px',
              cursor: 'pointer',
            }}
            onClick={navigateToDashBoard}
          >
            <SVGRenderer type="logo" width={isSmallScreen ? '50' : '100'} />
          </Avatar>
        )}
      </Grid>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 6vw;
  padding-left: ${props => (props.isSmallScreen ? '6vw' : '0vw')};
  padding-right: ${props => (props.isSmallScreen ? '6vw' : '0vw')};
  padding-bottom: 12px;
  color: white;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
