import { styled } from 'styled-components';
import { MAIN_ORANGE } from '../constant/colors';

const TextArea = styled.textarea`
  width: 100%;
  padding: 16.5px 14px;
  border: 1.5px solid #d3d3d3;
  box-sizing: border-box;
  background: none;
  resize: none;
  display: block;
  border-radius: 3px;
  padding: 20px;
  line-height: 1.4;
  color: ${props => props.color || '#000'};
  &:focus {
    border-color: ${MAIN_ORANGE};
    border-width: 1.5px;
    outline: none;
  }
  font-family: 'GmarketSansMedium', sans-serif;
`;

export default TextArea;
