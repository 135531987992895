import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import API_URL from '../../../api/urls';
import { userApi } from '../../../api/api';
import useRedirect from '../../../hooks/useRedirect';
import Header from '../../../components/Header';
import DataContainer from '../../../components/DataList/components/DataContainer';
import DataContainerWrapper from '../../../components/DataList/components/DataContainerWrapper';
import DataHeader from '../../../components/DataList/components/DataHeader';
import DataItem from '../../../components/DataList/components/DataItem';
import PaginationButton from '../../../components/DataList/components/PaginationButton';
import getMaxPage from '../../../hooks/getMaxPage';

const SettlementResult = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const [maxPage, setMaxPage] = useState(1);

  useRedirect();

  const fetchSettlementResult = async ({ queryKey }) => {
    const [_, currentPage] = queryKey;
    try {
      const response = await userApi.get(
        `${API_URL}/api/v1/place-information-provider/me/adjustment-requests?page=${currentPage}`,
      );
      getMaxPage(response.data?.adjustmentCount, setMaxPage, 15);
      return response.data;
    } catch (error) {}
  };

  const { isLoading, data } = useQuery(
    ['fetchSettlementResult', currentPage],
    fetchSettlementResult,
  );
  const settlementResult = data && data.adjustments;

  const headerList = [
    { label: '신청일자', grid: 2.5, key: 'createdAt' },
    { label: '처리일자', grid: 2.5, key: 'proceededAt' },
    { label: '신청내역', grid: 4, key: 'receipt' },
    { label: '금액', grid: 2, key: 'totalAmount' },
    { label: '상태', grid: 1, key: 'statusDisplay' },
  ];

  return (
    <>
      <Container
        style={{
          maxWidth: '960px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        sx={{ mb: 4 }}
      >
        <Header
          isSmallScreen={isSmallScreen}
          isGreeting={false}
          titleText="정산 처리 결과"
          subTextFirstLine="요청 및 승인된 정산 내역을"
          subTextSecondLine="확인할 수 있습니다."
          navigateToDashBoard={() => navigate('/dashboard')}
        />
        <DataContainerWrapper
          pageFrom="settlementResult"
          isMdScreen={isMdScreen}
          isLargeScreen={isLargeScreen}
        >
          <DataContainer>
            <DataHeader headerList={headerList} fontSize={isMdScreen && '0.53rem'} />
            {!isLoading && (
              <Grid container>
                {settlementResult?.length ? (
                  <>
                    {settlementResult?.map((result, index) => (
                      <DataItem
                        headerList={headerList}
                        resultList={settlementResult}
                        result={result}
                        status={result.status}
                        key={index}
                        index={index}
                        fontSize={isMdScreen && '0.5rem'}
                        pageFrom="settlementResult"
                      />
                    ))}
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: '30vh', color: '#5E5E5E' }}
                  >
                    <Grid item>정산 내역이 없습니다.</Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </DataContainer>

          <PaginationButton maxPage={maxPage} page={currentPage} setCurrentPage={setCurrentPage} />
        </DataContainerWrapper>
      </Container>
    </>
  );
};

export default SettlementResult;
