import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Grid, Button, Paper, useMediaQuery } from '@mui/material';
import API_URL from '../../api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { MAIN_ORANGE, FILTER_ORANGE } from '../../constant/colors';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from 'styled-components';
import { useTheme } from '@emotion/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { modalProps, userInfo } from '../../recoilAtom/recoilAtom';
import { CURRENT_TEST_ID } from '../DashBoard/UserDashBoard/DashBoard';

const SearchMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [query, setQuery] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [noData, setNoData] = useState(false);
  const setModalProp = useSetRecoilState(modalProps);

  const [userInformation, setUserInformation] = useRecoilState(userInfo);

  useEffect(() => {
    if (location.hash) {
      const query = decodeURIComponent(location.hash.split('#')[1] || '');
      setQuery(decodeURIComponent(query));
      loadOptions(query);
    }
  }, [location, navigate]);

  const setHash = () => {
    navigate('/dashboard');
    navigate('/searchplace#' + query);
  };

  const loadOptions = async searchText => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/place-information-provider/places/search?keyword=${searchText}`,
      );
      const options = response.data.map(item => ({
        label: item.name,
        value: item.id,
        babyPass: item.has_babypass_category,
        pending: item.has_pending_proposal,
        ownerPlace: item.is_owner_place,
      }));
      setSearchData(options);

      if (options.length === 0) {
        setNoData(true);
      }
    } catch (error) {}
  };

  const handleChange = selectedOption => {
    if (selectedOption?.babyPass) {
      setModalProp({
        titleText: '애가패스 전용 장소입니다',
        text: '해당 장소는 수정하실 수 없습니다.',
        text2: '다른 장소를 선택해 주세요.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    if (selectedOption?.ownerPlace && selectedOption?.label !== '애기야가자') {
      setModalProp({
        titleText: '사업주가 관리하는 장소입니다',
        text: '해당 장소는 수정하실 수 없습니다.',
        text2: '다른 장소를 선택해 주세요.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    if (selectedOption?.pending) {
      setModalProp({
        titleText: '검토 중인 장소입니다',
        text: '해당 장소는 관리자의 검토가 진행 중인 장소입니다.',
        text2: '다른 장소를 선택해 주세요.',
        buttonText: '확인',
        onPress: () => setModalProp(null),
      });
      return;
    }

    navigate(`${selectedOption.value}`, {
      state: {
        placeData: selectedOption && { id: selectedOption.value, name: selectedOption.label },
      },
    });
  };

  if (userInformation.id === CURRENT_TEST_ID) {
    return (
      <Paper elevation={3}>
        <SearchWrapperContainer isMdScreen={isMdScreen}>
          <Button
            sx={{
              width: '100%',
              height: '8rem',
              border: '1px solid',
              color: 'white',
              fontSize: '1.5rem',
              backgroundColor: MAIN_ORANGE,
              '&:hover': {
                backgroundColor: FILTER_ORANGE,
              },
            }}
            onClick={() => navigate('/addplace')}
          >
            신규 장소 테스트 등록
          </Button>
        </SearchWrapperContainer>
      </Paper>
    );
  }

  return (
    <Paper elevation={3}>
      <SearchWrapperContainer isMdScreen={isMdScreen}>
        <SearchPanel>
          <Grid container>
            <Grid item xs={isMdScreen ? 9.5 : 10}>
              <InputContainer>
                <SearchInput
                  placeholder="장소명을 검색해주세요."
                  onChange={e => setQuery(e.target.value)}
                  value={query}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setHash();
                    }
                  }}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={isMdScreen ? 2.5 : 2}>
              <Button
                fullWidth
                sx={{
                  minWidth: '0 !important',
                  cursor: 'default',
                  background: MAIN_ORANGE,
                  marginRight: '-1rem',
                  '&:hover': {
                    background: MAIN_ORANGE,
                  },
                }}
                onClick={() => {
                  setHash();
                }}
              >
                <SearchIcon sx={{ color: 'white' }} />
              </Button>
            </Grid>
          </Grid>

          {searchData.length > 0 ? (
            <SearchDataContainer>
              {searchData.map((item, index) => (
                <SearchDataItem key={index + item.label} onClick={() => handleChange(item)}>
                  <Typography variant="body1">{item.label}</Typography>
                </SearchDataItem>
              ))}
            </SearchDataContainer>
          ) : (
            <Grid
              container
              flexWrap={true}
              flexDirection="column"
              alignItems="center"
              paddingX={isMdScreen ? 1 : 6}
              paddingY={16}
            >
              <Grid item xs={12} mb={3}>
                {noData ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem',
                          md: '1rem',
                        },
                      }}
                    >
                      검색 결과가 없습니다.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem',
                          md: '1rem',
                        },
                      }}
                    >
                      등록하실 장소를 검색하여, 앱 등록여부를 확인해주세요.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem',
                          md: '1rem',
                        },
                      }}
                    >
                      이미 등록된 장소에 수정이 필요할 경우 장소 수정
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem',
                          md: '1rem',
                        },
                      }}
                    >
                      등록된 장소가 없는 경우 신규 장소 등록을 선택해주세요.
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </SearchPanel>
        <Button
          sx={{
            width: '100%',
            height: '3.5rem',
            border: '1px solid',
            color: 'white',
            backgroundColor: MAIN_ORANGE,
            '&:hover': {
              backgroundColor: FILTER_ORANGE,
            },
          }}
          onClick={() => navigate('/addplace')}
        >
          신규 장소 등록
        </Button>
      </SearchWrapperContainer>
    </Paper>
  );
};

export default SearchMain;

const SearchWrapperContainer = styled.div`
  display: flex;
  padding: ${props => (props.isMdScreen ? '2.5vh' : '4.5vh')};
  justify-content: space-between;
  flex-direction: column;
  min-height: 70vh;
  //maxheight: '70vh';
`;

const SearchPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchDataItem = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  padding: 15px;
  border-color: transparent;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const SearchDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 0;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${MAIN_ORANGE};
    border-radius: 20px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-right: 1rem;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
  font-size: 1rem;
  outline: none;
  &:focus {
    border-bottom: 1px solid #ccc;
  }
`;
