import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Paper, Divider, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import FormHeader from '../../components/FormHeader';
import FormRequestButton from '../../components/FormRequestButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import SignUpTerms from './components/SignUpTerms';
import ProviderId from './components/ProviderId';
import Password from './components/Password';
import ConfirmPassword from './components/ConfirmPassword';
import NickName from './components/Nickname';
import Name from './components/Name';
import AccountNumber from './components/AccountNumber';
import { signUpApi } from '../../api/sign';
import { modalProps } from '../../recoilAtom/recoilAtom';

const SignUp = () => {
  const setModalProp = useSetRecoilState(modalProps);
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue, control } = useForm({
    defaultValues: {
      providerId: '',
      password: '',
      confirmPassword: '',
      nickname: '',
      name: '',
      bank: 'none',
      accountNumber: '',
      agreeToAllTerms: false,
      termsOfServiceAgree: false,
      personalInfoConsent: false,
    },
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const values = getValues();
  const [isPasswordMatch, setPasswordMatch] = useState(true);

  const handleNavigate = () => {
    navigate('/login');
    setModalProp(null);
  };

  const onSubmit = async signUpData => {
    setLoading(true);

    const { ok, response } = await signUpApi(signUpData);

    if (ok) {
      setLoading(false);
      setModalProp({
        titleText: '회원가입 요청이 완료되었습니다.',
        text: '승인 완료 후, 장소등록을 시작할 수 있습니다.',
        text2: '승인까지 약 며칠의 시간이 소요될 예정입니다.',
        buttonText: '확인',
        onPress: () => handleNavigate(),
      });
      return;
    } else {
      setLoading(false);
      if (response.status < 500) {
        if (response.data.code === 'AlreadyExistProviderId') {
          setModalProp({
            titleText: '이미 사용 중인 아이디입니다.',
            text: '다른 아이디로 변경해 주세요.',
            buttonText: '확인',
            onPress: () => setModalProp(null),
          });
          return;
        } else if (response.data.code === 'AlreadyExistNickname') {
          setModalProp({
            titleText: '이미 사용 중인 닉네임입니다.',
            text: '다른 닉네임으로 변경해 주세요.',
            buttonText: '확인',
            onPress: () => setModalProp(null),
          });
          return;
        }
      } else if (response.status >= 500) {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      } else {
        setModalProp({
          titleText: '서버 에러가 발생했습니다.',
          text: '잠시 후 다시 시도해 주세요.',
          text2: '지속적인 에러 발생 시 고객센터로 문의 바랍니다.',
          buttonText: '확인',
          onPress: () => setModalProp(null),
        });
        return;
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          sx={{
            my: { xs: 5, md: 8 },
            paddingTop: '6vw',
            paddingBottom: '8vw',
            paddingLeft: '8vw',
            paddingRight: '8vw',
          }}
          elevation={3}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHeader
              titleText="회원가입"
              subtitleText="장소등록을 하기 위해서 회원가입을 진행해주세요."
              isSmallScreen={isSmallScreen}
            />
            <ProviderId register={register} setValue={setValue} />
            <Password register={register} setValue={setValue} />
            <ConfirmPassword
              register={register}
              setValue={setValue}
              isPasswordMatch={isPasswordMatch}
              setPasswordMatch={setPasswordMatch}
              control={control}
            />
            <NickName values={values} register={register} setValue={setValue} />
            <Divider variant="middle" />
            <Name values={values} register={register} setValue={setValue} />
            <AccountNumber
              values={values}
              register={register}
              setValue={setValue}
              control={control}
            />
            <Divider variant="middle" />
            <SignUpTerms register={register} setValue={setValue} control={control} />
            <FormRequestButton control={control} buttonText="회원가입 요청" pageFrom="signUp" />
            {loading && <LoadingIndicator bgcolorOption={true} />}
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default SignUp;
